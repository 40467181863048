<template>

<div class="home">
    
    <v-container>
        
        <v-dialog v-model="dialog" persistent max-width="560">

            <v-card>

                <v-card-title class="headline">Copy link</v-card-title>

                <v-card-text
                    >Click on copy button to copy
                    the link</v-card-text
                >

                <v-text-field
                    id="myinput"
                    v-model="shareableLink"
                    class="ma-3"
                ></v-text-field>

                <v-card-actions>
                    
                    <v-spacer></v-spacer>

                    <div class="tooltip">

                        <button>

                            <span class="tooltiptext" id="myTooltip"
                            >Copy to clipboard</span
                            >
                            <v-btn
                            color="green darken-1"
                            text
                            @click="myLink"
                            @mouseout="outFunc"
                            >Copy</v-btn>

                        </button>

                    </div>

                    <v-btn color="green darken-1" text @click="dialog = false"
                    >Close</v-btn>

                </v-card-actions>

            </v-card>

        </v-dialog>
        
        <v-row dense class="pa-0 ma-0">
            
            <v-col cols="12">

                <!-- <v-card color="#26c6da" dark v-if="userData.signedIn && userData.accountType != 'Individual'">

                    <div class="d-flex flex-no-wrap justify-space-between">

                        <div>

                            <v-card-title
                            class="headline"
                            v-text="'How you can get Maximum Job Offers ?'"
                            ></v-card-title>

                            <v-card-subtitle
                            class="white--text"
                            v-text="
                                'We strongly recommend you to share your videos with as many people as you can, Because whenever any company searches for videos or resumes, We rank  videos and resumes on the basis of different parameters, out of which views is the one of the most important parameter, So sharing your videos or resume will increase your chance of getting Job Offers.'
                            "
                            ></v-card-subtitle>

                            <v-card-actions>

                                <v-spacer></v-spacer>
                                <v-btn
                                    @click="share()"
                                    class="ma-2 black--text"
                                    color="white"
                                    dark
                                >
                                    Share Now
                                    <v-icon dark right>mdi-share-variant</v-icon>
                                </v-btn>

                            </v-card-actions>

                        </div>

                    </div>

                </v-card> -->

                <!-- <br /> -->
                <!-- <v-card color="#26c6da" dark>
                <div class="d-flex flex-no-wrap justify-space-between">
                    <div>
                    <v-card-title
                        class="headline"
                        v-text="'Our Premium Services'"
                    ></v-card-title>

                    <v-card-subtitle
                        class="white--text"
                        v-text="
                        'If you urgently need a job,we recommend you to opt for preskilet premium. if somebody searches for the skill that you have, your resume will be rank above general search results, thus increasing your chances of landing an interview. And it is just for 49 Rs/month only.'
                        "
                    ></v-card-subtitle>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                        class="ma-2 black--text"
                        color="white"
                        dark
                        router
                        to="/payments"
                        >
                        Go Premium
                        <v-icon dark right>mdi-checkbox-marked-circle</v-icon>
                        </v-btn>
                    </v-card-actions>
                    </div>
                </div>
                </v-card> -->

                <v-card-title
                            class="headline blue--text"
                            v-text="'Preskilet for Students'"
                            ></v-card-title>

                            <br />


                <v-card flat>

                    <v-img
                    class="hidden-md-and-up white--text align-end"
                    :src="vectorImages.Welcome"
                    ></v-img>

                    <div class="d-flex flex-no-wrap justify-space-between">

                        <div>

                            <v-card-title
                            class="headline"
                            v-text="'Preskilet'"
                            ></v-card-title>

                            <v-card-subtitle
                            v-text="
                                'Welcome to preskilet, before you start creating your own videos for your live video profile, go through a brief introduction of sections in our website, it will help you create profile easily.'
                            "
                            ></v-card-subtitle>

                        </div>

                        <v-avatar class="ma-3 hidden-sm-and-down" size="125" tile>

                            <v-img
                            style="border-radius: 10%;"
                            :src="vectorImages.Welcome"
                            ></v-img>

                        </v-avatar>

                    </div>

                </v-card>

                <br />

                <v-card flat>

                    <v-img
                    class="hidden-md-and-up white--text align-end"
                    :src="vectorImages.search"
                    ></v-img>
                    
                    <div class="d-flex flex-no-wrap justify-space-between">

                        <div>

                            <v-card-title class="headline" v-text="'Search'"></v-card-title>

                            <v-card-subtitle
                            v-text="
                                'Search people by names with the help of preskilet'
                            "
                            ></v-card-subtitle>

                        </div>

                        <v-avatar class="ma-3 hidden-sm-and-down" size="125" tile>
                            <v-img :src="vectorImages.search"></v-img>
                        </v-avatar>

                    </div>

                </v-card>

                <br />

                <!-- <v-card flat>

                    <v-img
                    class="hidden-md-and-up white--text align-end"
                    :src="vectorImages.suggestions"
                    ></v-img>

                    <div class="d-flex flex-no-wrap justify-space-between">

                        <div>

                            <v-card-title
                            class="headline"
                            v-text="'Suggestions'"
                            ></v-card-title>

                            <v-card-subtitle
                            v-text="
                                'Suggestions are the people that you might wanna follow because they are having same  Prets (Presentation Videos) as yours or the followers / following of the people of whom you follow. Once you add your videos with title and description , We can show you people that matched your videos as recommendations . And as it is said that sharing is learning , you can get ideas from the Prets of other people who has the same Prets as yours to improve your prets.'
                            "
                            ></v-card-subtitle>

                        </div>

                        <v-avatar class="ma-3 hidden-sm-and-down" size="125" tile>
                            <v-img :src="vectorImages.suggestions"></v-img>
                        </v-avatar>

                    </div>

                </v-card>

                <br /> -->

                <v-card flat>

                    <v-img
                    class="hidden-md-and-up white--text align-end"
                    :src="vectorImages.profile"
                    ></v-img>

                    <div class="d-flex flex-no-wrap justify-space-between">

                        <div>

                            <v-card-title
                            class="headline"
                            v-text="'Profile'"
                            ></v-card-title>

                            <v-card-subtitle
                            v-text="
                                'Profile is one of the most important part of the Preskilet Profile. Make sure you enter corrent information, because if any recruiter likes your Profile, he will try to contact you based on your contact information.'
                            "
                            ></v-card-subtitle>

                        </div>

                        <v-avatar class="ma-3 hidden-sm-and-down" size="125" tile>
                            <v-img :src="vectorImages.profile"></v-img>
                        </v-avatar>

                    </div>

                </v-card>

                <br />

                <v-card flat>

                    <v-img
                    style="border-radius: 100%;"
                    class="hidden-md-and-up white--text align-end"
                    :src="vectorImages.pret"
                    ></v-img>

                    <div class="d-flex flex-no-wrap justify-space-between">

                        <div>

                            <v-card-title class="headline" v-text="'Video'"></v-card-title>

                            <v-card-subtitle
                            v-text="
                                'Using Video section you can upload your videos.'
                            "
                            ></v-card-subtitle>

                        </div>

                        <v-avatar class="ma-3 hidden-sm-and-down" size="125" tile>
                            <v-img :src="vectorImages.pret"></v-img>
                        </v-avatar>

                    </div>

                </v-card>

                <br />

                <v-card flat>

                    <v-img
                    style="border-radius: 100%;"
                    class="hidden-md-and-up white--text align-end"
                    :src="vectorImages.editPret"
                    ></v-img>

                    <div class="d-flex flex-no-wrap justify-space-between">

                        <div>

                            <v-card-title
                            class="headline"
                            v-text="'Edit Videos'"
                            ></v-card-title>

                            <v-card-subtitle
                            v-text="
                                'In Edit videos section you can edit or delete your previosly uploaded videos.'
                            "
                            ></v-card-subtitle>

                        </div>

                        <v-avatar class="ma-3 hidden-sm-and-down" size="125" tile>
                            <v-img :src="vectorImages.editPret"></v-img>
                        </v-avatar>

                    </div>

                </v-card>

                <br />

                <!-- <v-card flat>

                    <v-img
                    style="border-radius: 100%;"
                    class="hidden-md-and-up white--text align-end"
                    :src="vectorImages.followers"
                    ></v-img>

                    <div class="d-flex flex-no-wrap justify-space-between">

                        <div>
                            
                            <v-card-title
                            class="headline"
                            v-text="'Followers'"
                            ></v-card-title>

                            <v-card-subtitle
                            v-text="
                                'In Followers section you will able to see the people who follow you on preskilet, as soon as you upload any pret , we will send your followers a push notification , so that they can watch your pret as soon as possible there by increasing your views of prets.'
                            "
                            ></v-card-subtitle>

                        </div>

                        <v-avatar class="ma-3 hidden-sm-and-down" size="125" tile>

                            <v-img
                            style="border-radius: 20%;"
                            :src="vectorImages.followers"
                            ></v-img>

                        </v-avatar>

                    </div>

                </v-card> -->

                <!-- <br />

                <v-card flat>

                    <v-img
                    style="border-radius: 100%;"
                    class="hidden-md-and-up white--text align-end"
                    :src="vectorImages.following"
                    ></v-img>

                    <div class="d-flex flex-no-wrap justify-space-between">

                        <div>

                            <v-card-title
                            class="headline"
                            v-text="'Following'"
                            ></v-card-title>

                            <v-card-subtitle
                            v-text="
                                'In following section you show the people to whom you follow on preskilet, we recommend you to follow as many as people as you can so that you can learn more about how to make awesome prets (Presentation videos).'
                            "
                            ></v-card-subtitle>

                        </div>

                        <v-avatar class="ma-3 hidden-sm-and-down" size="125" tile>

                            <v-img
                            style="border-radius: 10%;"
                            :src="vectorImages.following"
                            ></v-img>

                        </v-avatar>

                    </div>

                </v-card>

                <br /> -->

                <v-card-title
                            class="headline blue--text"
                            v-text="'Preskilet for Teachers'"
                            ></v-card-title>

                            <br />

                
                <v-card flat>

                    <v-img
                    style="border-radius: 100%;"
                    class="hidden-md-and-up white--text align-end"
                    :src="vectorImages.group"
                    ></v-img>

                    <div class="d-flex flex-no-wrap justify-space-between">

                        <div>

                            <v-card-title
                            class="headline"
                            v-text="'Groups'"
                            ></v-card-title>

                            <v-card-subtitle
                            v-text="
                                'You can create groups (Batch-wise, Division-wise, Department-wise, College-wise) on preskilet to create tasks (assignments) for that group. Interested Users can follow those groups and you will get a group request. As soon as you accept the request, each group member will get notifications whenever you assign task (Assignment) for that group.'
                            "
                            ></v-card-subtitle>

                        </div>

                        <v-avatar class="ma-3 hidden-sm-and-down" size="125" tile>

                            <v-img
                            style="border-radius: 10%;"
                            :src="vectorImages.group"
                            ></v-img>

                        </v-avatar>

                    </div>

                </v-card>

                <br />

                <v-card flat>

                    <v-img
                    style="border-radius: 100%;"
                    class="hidden-md-and-up white--text align-end"
                    :src="vectorImages.task"
                    ></v-img>

                    <div class="d-flex flex-no-wrap justify-space-between">

                        <div>

                            <v-card-title
                            class="headline"
                            v-text="'Tasks'"
                            ></v-card-title>

                            <v-card-subtitle
                            v-text="
                                'You can create tasks on various topics so that your group members can upload prets (presentation videos) for respective task and then you can grade those presentation videos and generate a grade report in excel sheet.'
                            "
                            ></v-card-subtitle>

                        </div>

                        <v-avatar class="ma-3 hidden-sm-and-down" size="125" tile>

                            <v-img
                            style="border-radius: 10%;"
                            :src="vectorImages.task"
                            ></v-img>

                        </v-avatar>

                    </div>

                </v-card>

                <br />
                
                <v-card flat>

                    <v-img
                    style="border-radius: 100%;"
                    class="hidden-md-and-up white--text align-end"
                    :src="vectorImages.excel"
                    ></v-img>

                    <div class="d-flex flex-no-wrap justify-space-between">

                        <div>

                            <v-card-title
                            class="headline"
                            v-text="'Download Excel'"
                            ></v-card-title>

                            <v-card-subtitle
                            v-text="
                                'You can download excel sheet for a particular group containing grades of every task pret (presentation video for assigned task) along with details of group members such as Registration number, Roll number, Batch name, etc in just a single click!'
                            "
                            ></v-card-subtitle>

                        </div>

                        <v-avatar class="ma-3 hidden-sm-and-down" size="125" tile>

                            <v-img
                            style="border-radius: 10%;"
                            :src="vectorImages.excel"
                            ></v-img>

                        </v-avatar>

                    </div>

                </v-card> 

                <br />
                
            </v-col>

        </v-row>

    </v-container>

</div>
</template>

<style scoped>

#create .v-speed-dial {
  position  : absolute;
}

#create .v-btn--floating {
  position  : relative;
}
.tooltip {
  position  : relative;
  display   : inline-block;
}

.tooltip .tooltiptext {
  visibility        : hidden;
  width             : 140px;
  background-color  : #555;
  color             : #fff;
  text-align        : center;
  border-radius     : 6px;
  padding           : 5px;
  position          : absolute;
  z-index           : 1;
  bottom            : 150%;
  left              : 50%;
  margin-left       : -75px;
  opacity           : 0;
  transition        : opacity 0.3s;
}

.tooltip .tooltiptext::after {
  content       : "";
  position      : absolute;
  top           : 100%;
  left          : 50%;
  margin-left   : -5px;
  border-width  : 5px;
  border-style  : solid;
  border-color  : #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility    : visible;
  opacity       : 1;
}

</style>

<script>

import { mapGetters } from "vuex";
import utilities from "@/helpers/utilities";

import firebase from "firebase/app";
import "firebase/firebase-analytics";
var firebaseAnlytics;


export default {
    
    name        : "LandUpInfo",
    
    data() {

        return {

            vectorImages    : utilities.vectorImages,
            recommendations : [],
            shareableLink   : "https://preskilet.com/",
            dialog          : false

        };

    },

    computed    : { ...mapGetters({ userData    : "User/userData" }) },
    
    created() {

        // FIREBASE ANLYTICS
        firebaseAnlytics = firebase.analytics();
        firebaseAnlytics.logEvent("LandUpInfo Component");

        this.$store.dispatch("User/updateUserToken", null, { root: true });
        this.$store.commit("User/isSignedIn");
    
    },

    mounted() {

        this.$store.dispatch("User/updateUserToken", null, { root: true });
        this.$store.commit("User/isSignedIn");
        this.$store.commit("User/toggleProgressLoader", false);
        this.shareableLink = this.shareableLink + this.userData.email;
    
    },

    methods: {
        
        myLink() {
            
            var tooltip         = document.getElementById("myTooltip");
            tooltip.innerHTML   = "Link Copied !";
            var copiedText      = document.getElementById("myinput");

            copiedText.select();
            copiedText.setSelectionRange(0, 99999);
            document.execCommand("copy");
        
        },

        outFunc() {

            var tooltip         = document.getElementById("myTooltip");
            tooltip.innerHTML   = "Copy to clipboard";
        
        },
        
        share() {

            if (navigator.share) {
                
                navigator
                .share({
                    title: "preskilet",
                    text: "Check out my live Video Profile on Preskilet",
                    url: this.shareableLink,
                })
                .then(() => console.log("Successful share is done"))
                .catch((err) => {
                    let errorMsg = utilities.errorHandler(err);
                    this.$store.commit("User/showErrorAlert", errorMsg);
                });

            } else {

                console.log("outdated browser , sharing cant be done directly, so share link using model");
                this.dialog = true;
            
            }
    
        }
    
    },

};

</script>
