<template>
<div>
    
    <ProgressLoader />
    <NetConnectivity xs12 md5/>  
    <SuccessAlert xs6 md5/>
    <ErrorAlert xs6 md5/>
    
    <SignedInNavbar/>

    <vMain class="white"><router-view /></vMain>

</div>
</template>

<style>

.v-card__text, .v-card__title {
    word-break: normal !important;
}

pre {
    line-height: 2.5em;
    
    /* to wrap the text when it is in one line , to move it to next line in all type of browsers */
    white-space: pre-wrap; /* Since CSS 2.1 */
    white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
    white-space: -pre-wrap; /* Opera 4-6 */
    white-space: -o-pre-wrap; /* Opera 7 */
    word-wrap: break-word; /* Internet Explorer 5.5+ */
}

</style>

<script>
import { mapGetters } from "vuex";

import Navbar           from "@/components/Navbar";
import SignedInNavbar   from "@/components/SignedInNavbar"
import ProgressLoader   from "@/components/ProgressLoader";
import SuccessAlert     from "@/components/SuccessAlert";
import ErrorAlert       from "@/components/ErrorAlert";
import NetConnectivity  from "@/components/NetConnectivity";

export default {
    
    name: "Root",
    
    computed: { ...mapGetters({ userData: "User/userData" }) },
    
    components: { Navbar,SignedInNavbar, ProgressLoader, SuccessAlert, ErrorAlert, NetConnectivity },
    
    created(){
        console.log("At root level: ", this.userData);

        const accountTypes = ["Teacher", "Student", "Candidate", "Recruiter"]
        //Redirecting user to select account page if he is signed in but not select an account and 
        //accidenly moved to any other page
        if (this.$route.name !== 'SelectAccountType')   { // User is not on select account page
            if (this.userData.signedIn)                     { // User is signed in 
                if (!accountTypes.includes(this.userData.accountType) || this.userData.accountType === "none") { // And acccount type is not set
                    window.location = document.location.origin + "/selectaccounttype"; // Redirect user to select account
                }
            }
        }
    }

};
</script>