<template>
<div>
    
    <!-- Landing page to show when user is not signed in -->
    <div v-if="!userData.signedIn">
        <LandingPage />
    </div>

    <v-container style="min-height: 80vh;">
        
        <v-layout row justify-space-around>
            
            <!-- Left section which is on top on mobile phone-->
            <v-flex xs12 md5 hidden-md-and-up>

                <!-- !!! If user is not signed in -->
                
                <!-- Preskilet sign up button & Ad -->
                <v-flex xs12 md11 v-if="!userData.signedIn">
                    
                    <v-row dense class="pa-0 ma-0">
                        
                        <v-col>
                            
                            <v-card flat>
                                
                                <v-img class="hidden-md-and-up white--text align-end" :src="vectorImages.presentation"></v-img>
                                
                                <div class="d-flex flex-no-wrap justify-space-between">
                                    
                                    <div>
                                        <v-card-title class="headline" v-text="'Preskilet Account'"></v-card-title>
                                        <v-card-subtitle v-text="'With an Preskilet account, you can make an elegant video resume. Your resume will be live at preskilet.com/your email all the time. You can then share this link with any recruiter. And the best part is that it’s all for free !'"></v-card-subtitle>
                                    </div>
                                    
                                    <v-avatar class="ma-3 hidden-sm-and-down" size="125" tile>
                                        <v-img style="border-radius: 10px;" :src="vectorImages.presentation"></v-img>
                                    </v-avatar>
                                
                                </div>
                            
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn class="ma-2" color="primary" dark router to="/signin"> Sign Up / Sign In <v-icon dark right>mdi-checkbox-marked-circle</v-icon> </v-btn>
                                </v-card-actions>
                            
                            </v-card>
                        
                        </v-col>
                        
                        <v-col>
                            
                            <v-card flat>
                                <v-img max-width="500" class="white--text align-end" :src="vectorImages.homePageAnimtaion"></v-img>
                            </v-card>
                        
                        </v-col>
                    
                    </v-row>
                
                </v-flex>
                
                <!-- !!! If user is signed in -->

                <!-- notification and install pwa -->
                <v-flex xs12 md12 v-if="userData.signedIn">
                    
                    <v-row dense class="pa-0 ma-0">
                        
                        <v-col v-if="notificationPermission">

                            <v-card color="primary" dark>
                    <div class="d-flex flex-no-wrap justify-space-between">
                    <div>
                        <v-card-title>
                        <v-icon left>mdi-bell</v-icon>
                        <span class="title">Allow Notifications to </span>
                        </v-card-title>

                        <!-- For Candidates -->
                        <v-card-text style="font-size: 18px;line-height: 150%;" class="white--text">
                        <ul>
                            <li> Get notifications  of <b> Task / Assignments </b> for your Group / Batch. </li>
                            <li >know when <b> Recruiter  visits </b> your profile or video. </li>
                            <li> Someone starts <b> Following </b> you or send <b>  Follow request </b>  for your group. </li>
                            <li >know when Video is your <b> Graded / like / commented </b> by anyone. </li>
                        </ul>
                        </v-card-text>
                        
                    </div>
                    
                    <v-avatar class="ma-3 hidden-sm-and-down" size="125"  style="border-radius: 10px;">
                        <v-img :src="vectorImages.notifications"></v-img>
                    </v-avatar>
                    </div>
                
                    <v-card-actions>
                    <v-spacer></v-spacer>
                        <v-btn
                        class="ma-2 primary--text"
                        color="white"
                        dark
                        @click="reqforpermission()" > Allow
                        <v-icon dark right>mdi-checkbox-marked-circle</v-icon>
                        </v-btn>
                        
                        <v-progress-circular
                        v-if="notificationprogress"
                        :size="50"
                        color="white"
                        dark
                        indeterminate>
                        </v-progress-circular>
                    </v-card-actions>
                </v-card>
                        
                        </v-col>
        
                    
                    </v-row>
                    
                </v-flex>
         
            </v-flex>

            <v-flex xs12 md12 v-if="userData.signedIn && (userData.profile.account ==  'Student' || userData.profile.account == 'Candidate')">
                    <v-alert shaped outlined type="primary">
                        
                        <v-row align="center" no-gutters>
                            <v-col  class="grow">
                                <h3 class="text-h5"> Apply for Job Openings </h3>
                                <div>To apply for placements with packages from 4 LPA to 44 LPA, click on the button. 
                                    <span style="color: red;">If you're not in your final year or ready for placements, please do not apply, just browse for reference.</span>
                                </div>
                            </v-col>
                            <v-col class="shrink">
                                <v-btn style="margin-left: 5px;" href="https://curiculo.live/placements" color="info" >Go to Curiculo.live Placements</v-btn>
                            </v-col>
                        </v-row>
                    </v-alert>
                    <br />
                    
                </v-flex>



            <!-- Explore videos Feed -->
            <v-flex xs12 md6 style="margin-top: -2%;">
                <!-- Search Bar -->
                <v-text-field
                    prepend-inner-icon="mdi-account"
                    style="margin-left: 10px;margin-right: 10px;margin-top: 10px;"
                    v-if="userData.signedIn"
                    v-model="searchQuery"
                    type="search"
                    v-on:keyup.enter="pressEnter"
                    v-on:keyup="typingInProfileSearch"
                    solo
                    label="Search Profiles by Names"
                    append-icon="mdi-magnify"
                ></v-text-field>

                <v-text-field
                    prepend-inner-icon="mdi-youtube"
                    style="margin-left: 10px;margin-right: 10px;"
                    v-if="userData.signedIn"
                    v-model="searchQueryForTags"
                    type="search"
                    v-on:keyup.enter="pressEnterForTags"
                    v-on:keyup="typingInTags"
                    solo
                    label="Search Videos by tags, title, description"
                    append-icon="mdi-magnify"
                    persistent-hint
                    hint="For example you can search for video resume, operating system, linux, aws, america, Spectrum, 5G Technology, world bank, wireless, model making, data visualisation, cyber, chemistry, factorial, recursion, Jquery, Java, Advance Java, SQL, DBMS, Javascript, artificial intelligence, Capstone Project, BlockChain, presentation video, computer network, ai, finance, topology, software engineering, python, string, one way video interview, management, case study, mini project, major project, IOT project, Job search etc."
                ></v-text-field>

                <TrendingAndLatestFeed v-if="userData.signedIn"/>
                <LandUpInfo v-if="!userData.signedIn"/>
            </v-flex>

            <!-- Right section only on desktop-->
            <v-flex xs12 md5 hidden-sm-and-down>

                <!-- !!! If user is not signed in -->
                
                <!-- Preskilet sign up button & Ad -->
                <v-flex xs12 md11 v-if="!userData.signedIn">
                    
                    <v-row dense class="pa-0 ma-0">
                        
                        <v-col>
                            
                            <v-card flat>
                                
                                <v-img class="hidden-md-and-up white--text align-end" :src="vectorImages.presentation"></v-img>
                                
                                <div class="d-flex flex-no-wrap justify-space-between">
                                    
                                    <div>
                                        <v-card-title class="headline" v-text="'Preskilet Account'"></v-card-title>
                                        <v-card-subtitle v-text="'With an Preskilet account, you can make an elegant video resume. Your resume will be live at preskilet.com/your email all the time. You can then share this link with any recruiter. And the best part is that it’s all for free !'"></v-card-subtitle>
                                    </div>
                                    
                                    <v-avatar class="ma-3 hidden-sm-and-down" size="125" tile>
                                        <v-img style="border-radius: 10px;" :src="vectorImages.presentation"></v-img>
                                    </v-avatar>
                                
                                </div>
                            
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn class="ma-2" color="primary" dark router to="/signin"> Sign Up <v-icon dark right>mdi-checkbox-marked-circle</v-icon> </v-btn>
                                </v-card-actions>
                            
                            </v-card>
                        
                        </v-col>
                        
                        <v-col>
                            
                            <v-card flat>
                                <v-img max-width="500" class="white--text align-end" :src="vectorImages.homePageAnimtaion"></v-img>
                            </v-card>
                        
                        </v-col>
                    
                    </v-row>
                
                </v-flex>
                
                <!-- !!! If user is signed in -->

                <!-- notification and install pwa -->
                <v-flex xs12 md12 v-if="userData.signedIn">
                    
                    <v-row dense class="pa-0 ma-0">
                        
                        <v-col v-if="notificationPermission">

                            <v-card color="primary" dark>
                    <div class="d-flex flex-no-wrap justify-space-between">
                    <div>
                        <v-card-title>
                        <v-icon left>mdi-bell</v-icon>
                        <span class="title">Allow Notifications to </span>
                        </v-card-title>

                        <!-- For Candidates -->
                        <v-card-text style="font-size: 18px;line-height: 150%;" class="white--text">
                        <ul>
                            <li> Get notifications  of <b> Task / Assignments </b> for your Group / Batch. </li>
                            <li >know when <b> Recruiter  visits </b> your profile or video. </li>
                            <li> Someone starts <b> Following </b> you or send <b>  Follow request </b>  for your group. </li>
                            <li >know when Video is your <b> Graded / like / commented </b> by anyone. </li>
                        </ul>
                        </v-card-text>
                        
                    </div>
                    
                    <v-avatar class="ma-3 hidden-sm-and-down" size="125"  style="border-radius: 10px;">
                        <v-img :src="vectorImages.notifications"></v-img>
                    </v-avatar>
                    </div>
                
                    <v-card-actions>
                    <v-spacer></v-spacer>
                        <v-btn
                        class="ma-2 primary--text"
                        color="white"
                        dark
                        @click="reqforpermission()" > Allow
                        <v-icon dark right>mdi-checkbox-marked-circle</v-icon>
                        </v-btn>
                        
                        <v-progress-circular
                        v-if="notificationprogress"
                        :size="50"
                        color="white"
                        dark
                        indeterminate>
                        </v-progress-circular>
                    </v-card-actions>
                </v-card>
                        
                        </v-col>
        
                    
                    </v-row>
                    
                </v-flex>
                <!-- Suggestions 
                <Suggestions v-if="userData.signedIn" />
                 -->

                 <!-- If user is singed in  -->
                  <!-- !!! Google sticky ad on desktop  -->
                 <v-card  class="mx-auto sticky" v-if="userData.signedIn && userData.profile.account === 'Student'">
            
            <v-list-item two-line class="RightSideAd">
                
                <v-list-item-avatar><img src="https://banner2.cleanpng.com/20180324/iww/kisspng-google-logo-g-suite-google-5ab6f1cee66464.5739288415219388949437.jpg" /></v-list-item-avatar>
                
                <v-list-item-content>
                    
                    <v-list-item-title>Google Ads</v-list-item-title>
                    
                    <v-list-item-subtitle>Sponsored</v-list-item-subtitle>
                
                </v-list-item-content>
                            
                <v-btn color="primary" size="35" tile large icon router >
                    <v-icon left> mdi-compass-outline</v-icon>
                </v-btn>

            </v-list-item>

                <div class="ad-container">
                    <Adsense
                        class="adsbygoogle"
                        style="display:block"
                        data-ad-client="ca-pub-2789565594210548"
                        data-ad-slot="4343687426"
                        data-ad-format="auto"
                        data-full-width-responsive="true"
                    ></Adsense>
                </div>
               
        </v-card>


            </v-flex>

        </v-layout>

        <!-- <div style="position: relative; width: 100%; height: 100%; padding-top: 56.2225%;
        padding-bottom: 0;  margin-top: 1.6em; margin-bottom: 0.9em; overflow: hidden;
        border-radius: 8px; will-change: transform;">
            <iframe loading="lazy" style="position: absolute; width: 100%; height: 100%; top: 0; left: 0; border: none; padding: 0;margin: 0;"
                src="https://www.canva.com/design/DAGPT9xDLXE/qsGvnqwJNLhFQLyJWjlgXQ/view?embed" allowfullscreen="allowfullscreen" allow="fullscreen">
            </iframe>
        </div>

        <div style="position: relative; width: 100%; height: 100%; padding-top: 56.2225%;
        padding-bottom: 0;  margin-top: 1.6em; margin-bottom: 0.9em; overflow: hidden;
        border-radius: 8px; will-change: transform;">
            <iframe loading="lazy" style="position: absolute; width: 100%; height: 100%; top: 0; left: 0; border: none; padding: 0;margin: 0;"
                src="https://www.canva.com/design/DAGPT11gIcc/g9zcPhhLeY7DZGiy4ybG3g/view?embed" allowfullscreen="allowfullscreen" allow="fullscreen">
            </iframe>
        </div>
        

        <div style="position: relative; width: 100%; height: 100%; padding-top: 56.2225%;
        padding-bottom: 0;  margin-top: 1.6em; margin-bottom: 0.9em; overflow: hidden;
        border-radius: 8px; will-change: transform;">
            <iframe loading="lazy" style="position: absolute; width: 100%; height: 100%; top: 0; left: 0; border: none; padding: 0;margin: 0;"
                src="https://www.canva.com/design/DAGPT98oBPU/AZtQWtysBpgK2_jm-_oIoA/view?embed" allowfullscreen="allowfullscreen" allow="fullscreen">
            </iframe>
        </div>
        

        <div style="position: relative; width: 100%; height: 100%; padding-top: 56.2225%;
        padding-bottom: 0;  margin-top: 1.6em; margin-bottom: 0.9em; overflow: hidden;
        border-radius: 8px; will-change: transform;">
            <iframe loading="lazy" style="position: absolute; width: 100%; height: 100%; top: 0; left: 0; border: none; padding: 0;margin: 0;"
                src="https://www.canva.com/design/DAGPTwgJsTc/_01i5KymxP25jeF81MyjXQ/view?embed" allowfullscreen="allowfullscreen" allow="fullscreen">
            </iframe>
        </div>

        <div style="position: relative; width: 100%; height: 100%; padding-top: 56.2225%;
        padding-bottom: 0;  margin-top: 1.6em; margin-bottom: 0.9em; overflow: hidden;
        border-radius: 8px; will-change: transform;">
            <iframe loading="lazy" style="position: absolute; width: 100%; height: 100%; top: 0; left: 0; border: none; padding: 0;margin: 0;"
                src="https://www.canva.com/design/DAGPT9D2lcQ/S8hzNJcKeGpGgKyH0pl4sw/view?embed" allowfullscreen="allowfullscreen" allow="fullscreen">
            </iframe>
        </div>  
        
         -->
        


    </v-container>
     <br>


    <br>
    <br>
    <footer class="site-footer">

        <div class="container">            
            <v-layout row wrap justify-space-around>
                
                <v-flex xs11 md8>
                    
                    <h6>About</h6>
                    
                    <br />
                    
                    <p class="text-justify white--text" dark>
                        
                        Preskilet stands for presenting your soft skill set !
                        Preskilet is the world’s first professional video platform,
                        At preskilet you can create your professional video resume containing videos about
                        projects and achivements etc. You can share your video profile
                        with potential recruiters for increasing chances of placements.
                        With preskilet your video profile is live At
                        
                        <b>https://preskilet.com/your email</b>. <br /> <br />
                    
                    </p>
                
                </v-flex>
                
                <v-flex xs11 md3>
                    
                    <h6>Quick Links</h6>
                    
                    <v-list style="background: #26272b;" dark>

                        <!-- <div v-for="(item, i) in commonMenuitems" :key="i">

                            <div v-if="item.title != 'About us' && item.title != 'Contact us'">
                                <v-list-item router :to="item.route" >                            
                                    <v-list-item-icon>
                                        <i size="30" v-if="item.title == 'Settings'" class="material-icons"> settings </i>
                                        <v-icon>{{ item.icon }}</v-icon>
                                    </v-list-item-icon>
                                    
                                    <v-list-item-content>
                                        <v-list-item-title>{{ item.title }}</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </div>

                            <div v-if="item.title == 'About us' || item.title == 'Contact us'">
                                <v-list-item  @click="openInNewPage()">                            
                                    <v-list-item-icon>
                                        <i size="30" v-if="item.title == 'Settings'" class="material-icons"> settings </i>
                                        <v-icon>{{ item.icon }}</v-icon>
                                    </v-list-item-icon>
                                    
                                    <v-list-item-content>
                                        <v-list-item-title>{{ item.title }}</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </div>

                        </div> -->

                        <v-list-item v-for="(item, i) in commonMenuitems" :key="i" router :to="item.route">
                            
                            <v-list-item-icon>
                                <i size="30" v-if="item.title == 'Settings'" class="material-icons"> settings </i>
                                <v-icon>{{ item.icon }}</v-icon>
                            </v-list-item-icon>
                            
                            <v-list-item-content>
                                <v-list-item-title>{{ item.title }}</v-list-item-title>
                            </v-list-item-content>
                            
                        </v-list-item>
                    
                    </v-list>
                </v-flex>
                
            </v-layout>
            
            <br /><hr /><br />
            
            <v-layout row wrap justify-space-around>
            
                <v-flex xs11 md9>
                    <p class="ma-3 white--text text-center"> Copyright &copy; 2020 by <a class="white--text" href="https://preskilet.com">Preskilet</a>.</p>
                    <p class="ma-3 white--text text-center"> Made with ❤️ in India 🇮🇳 </p>
                </v-flex>
                
                <v-flex xs11 md2 class="text-center ma-3">
                    <a :href="item.link" v-for="item in SocialLinksOfPreskilet" :key="item.link" target="_blank">
                        <v-btn class="mx-4 white--text" icon router>
                            <v-icon size="24px">{{ item.icon }}</v-icon>
                        </v-btn>
                    </a>
                </v-flex>
            
            </v-layout>
        
        </div>
    
    </footer>
</div>
</template>

<style scoped>
.example-tags {
        font-size: 0.8rem; /* Adjust the font size as needed */
        color: #666; /* Adjust the color */
        margin-top: 2px; /* Adjust the margin from the input field */
    }


.footerad{ 
    width: 100%;
    height: 100%;
    margin: 25px;
    margin-top: 0px;
    margin-bottom:30px;
}

.Brand-logo {
  text-decoration: none !important;
  color: black !important;
}

.headtitle {
  font-size: 45px;
  color: grey;
  margin-bottom: 8%;
  margin-top: 10vh;
}


.blink_me {
  animation: blinker 1s linear infinite;
  color: red;
  margin: 1%;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

/* for desktop */
@media screen and (min-width: 650px) {
  .videoRecommendationThubmnail {
    border-radius: 7px;
  }
  .headtitle {
    font-size: 70px;
  }
  .footerad{
      width: 100%;
      height: 100%;
      margin:25px;
      margin-top: -3px;
      margin-bottom:30px;
  }
  .sticky{
    position: -webkit-sticky;
    position: sticky;
    top: 35px;
    z-index: 2;
    width: auto !important;
}

  .sticky2{
    position: -webkit-sticky;
    position: sticky;
    top: 400px;
    z-index: 2;
    width: auto !important;
    margin-top: 280%;
}

}

/* For footer */
.site-footer {
  background-color: #26272b;
  padding: 45px 0 20px;
  font-size: 15px;
  line-height: 24px;
  color: #737373;
}

.site-footer hr {
  border-top-color: #bbb;
  opacity: 0.5;
}

.site-footer hr.small {
  margin: 20px 0;
}

.site-footer h6 {
  color: #fff;
  font-size: 16px;
  text-transform: uppercase;
  margin-top: 5px;
  letter-spacing: 2px;
}

</style>

<script>

import { mapGetters } from "vuex";

import utilities from "@/helpers/utilities";
import apis from "../helpers/apiConfig";
const axios = require("axios").default;

import firebase from "firebase/app";
import "firebase/auth";
import "firebase/messaging";
import "firebase/firebase-analytics";

import VuePlyr from "vue-plyr";

import LandUpInfo from "@/components/LandUpInfo";
import TrendingAndLatestFeed from "@/components/TrendingAndLatestFeed";
import LandingPage from "@/components/LandingPage";

var messaging;
var deferredPrompt;
var firebaseAnlytics;

// Google Adsense    
import Vue from "vue";
import Ads from 'vue-google-adsense'
Vue.use(require('vue-script2'))
Vue.use(Ads.Adsense)


export default {
    name: "Home",
    
    components: { "vue-plyr": VuePlyr, LandUpInfo, TrendingAndLatestFeed, LandingPage },
    
    computed: { ...mapGetters({ userData: "User/userData" }) },
    
    async created() {

        // FIREBASE ANLYTICS
        firebaseAnlytics = firebase.analytics();
        firebaseAnlytics.logEvent("Home Page");
        
        this.$store.commit("User/toggleProgressLoader", false);
        this.$store.dispatch("User/updateUserToken", null, { root: true });
        this.$store.commit("User/isSignedIn");
        
        if (firebase.messaging.isSupported()) { messaging = firebase.messaging() };
        
        if (Notification.permission == "granted") {
            this.notificationPermission = false;
            this.postNotificationToken();
        }
        
        // this.exploreVideos();
        
        let that = this;
        
        window.addEventListener("beforeinstallprompt", function(e) {
            
            that.installPwaBanner = true;
            e.preventDefault();
            deferredPrompt = e;
        });
    
    },
    
    mounted() {
      //  this.getSuggestions()
    },
    
    data() {
        return {

            adsarray : [2,4,8,9,12,16],

            loadMoreVideosProgress  : false,
            excludeVideoIDs        : [],
            
            options                 : { controls: ["play-large", "mute"], hideControls: true},
            exploreVideosArray      : [],
            suggestData             : [],
            isLimitedLength         : true,
            
            notificationPermission  : true,
            notificationprogress    : false,
            installPwaBanner        : false,
            vectorImages            : utilities.vectorImages,
            searchQuery             : "",
            searchQueryForTags      : "",
            
            SocialLinksOfPreskilet  : [
                {   icon : "mdi-linkedin",  link : "http://bit.ly/4dovZ6l" },
                {   icon : "mdi-instagram", link : "https://www.instagram.com/preskilet/"                           }
            ],
            
            commonMenuitems: [
                {   title : "Terms & conditions",   route : "/termsandconditions",  icon : "mdi-file-document"  },
                {   title : "Privacy Policy",       route : "/privacypolicy",       icon : "mdi-lock"           },
                {   title : "About us",             route: "/aboutus",              icon : "mdi-information"    },
                {   title : "Contact us",           route: "/contactus",            icon : "mdi-phone"          }
            ]
        }
    },
    
    methods: {
        
        exploreVideos() {

            this.loadMoreVideosProgress = true;

            let endPoint = apis.urls.exploreVideos;
            
            axios(endPoint, {
                method  :   "post",
                data  : { excludeVideoIDs : this.excludeVideoIDs }
            })
            .then((res) => {
                
                this.loadMoreVideosProgress = false;
                
                var videosData = res.data.result.data
                
                if(videosData.length === 0) {
                    this.$store.commit("User/showErrorAlert", "There are no more videos");
                } else {
                    
                    if(this.excludeVideoIDs.length === 0){
                        // FIREBASE ANLYTICS
                        firebaseAnlytics.logEvent("Explore Video Displayed");
                    } else {
                        // FIREBASE ANLYTICS
                        firebaseAnlytics.logEvent("Explore Video (Load more Video) button clicked");
                    }
                    
                    for (let i = 0; i < videosData.length; i++) {
                        const element = videosData[i];
                        this.exploreVideosArray.push(element)
                        this.excludeVideoIDs.push(element._id);
                    }

                    console.log("excludeVideoIDs 2 : ",this.excludeVideoIDs);


                }
            
            })
            .catch((err)    => { console.log("exploreVideos() Error : ", err)   });

        },
        
        pressEnter: function() {
            this.$store.commit("User/toggleProgressLoader", true);
            window.location.href = "/search/" + this.searchQuery;
            this.searchQuery = "";
        },

        pressEnterForTags: function() {
            this.$store.commit("User/toggleProgressLoader", true);
            window.location.href = "/search/" + this.searchQueryForTags + "?type=tags";
            this.searchQueryForTags = "";
        },

        typingInProfileSearch: function() {
            this.searchQueryForTags = ""
        },

        typingInTags: function() {
            this.searchQuery = ""
        },

        redirectToSign() { window.location.href = "/signin" },
        
        postNotificationToken() {
            
            messaging.getToken().then((token) => {
                
                if (this.userData.token) {
                    
                    this.notificationprogress = false;
                    this.notificationPermission = false;
                    
                    let endPoint = apis.urls.saveNotificationToken;
                    
                    let data = {
                        token : token,
                        device: this.deviceName(),
                        browser: this.browserName()
                    }
                    
                    axios(endPoint, {
                        method          :   "post",
                        data,
                        withCredentials :   true,
                        headers         : { authorization : this.userData.token }
                    })
                    .then((res) => {})
                    .catch((err) => { console.log("Error in postNotificationToken ", err) });
                
                } else {
                    console.log("User signed out but has allowed push notification");
                }
            
            });
        
        },
        
        showInstallPwaBanner() {
            
            if (deferredPrompt === undefined) {
                
                this.alreadyinstalled = true;
                this.pwapopup = false;
                
            } else {
                
                deferredPrompt.prompt();
                
                deferredPrompt.userChoice.then((choiceResult) => { 
                    if (choiceResult.outcome === "accepted") { this.installPwaBanner = false }
                    deferredPrompt = null;
                });
            }
        },
        
        reqforpermission() {
            
            if (firebase.messaging.isSupported()) {
                
                messaging.requestPermission()
                .then(() => {
                    this.notificationprogress = true;
                    this.postNotificationToken();
                })
                .catch((err) => {
                    console.log("exploreVideos() Error : ", err);
                    this.permission = true;
                });
            
            } else {
                
                this.$store.commit( "User/showErrorAlert", "Push Notifications is not supported in Safari, We kindly recommend you to use chrome");
                this.permission = true;
            
            }
        },
        
        deviceName() {
            let ua = navigator.userAgent;
            
            if (    ua.match(/Android/i)    )   {   return "Android"    };
            if (    ua.match(/BlackBerry/i) )   {   return "BlackBerry" };
            if (    ua.match(/iPhone/i)     )   {   return "iPhone"     };
            if (    ua.match(/iPad/i)       )   {   return "iPad"       };
            if (    ua.match(/iPod/i)       )   {   return "iPod"       };
            if (    ua.match(/IEMobile/i)   )   {   return "IEMobile"   };
            if (    ua.match(/Mac/i)        )   {   return "Mac"        };
            if (    ua.match(/Windows/i)    )   {   return "Windows"    };
            
            return "device";
        },
        
        browserName() {
            
            let ua = navigator.userAgent;
            
            if  (   ua.match(/Firefox/i)                        )   {   return  "Firefox"   };
            if  (   ua.match(/Chrome/i) && ua.match(/Safari/i)  )   {   return  "Chrome"    };
            if  (   ua.match(/Safari/i)                         )   {   return  "Safari"    };
            
            return "Browser";
        },
        
        followPreskilet(uid) {
              console.log("my uid : ", uid);
                
                this.$store.commit("User/toggleProgressLoader", true);

                let data        = { userId: uid };
                let endPoint    = apis.urls.follow;
                
                axios(endPoint, {
                    method          : "post",
                    data,
                    withCredentials : true,
                    headers         : { authorization: this.userData.token }
                })
                .then((res) => {

                    this.$store.commit("User/toggleProgressLoader", false);
                    this.$store.commit("User/showSuccessAlert", res.data.result);


                    this.suggestData.forEach(x => {
                        if(JSON.stringify(x._id) === JSON.stringify(uid)){
                            x.msg = "following";
                        }
                        
                    });

                    // FIREBASE ANLYTICS
                    firebaseAnlytics.logEvent("New Follow Operation on Home from suggestions");                
                })
                .catch((err) => {
                    
                    this.$store.commit("User/toggleProgressLoader", false);
                    console.log("Error in watch",err);
                    let errorMsg = utilities.errorHandler(err);
                    this.$store.commit("User/showErrorAlert", errorMsg);
                
                });

        },

        getSuggestions() {
            
            let endPoint = apis.urls.getSuggestions;
            
            axios(endPoint, {
                method          :   "get",
                withCredentials :   true,
                headers         : { authorization: this.userData.token }
            })
            .then((res) => {
                
                if (res.data.result) {
                    
                    this.userId = res.data.result._id;
                    var userAlreadyFollowing = res.data.result.userFollowing;
                    var tempSuggestData = res.data.result.suggest;
                    
                    // To put all the data in one single array
                    let finalCycle = [];
                    for (let i = 0; i < tempSuggestData.length; i++) {
                    for (let j = 0; j < tempSuggestData[i].following.length; j++) {
                        // To not show the user the himself as a suggestions
                        if (tempSuggestData[i].following[j]._id != this.userId) {
                            finalCycle.push(tempSuggestData[i].following[j]);
                        }
                    }
                    }
                    
                    for (let jm = 0; jm < finalCycle.length; jm++) {
                        // console.log("docs ", jm, " ", finalCycle[jm])
                    }
                    
                    // Removing all the duplicates from the array
                    for (let i = 0; i < finalCycle.length; i++) {
                    for (let j = 0; j < finalCycle.length; j++) {
                        if (i != j) {
                        if (finalCycle[i]._id == finalCycle[j]._id) {
                            finalCycle.splice(j, 1);
                        }
                        }
                    }
                    }
                    
                    for (let jm = 0; jm < finalCycle.length; jm++) {
                        // console.log("docs ", jm, " ", finalCycle[jm])
                    }
                    
                    var uniqueChars = [];
                    
                    function doesFollow(myid) {
                    for (let i = 0; i < userAlreadyFollowing.length; i++) {
                        if (userAlreadyFollowing[i]._id == myid) {
                        return true;
                        }
                    }
                    return false;
                    }

                    var uniqueChars = [];
                    for (let j = 0; j < finalCycle.length; j++) {
                    if (!doesFollow(finalCycle[j]._id)) {
                        uniqueChars.push(finalCycle[j]);
                    }
                    }
                    this.suggestData = uniqueChars;
                    
                    var suggestDataWithMsg = uniqueChars.map((x)=>{
                        
                        return {
                        email : x.email,
                        followers : x.followers,
                        profile: x.profile,
                        _id : x._id,
                        msg: "follow"
                    }
                    });
                    
                               console.log("suggestions : ", suggestDataWithMsg);
                
                suggestDataWithMsg = suggestDataWithMsg.sort(() => Math.random() - 0.5)
                
                this.suggestData = suggestDataWithMsg;

                console.log("shuffled : ", this.suggestData);


                    if (this.suggestData.length == 1) {
                    this.isLimitedLength = false;
                    }


                }

                // FIREBASE ANLYTICS
                firebaseAnlytics.logEvent("Suggestions Displayed on Home Page");
            
            })
            .catch((err) => { console.log("getSuggestions Error : ", err) });
        
        },

        openInNewPage() {
            window.open('https://staticpages.preskilet.com/aboutus', '_blank')
        }
        
    }
};
</script>
