<template>
<div>
    
    <v-container>

        <v-layout row wrap justify-space-around>
            
            <v-flex xs11 md6>
                
                <v-card flat>
                    
                    <v-img
                    class="white--text align-end"
                    :src="vectorImages.signout"
                    ></v-img>

                    <div class="d-flex flex-no-wrap justify-space-between">
                    
                        <div>
                        
                            <v-card-title
                            class="headline"
                            v-text="'Please wait... While we are sigining you out...'"
                            ></v-card-title>
                        </div>
                    
                    </div>
                
                </v-card>
            
            </v-flex>
            
            <v-flex xs11 md4> </v-flex>
        
        </v-layout>

    </v-container>

</div>
</template>

<style scoped>

.form-container {
  padding   : 20px;
}

</style>

<script>

import { mapGetters } from "vuex";
import utilities from "@/helpers/utilities";
import apis from "../helpers/apiConfig";
const axios = require("axios").default;
import firebase from "firebase/app";
import "firebase/auth";

import "firebase/firebase-analytics";
var firebaseAnlytics;


export default {
    
    name        : "SignOut",
    
    computed    : { ...mapGetters({ userData: "User/userData" }) },
  
    mounted() {

        // FIREBASE ANLYTICS
        firebaseAnlytics = firebase.analytics();
        firebaseAnlytics.logEvent("SignOut Page");
        
        this.$store.commit("User/toggleProgressLoader", false);
        this.$store.dispatch("User/updateUserToken", null, { root: true });
        this.$store.commit("User/isSignedIn");

        let that = this;
        
        firebase
        .auth()
        .signOut()
        .then(function() {
            that.revokeToken();
                            window.location.href = "/";

        })
        .catch(function(errorMsg) {
            
            console.log("Error in SignOut",errorMsg);
            that.$store.commit("User/showErrorAlert", errorMsg);
        
        });
    
    },

    data() {
        
        return {
            vectorImages: utilities.vectorImages,
        };

    },

    methods: {
        
        revokeToken() {
            
            let endPoint = apis.urls.signOut;
            let that = this;

            axios(endPoint, {
                method          : "Post",
                withCredentials : true,
                headers         : { Authorization: this.userData.token }
            })
            .then((res) => {
                
                this.$store.commit("User/toggleProgressLoader", false);
                console.log(res);
                that.$store.commit("User/signOut");
                window.location.href = "/";
            
            })
            .catch((err) => {
                
                this.$store.commit("User/toggleProgressLoader", false);
                console.log("Error in Sign Out",err);
                let errorMsg = utilities.errorHandler(err);
                this.$store.commit("User/showErrorAlert", errorMsg);
            
            });

        }

    }

};

</script>
