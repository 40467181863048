<template>
    <div :style="`margin-top: -3%;margin-bottom: 3%;padding-left: 5%;padding-right: 5%;padding-bottom: 5%;background-color: ${mergedConfig.core.colors.primary};`">
        <v-card-title class="text-h3">
            <p
                style="
                font-family: 'Poppins', sans-serif;
                font-weight: 700;
                font-size: 52px;
                line-height: 125%;
                margin: 0 auto;
                margin-bottom: 3%;
                "
            >
                <span :style="`color:${mergedConfig.core.colors.primary};-webkit-text-stroke: 2px ${mergedConfig.core.colors.secondary}`">
                    {{ mergedConfig.info.title1 }}
                </span>
                
                <span :style="`color:${mergedConfig.core.colors.secondary};-webkit-text-stroke: 1px ${mergedConfig.core.colors.primary}`">
                    {{ mergedConfig.info.title2 }}
                </span>
                
            </p>
        </v-card-title>
        
        <v-card-subtitle v-if="mergedConfig.info.subTitle">
            <p
                :style="`color:${mergedConfig.core.colors.secondary};
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 24px;
                    margin: 0px;
                    margin-bottom: 3%;`"
            >
                {{ mergedConfig.info.subTitle }}
            </p>
        </v-card-subtitle>

        <v-row :style="`overflow-y: auto;max-height: ${mergedConfig.core.galleryMaxHeight};`">            
        <v-col
            v-for="imageItem in mergedConfig.imagesList"
            :key="imageItem.url"
            class="d-flex child-flex"
            cols="12"
            :md="12 / mergedConfig.core.tabletImages"
            :lg="12 / mergedConfig.core.laptopImages"
        >

                <v-img eager v-if="mergedConfig.core.onlyImages" :src="imageItem.url" aspect-ratio="1" :class="`grey lighten-2 ${imageItem.isCircle ? rounded-circle :  '' }`"></v-img>
                
                <v-card v-if="!mergedConfig.core.onlyImages" elevation="0" :style="`background-color: ${mergedConfig.core.colors.primary}`">
                    <div class="d-flex flex-no-wrap justify-start align-center">
                        <v-avatar :tile="imageItem.isTileShape" class="ma-3" size="125">
                            <v-img eager :src="imageItem.url"></v-img>
                        </v-avatar>
    
                        <div>
                            <v-card-title
                                :style="`
                                    font-family: 'Poppins', sans-serif;
                                    font-weight: 700;
                                    font-size: 34px;
                                    color:${mergedConfig.core.colors.secondary};
                                    margin-bottom: 5%;
                                    `"
                            >
                                {{ imageItem.title }}
                            </v-card-title>
        
                            <v-card-subtitle
                                :style="`
                                    font-family: 'Poppins', sans-serif;
                                    font-size: 16px;
                                    color:${mergedConfig.core.colors.secondary};
                                `"
                                >
                                {{imageItem.subTitle}}
                            </v-card-subtitle>
                        </div>
                    </div>
                </v-card>

            <template v-slot:placeholder>
                <v-row
                class="fill-height ma-0"
                align="center"
                justify="center"
                >
                <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                ></v-progress-circular>
                </v-row>
            </template>
        </v-col>
        </v-row>
</div>

</template>



<script>
  import { merge } from 'lodash';

  export default {
    name : "Multimedia",
    props: { config: { type: Object, default: () => ({}) } },
    data() { 
        return {
           defaultConfig: {
            // order is for -> mobile phones
            // order MD is for -> medim device
                info: {
                    title1: "Whats there in Preskilet for",
                    title2: "44 LPA",
                    subTitle: "",
                },
                core: {
                    galleryMaxHeight: "100vh",
                    onlyImages: false,
                    tabletImages: 3,
                    laptopImages: 4,
                    colors: {
                      primary: "#2196f3",
                      secondary: "white",
                    },
                    media: {
                        blocksPerLine: "12",
                    },
                },
                imagesList: [
                    // {
                    //     url: "https://picsum.photos/500/300?image=534",
                    //     title: "10 LPA",
                    //     subTitle: "Shoaib Shaikh",
                    // },
                    // {
                    //     url: "https://picsum.photos/500/300?image=98",
                    //     title: "10 LPA",
                    //     subTitle: "Shoaib Shaikh",
                    // },
                ],
            }
        }
    },
    computed: {
        mergedConfig() {
            return merge({}, this.defaultConfig, this.config);
        },
    },
  };
</script>