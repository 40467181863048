<template>
    <div>
        <CentralImageBlock :config="centralImageBlock" />
        <MultimediaBlock :config="preskiletConfig" />
        <TimelineBlock :config="processConfig" />
        <MultimediaBlock :config="teacherConfig" />
        <MultimediaBlock :config="studentConfig" />
        <MultimediaBlock :config="recruiterConfig" />
        <CentralInfoBlock :config="centralInfoBlock"  />
        <GalleryBlock :config="galleryBlockConfig" />
        <GalleryBlock :config="galleryBlockConfigOnlyImages" />
    </div>
</template>
    
<script>

import MultimediaBlock from "./blocks/MultimediaBlock";
import TimelineBlock from "./blocks/TimelineBlock";
import CentralInfoBlock from "./blocks/CentralInfoBlock";
import GalleryBlock from "./blocks/GalleryBlock";
import CentralImageBlock from "./blocks/CentralImageBlock";

export default {
    name: "StaticPagesTemplate",
    components: { MultimediaBlock, TimelineBlock, CentralInfoBlock, GalleryBlock, CentralImageBlock },
    data() { 
        return {
            centralImageBlock: {
                core: {
                colors: {
                  primary: "white",
                  secondary: "#2196f3",
                  third: "black"
                },
              },
              content: [
              {
                // only for center title
                mdCols: "12",
                topTitleText: `This is how you get a center title`,
                topTitleColor: "#2196f3",
                
            },
              {
                  // Below is only on laptop
                  // for image at center - 6
                  // for image at right to corner - 12
                  rowTitle: "Something more for you !",
                  rowTitleColor: "",

                  theme: "",
                  borderColor: "",
                  mdCols: "5",
                  imagePosition: "right",
                  url: "https://cdn.dribbble.com/users/1046956/screenshots/5931773/giftboxanimation6x8.gif",
                  topTitleText: `Our gift to you - "Absolutely Free !`,
                  topTitleColor: "#2196f3",
                  
                  topTitleText2: "Hope you like it",
                  topTitleColor2: "red",
                  
                  bottomTitleText: `Our gift to you - "Absolutely Free !`,
                  bottomTitleColor: "#2196f3",

                  bottomTitleText2: "Hope you like it",
                  bottomTitleColor2: "red",
                  
                  subTitleText: "A lot of people are technically very sound in their respective field but because they lack soft skills it affects adversely in thier career! Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptates animi facilis magni ducimus quas fugit nisi incidunt dolor iste, ab rerum nesciunt at fugiat, laudantium numquam ratione vel corrupti vitae. Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptates animi facilis magni ducimus quas fugit nisi incidunt dolor iste, ab rerum nesciunt at fugiat, laudantium numquam ratione vel corrupti vitae.",
                  subTitleColor: "black",
                  avatorSize: "125",
                  isAvatorCircle: true,
                },
                {
                  // Below is only on laptop
                  // for image at center - 6
                  // for image at right to corner - 12
                  theme: "",
                  borderColor: "",
                  mdCols: "5",
                  imagePosition: "right",
                  url: "https://cdn.dribbble.com/users/1046956/screenshots/5931773/giftboxanimation6x8.gif",
                  topTitleText: `Our gift to you - "Absolutely Free !`,
                  topTitleColor: "#2196f3",
                  
                  topTitleText2: "Hope you like it",
                  topTitleColor2: "red",
                  
                  bottomTitleText: `Our gift to you - "Absolutely Free !`,
                  bottomTitleColor: "#2196f3",

                  bottomTitleText2: "Hope you like it",
                  bottomTitleColor2: "red",
                  
                  subTitleText: "A lot of people are technically very sound in their respective field but because they lack soft skills it affects adversely in thier career! Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptates animi facilis magni ducimus quas fugit nisi incidunt dolor iste, ab rerum nesciunt at fugiat, laudantium numquam ratione vel corrupti vitae. Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptates animi facilis magni ducimus quas fugit nisi incidunt dolor iste, ab rerum nesciunt at fugiat, laudantium numquam ratione vel corrupti vitae.",
                  subTitleColor: "black",
                  avatorSize: "125",
                  isAvatorCircle: true,
                },

                {
                  // Below is only on laptop
                  // for image at center - 6
                  // for image at right to corner - 12
                  rowTitle: "Something more for you !",
                  rowTitleColor: "",

                  theme: "",
                  borderColor: "",
                  mdCols: "12",
                  url: "https://cdn.dribbble.com/users/1046956/screenshots/5931773/giftboxanimation6x8.gif",
                  topTitleText: `Our gift to you - "Absolutely Free !`,
                  topTitleColor: "green",
                  
                  topTitleText2: "Hope you like it",
                  topTitleColor2: "yellow",
                  
                  bottomTitleText: `Our gift to you - "Absolutely Free !`,
                  bottomTitleColor: "green",

                  bottomTitleText2: "Hope you like it",
                  bottomTitleColor2: "yellow",
                  
                  subTitleText: "A lot of people are technically very sound in their respective field but because they lack soft skills it affects adversely in thier career! Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptates animi facilis magni ducimus quas fugit nisi incidunt dolor iste, ab rerum nesciunt at fugiat, laudantium numquam ratione vel corrupti vitae. Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptates animi facilis magni ducimus quas fugit nisi incidunt dolor iste, ab rerum nesciunt at fugiat, laudantium numquam ratione vel corrupti vitae.",
                  subTitleColor: "black",
                  avatorSize: "125",
                  isAvatorCircle: true,
                },
              ],

            },
            preskiletConfig: {
                core: {
                    info: {
                        orderMd: "2",
                        order: "1",
                    },
                    media: {
                        orderMd: "1",
                        order: "2",
                    },                
                },
                info: {
                    title1: "Present your soft skill set with",
                    title2: "Preskilet.",
                    subTitle: "It helps student to improve their presentation skills so that they can present themselves in front of the recruiters without any fear and it helps teachers to take presentations with less efforts and less time.",
                    ctaButton: {
                        isShow: true,
                        link: "/signin"
                    }
                },
                videos: [
                    {
                        title: "Watch what Recruiters witnessed on Preskilet",
                        URL: "https://www.youtube.com/embed/qe41pmUrPdI?si=AR90fZzN7KbXjxop&amp;start=1"
                    },
                ],
                images: [
                    {
                        URL: "https://firebasestorage.googleapis.com/v0/b/githubsearch-acbac.appspot.com/o/vaibhav%20bhosale.png?alt=media&token=1e13348b-78e1-4c6f-866d-787f1dfb4bba&_gl=1*17ijxux*_ga*MTY4NDYwMzMzNi4xNjkxMjMwNjM4*_ga_CW55HF8NVT*MTY5NjMzNTQ3OS40LjEuMTY5NjMzNTg0Ni41MC4wLjA."
                    }
                ],
            },
            processConfig: {
                processDetails: [
                    {
                        entity: "College",
                        img: "https://img.freepik.com/premium-vector/isolated-school-building-landscape-with-grass-clouds-urban-exterior_912383-537.jpg?w=360",
                        title: "College create activity",
                        details: "Colleges create presentation activities for students, such as academic presentations, project presentations, video resumes, and internship presentations, transforming the traditional physical presentation process into a digital one.",
                    },
                    {
                        entity: "Student",
                        img: "https://static.vecteezy.com/system/resources/thumbnails/000/350/111/small/Education__28193_29.jpg",
                        title: "Student upload video",
                        details: "Students record their presentation videos and upload them to the platform. They receive constructive feedback from both teachers and peers, helping them pinpoint areas for improvement. With this valuable input, they refine their skills in subsequent videos.",
                    },
                    {
                        entity: "Profile",
                        img: "https://img.freepik.com/premium-vector/video-blogger-internet-blogger-recording-media-content-watching-video-internet-vector-illustration_123447-3630.jpg?w=2000",
                        title: "Studet get Profile",
                        details: "When students are ready for job placements, all the presentation videos they've uploaded from their first academic year to their final academic year along with a video resume are transformed into a comprehensive video profile that they can share with recruiters",
                    },
                ],
            },
            teacherConfig: {
                core: {
                    info: {
                        orderMd: "1",
                        order: "1",
                    },
                    media: {
                        orderMd: "2",
                        order: "2",
                    },              
                },
                info: {
                    title1: "Whats there in Preskilet for",
                    title2: "Teachers?",
                    subTitle: " Teachers can efficiently take and evaluate student presentations in groups, whether they belong to a batch, division, or department, all in a digital format. This transformation drastically reduces the time required, going from 2 working days for physical presentations to just 2 hours. Additionally, our smart grades automation system allows them to download mark sheets in seconds, eliminating the need to spend 2 hours on manual mark sheet preparation.",
                    ctaButton: {
                        isShow: false,
                    }
                },
                videos: [
                    {
                        title: "Watch what teachers say about Preskilet",
                        URL: "https://www.youtube.com/embed/qe41pmUrPdI?si=AR90fZzN7KbXjxop&amp;start=1"
                    },
                ],
                images: [
                    {
                        URL: "https://firebasestorage.googleapis.com/v0/b/githubsearch-acbac.appspot.com/o/Mobile%20mockup%20with%20mobile%20dimensions.png?alt=media&token=c77366c6-8214-4649-b82e-60b1eabfe4bf&_gl=1*aiz7u9*_ga*MTY4NDYwMzMzNi4xNjkxMjMwNjM4*_ga_CW55HF8NVT*MTY5NjQxNTUyNi42LjEuMTY5NjQxNTc5NS4zMy4wLjA."
                    }
                ],
            },
            studentConfig: {
                core: {
                    colors: {
                      primary: "white",
                      secondary: "#2196f3",
                      third: "#2196f3",
                    },
                    info: {
                        orderMd: "2",
                        order: "1",
                    },
                    media: {
                        orderMd: "1",
                        order: "2",
                    },                
                },
                info: {
                    title1: "Whats there in Preskilet for",
                    title2: "Students?",
                    subTitle: "On Preskilet, students can record their own presentation videos and upload them to the platform as submissions for presentation activities. They can also upload voluntary videos. Students can receive feedback on their presentation videos from teachers and classmates. At the end of their academics, students receive a video profile, including a video resume, which they can share with recruiters.",
                    ctaButton: {
                        isShow: false,
                    }
                },
                videos: [
                    {
                        title: "Watch what students say about Preskilet",
                        URL: "https://www.youtube.com/embed/qe41pmUrPdI?si=AR90fZzN7KbXjxop&amp;start=1"
                    },
                ],
                images: [
                    {
                        URL: "https://firebasestorage.googleapis.com/v0/b/githubsearch-acbac.appspot.com/o/Student%20image.png?alt=media&token=651c092c-2698-4047-96fc-b599e1da0b36&_gl=1*4nd62w*_ga*MTY4NDYwMzMzNi4xNjkxMjMwNjM4*_ga_CW55HF8NVT*MTY5NjQyMDcwMi43LjEuMTY5NjQyMDcwNy41NS4wLjA."
                    }
                ],
            },
            recruiterConfig: {
                core: {
                    info: {
                        orderMd: "1",
                        order: "1",
                    },
                    media: {
                        orderMd: "2",
                        order: "2",
                    },                
                },
                info: {
                    title1: "Whats there in Preskilet for",
                    title2: "Recruiters?",
                    subTitle: "Recruiters benefit from our platform by accessing a comprehensive video profile, including video resumes and technical presentation videos, of students from all our client colleges in one centralized location, tailored to their specific needs. This streamlined process significantly reduces the time and resources required to interact with individual colleges, making recruitment more efficient and effective.",
                    ctaButton: {
                        isShow: false,
                    }
                },
                videos: [
                    {
                        title: "Watch what Recruiters witnessed on Preskilet",
                        URL: "https://www.youtube.com/embed/qe41pmUrPdI?si=AR90fZzN7KbXjxop&amp;start=1"
                    },
                ],
                images: [
                    {
                        URL: "https://firebasestorage.googleapis.com/v0/b/githubsearch-acbac.appspot.com/o/Student%20presenting%20for%20recruiter.png?alt=media&token=2a688e4a-9438-4e0c-8129-53c526f7c95b&_gl=1*zftbng*_ga*MTY4NDYwMzMzNi4xNjkxMjMwNjM4*_ga_CW55HF8NVT*MTY5NjQyMDcwMi43LjEuMTY5NjQyMjc5MC41NS4wLjA."
                    },
                    {
                        URL: "https://firebasestorage.googleapis.com/v0/b/githubsearch-acbac.appspot.com/o/Laptop%20Preskilet%20mock%20images.png?alt=media&token=f11aa992-a6f4-4c2b-9062-565010bc3b3c&_gl=1*px6vz7*_ga*MTY4NDYwMzMzNi4xNjkxMjMwNjM4*_ga_CW55HF8NVT*MTY5NjQyMDcwMi43LjEuMTY5NjQyMjYzNy42MC4wLjA."
                    }
                ],
            },
            centralInfoBlock: {
                core: {
                    colors: {
                      primary: "white",
                      secondary: "#2196f3",
                      third: "#2196f3",
                    },
                    semiBlock1: {
                        cols: "12",
                        md: "12",
                        orderMd: "1",
                        order: "1",
                    },
                    semiBlock2: {
                        cols: "12",
                        md: "6",
                        orderMd: "2",
                        order: "2",
                    },
                    semiBlock3: {
                        cols: "12",
                        md: "6",
                        orderMd: "3",
                        order: "3",
                    },
                },
                info: {
                    title1: "Why college should have",
                    title2: "preskilet?",
                    subTitle: "Elevate Student Presentation Skills and Attract Higher-Paying Opportunities for Students with Preskilet",
                },
                imgUrl: "https://firebasestorage.googleapis.com/v0/b/githubsearch-acbac.appspot.com/o/Placement%20package%20anlytics.png?alt=media&token=5e970502-33f3-4e63-91ae-94aad971eb0b&_gl=1*moqq5y*_ga*MTY4NDYwMzMzNi4xNjkxMjMwNjM4*_ga_CW55HF8NVT*MTY5NjQyNjExNy44LjEuMTY5NjQyNjEyNC41My4wLjA.",
                description: "Preskilet has proven to be a game-changer in student success and college excellence. In 2022, students equipped with Preskilet profiles achieved placements at 10 LPA, and within a year, in 2023, they secured remarkable offers at 44 LPA. This substantial growth is a testament to how students improve their confidence and presentation skills through our platform, resulting in a higher placement percentage. Moreover, they gain a valuable video profile that can be utilized anytime they desire. Additionally, colleges benefit by showcasing their impressive student video repository to the world, further enhancing their reputation and attracting top talent.",
            },
            galleryBlockConfig: {
                info: {
                    title1: "Whats there in Preskilet for",
                    title2: "44 LPA",
                    subTitle: "",
                },
                core: {
                    galleryMaxHeight: "100vh",
                    onlyImages: false,
                    tabletImages: 3,
                    laptopImages: 4,
                    colors: {
                      primary: "#2196f3",
                      secondary: "white",
                    },
                    media: {
                        blocksPerLine: "12",
                    },
                },
                imagesList: [
                    {
                        url: "https://picsum.photos/500/300?image=12",
                        title: "44 LPA",
                        subTitle: "Pooja Kumari",
                        // isTileShape: true,
                    },
                    {
                        url: "https://picsum.photos/500/300?image=17",
                        title: "4 LPA",
                        subTitle: "Dhanesh Pawar",
                    },
                    {
                        url: "https://picsum.photos/500/300?image=121",
                        title: "10 LPA",
                        subTitle: "Shoaib Shaikh",
                    },
                    {
                        url: "https://picsum.photos/500/300?image=23",
                        title: "10 LPA",
                        subTitle: "Shoaib Shaikh",
                    },
                    {
                        url: "https://picsum.photos/500/300?image=45",
                        title: "10 LPA",
                        subTitle: "Shoaib Shaikh",
                    },
                    {
                        url: "https://picsum.photos/500/300?image=345",
                        title: "10 LPA",
                        subTitle: "Shoaib Shaikh",
                    },
                    {
                        url: "https://picsum.photos/500/300?image=235",
                        title: "10 LPA",
                        subTitle: "Shoaib Shaikh",
                    },
                    {
                        url: "https://picsum.photos/500/300?image=452",
                        title: "10 LPA",
                        subTitle: "Shoaib Shaikh",
                    },
                    {
                        url: "https://picsum.photos/500/300?image=567",
                        title: "10 LPA",
                        subTitle: "Shoaib Shaikh",
                    },
                    {
                        url: "https://picsum.photos/500/300?image=456",
                        title: "10 LPA",
                        subTitle: "Shoaib Shaikh",
                    },
                    {
                        url: "https://picsum.photos/500/300?image=342",
                        title: "10 LPA",
                        subTitle: "Shoaib Shaikh",
                    },
                    {
                        url: "https://picsum.photos/500/300?image=65",
                        title: "10 LPA",
                        subTitle: "Shoaib Shaikh",
                    },
                    {
                        url: "https://picsum.photos/500/300?image=534",
                        title: "10 LPA",
                        subTitle: "Shoaib Shaikh",
                    },
                    {
                        url: "https://picsum.photos/500/300?image=98",
                        title: "10 LPA",
                        subTitle: "Shoaib Shaikh",
                    },
                ],
            },
            galleryBlockConfigOnlyImages: {
                info: {
                    title1: "Whats there in Preskilet for",
                    title2: "44 LPA",
                    subTitle: "",
                },
                core: {
                    galleryMaxHeight: "100vh",
                    onlyImages: true,
                    tabletImages: 3,
                    laptopImages: 4,
                    colors: {
                      primary: "#2196f3",
                      secondary: "white",
                    },
                    media: {
                        blocksPerLine: "12",
                    },
                },
                imagesList: [
                    {
                        url: "https://picsum.photos/500/300?image=12",
                        title: "44 LPA",
                        subTitle: "Pooja Kumari",
                        // isTileShape: true,
                    },
                    {
                        url: "https://picsum.photos/500/300?image=17",
                        title: "4 LPA",
                        subTitle: "Dhanesh Pawar",
                    },
                    {
                        url: "https://picsum.photos/500/300?image=121",
                        title: "10 LPA",
                        subTitle: "Shoaib Shaikh",
                    },
                    {
                        url: "https://picsum.photos/500/300?image=23",
                        title: "10 LPA",
                        subTitle: "Shoaib Shaikh",
                    },
                    {
                        url: "https://picsum.photos/500/300?image=45",
                        title: "10 LPA",
                        subTitle: "Shoaib Shaikh",
                    },
                    {
                        url: "https://picsum.photos/500/300?image=345",
                        title: "10 LPA",
                        subTitle: "Shoaib Shaikh",
                    },
                    {
                        url: "https://picsum.photos/500/300?image=235",
                        title: "10 LPA",
                        subTitle: "Shoaib Shaikh",
                    },
                    {
                        url: "https://picsum.photos/500/300?image=452",
                        title: "10 LPA",
                        subTitle: "Shoaib Shaikh",
                    },
                    {
                        url: "https://picsum.photos/500/300?image=567",
                        title: "10 LPA",
                        subTitle: "Shoaib Shaikh",
                    },
                    {
                        url: "https://picsum.photos/500/300?image=456",
                        title: "10 LPA",
                        subTitle: "Shoaib Shaikh",
                    },
                    {
                        url: "https://picsum.photos/500/300?image=342",
                        title: "10 LPA",
                        subTitle: "Shoaib Shaikh",
                    },
                    {
                        url: "https://picsum.photos/500/300?image=65",
                        title: "10 LPA",
                        subTitle: "Shoaib Shaikh",
                    },
                    {
                        url: "https://picsum.photos/500/300?image=534",
                        title: "10 LPA",
                        subTitle: "Shoaib Shaikh",
                    },
                    {
                        url: "https://picsum.photos/500/300?image=98",
                        title: "10 LPA",
                        subTitle: "Shoaib Shaikh",
                    },
                ],
            }
        }
    },
};
</script>
    