<template>
<div>
    
    <v-container>
        
        <v-layout row wrap justify-space-around>

            <v-flex xs11 md6>
                <v-card color="white" outlined>

                    <div class="d-flex flex-no-wrap justify-space-between">

                        <div>

                            <v-card-title
                            class="headline"
                            v-text="'Profile Picture'"
                            ></v-card-title>

                            <v-card-subtitle
                            v-text="
                                'You need to change your profile picture on gmail and then once sign-out and sign-in again to update your profile picture here'
                            "
                            ></v-card-subtitle>

                        </div>

                        <v-avatar class="ma-3" size="100" tile>

                            <v-img style="border-radius:10px;" :src="profilePhoto"></v-img>

                        </v-avatar>

                    </div>

                </v-card>

                <br />

                <v-card color="white" outlined>

                    <v-form
                    ref="form"
                    v-model="valid"
                    lazy-validation
                    class="form-container"
                    >
                        <p style="color: red;font-size: 16px;"> <b>Note : You can edit this Information later in profile section.</b></p>


                        <v-text-field
                            v-model="name"
                            counter="100"
                            :rules="nameRules"
                            label="Name"
                            required
                            :disabled="disabled"
                            clearable
                        ></v-text-field>

                        <br />

                           <!-- <span v-for="item in Object.keys(clientCollegeList)">
                            <v-btn
                            
                            class="mt-5"
                            outlined
                            rounded
                            small
                            @click="selectedCollege = item, organizationName = null"
                        >
                            {{ clientCollegeList[item] }}
                            <v-icon v-if="selectedCollege === item" color="primary" right>
                                mdi-checkbox-marked-circle
                            </v-icon>
                        </v-btn>
                        <br />

                        </span> -->


                        <!-- <div v-if="selectedCollege && selectedCollege != 'NOTA'" >
                            <br /><hr /><br />

                            <v-btn
                                class="mt-5"
                                outlined
                                color="primary"
                                router to="/signout"
                            >
                                Sign out
                            </v-btn>

                            <br /><br />
                            <p>
                                You are logged in using the following email id
                                <br />
                                <b>{{ userData.email }}</b>
                            </p>

                            <h3 style="color: red">Please login using your college email id</h3>
                            <p>Sign out by clicking on above button and signin again using your college email id </p>

                          



                        <br />



                        </div> -->
                        <h3>Please select your college</h3>

                        <v-list two-line v-for="(item, index) in institutionList">
                            
                            <v-list-item  style="border: 2px solid black; border-radius: 5px;"
                                @click="selectedCollege = item.name, organizationName = null"
                            >
                                <v-list-item-avatar>
                                    <v-img :src="item.logo"></v-img>
                                </v-list-item-avatar>

                                <v-list-item-content>
                                    <v-list-item-title v-html="item.name"></v-list-item-title>
                                    <v-list-item-subtitle v-html="item.address"></v-list-item-subtitle>
                                </v-list-item-content>

                                <v-list-item-action v-if="selectedCollege === item.name">
          <v-btn icon>
            <v-icon color="blue">mdi-checkbox-marked-circle</v-icon>
          </v-btn>
        </v-list-item-action>
                            </v-list-item>

                        </v-list>

                        <v-list two-line>
                            
                        <v-list-item style="border: 2px solid black; border-radius: 5px;"
                                @click="selectedCollege = 'NOTA', organizationName = null"
                            >
                                <v-list-item-avatar>
                                    <v-btn icon>
            <v-icon color="blue">mdi-cancel</v-icon>
          </v-btn>
                                </v-list-item-avatar>

                                <v-list-item-content>
                                    <v-list-item-title v-html="'None of the above'"></v-list-item-title>
                                    <v-list-item-subtitle v-html="'Select if your college is not in the list, so you can manually enter'"></v-list-item-subtitle>
                                </v-list-item-content>

                                <v-list-item-action v-if="selectedCollege === 'NOTA'">
          <v-btn icon>
            <v-icon color="blue">mdi-checkbox-marked-circle</v-icon>
          </v-btn>
        </v-list-item-action>
                            </v-list-item> 
</v-list>
                     

                        <div v-if="selectedCollege === 'NOTA'">
                                <p style="color: red;font-size: 15px;">Note : Please type your college name below </p>
                            
                            <v-combobox
                                :items="organizationTerms"
                                v-model="organizationName"
                                counter="100"
                                :rules="organizationNameRules"
                                label="College / Organization Name"
                                required
                                :disabled="disabled"
                                clearable
                            ></v-combobox>
                        </div>

                        <div v-if="selectedCollege">
                        
                            <v-combobox
                                :items="departmentTerms"
                                v-model="departmentName"
                                counter="100"
                                :rules="departmentNameRules"
                                label="Department Name"
                                required
                                :disabled="disabled"
                                clearable
                            ></v-combobox>
                            
                            <v-select
                                :disabled="disabled"
                                v-model="genderType"
                                :items="genderTypes"
                                label="Select Gender Type"
                                data-vv-name="select"
                                :rules="[(v) => !!v || 'Gender type is required']"
                                required
                            ></v-select>
                            
                            <v-select
                                :disabled="disabled"
                                v-model="professionType"
                                :items="professionTypes"
                                label="Select Profession Type"
                                data-vv-name="select"
                                :rules="[(v) => !!v || 'Profession type is required']"
                                required
                            ></v-select>
                            
                            <v-combobox
                                v-if="professionType === 'Student'"
                                :items="regnoTerms"
                                v-model="regno"
                                counter="100"
                                :rules="regnoRules"
                                label="Registration Number /  PRN "
                                required
                                :disabled="disabled"
                                clearable
                            ></v-combobox>
                            
                            <v-combobox
                                v-if="professionType === 'Student'"
                                :items="seatnoTerms"
                                v-model="seatno"
                                counter="100"
                                :rules="seatnoRules"
                                label="Exam Seat Number"
                                required
                                :disabled="disabled"
                                clearable
                            ></v-combobox>


                            <v-combobox
                                v-if="professionType === 'Student'"
                                :items="rollnoTerms"
                                v-model="rollno"
                                counter="100"
                                :rules="rollnoRules"
                                label="Roll Number"
                                required
                                :disabled="disabled"
                                clearable
                            ></v-combobox>

                            <v-combobox
                                v-if="professionType === 'Student'"
                                :items="divisionTerms"
                                v-model="division"
                                counter="100"
                                :rules="divisionRules"
                                label="Division Name"
                                required
                                :disabled="disabled"
                                clearable
                            ></v-combobox>

                            <v-combobox
                                v-if="professionType === 'Student'"
                                :items="batchTerms"
                                v-model="batch"
                                counter="100"
                                :rules="batchRules"
                                label="Batch Name"
                                required
                                :disabled="disabled"
                                clearable
                            ></v-combobox>


                            <br />

                            <div class="text-right">

                                <v-btn
                                class="ma-2"
                                color="primary"
                                depressed
                                v-on:click="validate"
                                :disabled="disabled"
                                >

                                Save & Continue  <v-icon dark right class="ma-2 pa-2">mdi-send</v-icon>

                                </v-btn>

                            </div>
                        </div>

                    </v-form>

                </v-card>

            </v-flex>

            <v-flex xs11 md4>

            </v-flex>

        </v-layout>

    </v-container>

</div>
</template>

<style scoped>

.form-container {
  padding   : 20px;
}

</style>

<script>

import { mapGetters } from "vuex";
import utilities from "@/helpers/utilities";
import apis from "../helpers/apiConfig";
const axios = require("axios").default;

import firebase from "firebase/app";
import "firebase/firebase-analytics";
var firebaseAnlytics;

// Google Adsense    
import Vue from "vue";
import Ads from 'vue-google-adsense'
Vue.use(require('vue-script2'))
Vue.use(Ads.Adsense)
Vue.use(Ads.InFeedAdsense)


export default {
    
    name        : "Profile",
    
    computed    : { ...mapGetters({ userData    : "User/userData" }) },
  
    mounted() {
 if (window.screen.availWidth <= 650) { this.cardFlatFlag = true; } 
        else { this.cardOutlineFlag = true; }

        // FIREBASE ANLYTICS
        firebaseAnlytics = firebase.analytics();
        firebaseAnlytics.logEvent("SelectAccountType Page");
        
        this.$store.commit("User/toggleProgressLoader", false);
        this.$store.dispatch("User/updateUserToken", null, { root: true });
        this.$store.commit("User/isSignedIn");

        if (this.userData.signedIn) {
            this.getProfileData();
            this.getInstitutions();
        } else {
            console.log("no one is logged in ");
        }

    },

    data() {
        
        return {
            institutionList: [],
            clientCollegeList: {
                MITAOE: "MIT Academy of Engineering, Alandi",
                MITACSC: "MIT ACSC College, Alandi",
                MITADT: "MIT ADT University, Loni Kalbhor",
                NOTA: "None of the above",
            },
            selectedCollege: "",
            cardFlatFlag    : true,
            cardOutlineFlag : false,
            profilePhoto    : "",
            disabled        : false,
            valid           : true,
            
            name            : "",
            nameRules       : [
                (v) => !!v || "Name is required",
                (v) => (v && v.length <= 100) || "Name must be less than 100 characters",
            ],

            // organizationTerms       : ["MIT Academy Of Engineering", "Immense Spoken English and Soft Skills Institute", "Trinity College of Engineering and Research",  "Wordsmaya", "Walchand College of Engineering", "College of Engineering Pune","Pune Institute of Computer Technology (PICT), Pune","D.Y. Patil College of Engineering (DYPCOE)","Jayawant Shikshan Prasarak Mandal S Rajarshi Shahu College Of Engineering (RSCE)","MIT Art Design And Technology University (MIT ADTU) ","National Institute Of Construction Management And Research (NICMAR)","Vishwakarma Institute Of Technology (VIT)","Pimpri Chinchwad Education Trust (PCET)","Symbiosis Institute Of Geoinformatics (SIG)","Indira College Of Engineering And Management (ICEM)","Government Polytechnic Pune","Government Polytechnic, Awasari","All India Shri Shivaji Memorial Societys Polytechnic","D. Y. Patil Polytechnic, Ambi","Jaihind Polytechnic Kuran","Maeer’s Mit Polytechnic","Jayawantrao Sawant Polytechnic"],
            organizationTerms       : [],
            organizationName        : "",
            organizationNameRules   : [
                (v) => !!v || "College / Organization Name is required",
                (v) => (v && v.length <= 100) || "College / Organization must be less than 100 characters",
            ],

            // departmentTerms       : ["Not Applicable", "School Of Electrical Engineering","School Of Computer Engineering & Technology","School Of Mechanical & Civil Engineering","School Of Chemical Engineering","School Of Design","School Of Humanities And Engineering Sciences","Computer Department","Information Technology Department","Electrical Department","Civil Department","Mechanical Department"],
            departmentTerms       : [],
            departmentName        : "",
            departmentNameRules   : [
                (v) => !!v || "Department Name is required",
                (v) => (v && v.length <= 100) || "Department must be less than 100 characters",
            ],

            professionType        : "",
            // professionTypes       : ["Student", "Teacher", "Candidate", "Recruiter"],
            professionTypes       : ["Student", "Teacher"],
            
            genderType        : "",
            genderTypes       : ["Male", "Female", "Other"],

            regno: "",
            regnoTerms: [],
            regnoRules: [(v) => !!v || "Register Number is required", (v) => (v && v.length <= 100) || "Registration Number must be less than 100 characters" ],
            
            seatno: "",
            seatnoTerms: [],
            seatnoRules: [(v) => !!v || "Exam Seat Number is required", (v) => (v && v.length <= 100) || "Exam Seat Number must be less than 100 characters" ],
            
            rollno: "",
            rollnoTerms: [],
            rollnoRules: [(v) => !!v || "Roll Number is required", (v) => (v && v.length <= 100) || "Roll must be less than 100 characters" ],

            division: "",
            // divisionTerms: ["A1","B1","C1","D1","A2","B2","C2","D2","A3","B3","C3","D3","A4","B4","C4","D4"],
            divisionTerms: [],
            divisionRules: [(v) => !!v || "DivisionName is required", (v) => (v && v.length <= 100) || "Division Name must be less than 100 characters" ],

            batch: "",
            // batchTerms: ["A11","A12","A13","B11","B12","B13","C11","C12","C13","D11","D12","D13","A21","A22","A23","B21","B22","B23","C21","C22","C23","D21","D22","D23","A31","A32","A33","B31","B32","B33","C31","C32","C33","D31","D32","D33"],
            batchTerms: [],
            batchRules: [(v) => !!v || "Batch Name is required", (v) => (v && v.length <= 100) || "Division Name must be less than 100 characters" ],

        };

    },
    
    methods: {
        
        cancelFromEditing() {
            window.location.href = "/profile";
        },

        validate() {
            
            this.$refs.form.validate();
            
            if (this.$refs.form.validate()) {
                this.setProfiledata();
            }

        },

        reset() {
            this.$refs.form.reset();
        },

        resetValidation() {
            this.$refs.form.resetValidation();
        },

        setProfiledata() {
            
            this.$store.commit("User/toggleProgressLoader", false);
            window.localStorage.accountType = this.professionType;
            
            console.log("selectedCollege : ", this.selectedCollege);
            console.log("this.organizationName : ", this.organizationName);

            const organizationNameToSentToBack = this.selectedCollege !== "NOTA" ? this.selectedCollege : this.organizationName; 

            console.log("organizationNameToSentToBack : ", organizationNameToSentToBack);

            let data = {
                name            : this.name,
                account         : this.professionType,
                gender          : this.genderType,


                organization    : organizationNameToSentToBack,
                department      : this.departmentName,
                
                regno           : this.regno, 
                seatno          : this.seatno,
                rollno          : this.rollno,
                div             : this.division,
                batch           : this.batch     
            };

            console.log("Profile setup data : ",data);

            let endPoint = apis.urls.editProfile;
            this.$store.commit("User/toggleProgressLoader", true);
            
            axios(endPoint, {
                method          : "post",
                data,
                withCredentials : true,
                headers         : { authorization    : this.userData.token }
            })
            .then((res) => {
            
                console.log("success : ", res.data.result.message);
                this.$store.commit("User/toggleProgressLoader", false);
                this.disabled = true;
                this.$store.commit("User/showSuccessAlert", res.data.result.message);
                
                // FIREBASE ANLYTICS
                firebaseAnlytics.logEvent("Profile Data Updated on SelectAccountType Page");
                window.location.href = "/";
            
            })
            .catch((err) => {
                
                this.$store.commit("User/toggleProgressLoader", false);
                console.log("Error In Profile",err);
                let errorMsg = utilities.errorHandler(err);
                this.$store.commit("User/showErrorAlert", errorMsg);
            
                window.location.href = "/signin";

            });

        },

        getProfileData() {
            
            let endPoint = apis.urls.getProfile;
            this.$store.commit("User/toggleProgressLoader", true);
            
            axios(endPoint, {
                method          : "get",
                withCredentials : true,
                headers         : { authorization: this.userData.token }
            })
            .then((res) => {

                this.$store.commit("User/toggleProgressLoader", false);
                
                this.professionType = res.data.result.data.account;
                var mydata         = res.data.result.data.profile;
                
                
                this.name           = mydata.name;
                this.profilePhoto   = mydata.profilePhoto;
                this.organizationName   = mydata.organization;
                this.genderType = mydata.gender;
                this.departmentName     = mydata.department;
                this.regno          = mydata.regno;
                this.seatno          = mydata.seatno;
                this.rollno         = mydata.rollno;
                this.division            = mydata.div;
                this.batch          = mydata.batch;

                // FIREBASE ANLYTICS
                firebaseAnlytics.logEvent("Profile Data Retrived on SelectAccountType Page");
                
            })
            .catch((err) => {

                this.$store.commit("User/toggleProgressLoader", false);
                console.log("Error In Profile",err);
                let errorMsg = utilities.errorHandler(err);
                this.$store.commit("User/showErrorAlert", errorMsg);
            
            });

        },

        getInstitutions()
        {
            let endPoint = apis.urls.getInstitutions;
            this.$store.commit("User/toggleProgressLoader", true);
            
            axios(endPoint, {
                method          : "get",
                withCredentials : true,
                headers         : { authorization: this.userData.token }
            })
            .then((res) => {
                console.log("Institutions data : ",res);
                
                let data = res.data.result.data;
                this.institutionList = data;
                this.$store.commit("User/toggleProgressLoader", false);

                
                // FIREBASE ANLYTICS
                firebaseAnlytics.logEvent("Institution Data Displayed");
            
            })
            .catch((err) => {
                
                this.$store.commit("User/toggleProgressLoader", false);
                console.log("Error in institution data",err);
                let errorMsg = utilities.errorHandler(err);
                this.$store.commit("User/showErrorAlert", errorMsg);
            
            });
        },

    
    }

};

</script>
