module.exports = {
    
    send: (recipients, message, notificationTokensMdl, admin, callback) => {
        
        if (typeof callback !== "function") { callback = () => {} }
      
        console.log("Message object in notification module: ",message)
        
        var include = { _id: 0, token: 1 };
        
        notificationTokensMdl.find({userId: recipients }, include)
        .then((tokens) => {
            
            if (tokens.length != 0) {
                
                var tokensArray = [];
                
                for (let index = 0; index < tokens.length; index++) {
                    const element = tokens[index].token;
                    tokensArray.push(element)
                }

                const hostedEnv    = require("../keys/keys").hostedEnv;
                const registrationTokens = tokensArray;
                let host = (hostedEnv === 'local') ?  "http://localhost:8080" : "https://preskilet.com";
                
                const messageObject = {
                    
                    tokens              : registrationTokens,
                    notification        : {title: message.title},
                    
                    "webpush"           : 
                    {
                        "fcm_options"   : { "link"      : host + message.url },
                        "notification"  : 
                        {
                            "body"      : message.body,
                            // "requireInteraction": "true",
                            "icon"      : message.icon ? message.icon : "https://firebasestorage.googleapis.com/v0/b/preskiletdev.appspot.com/o/assets%2FPreskilet%20Icon.png?alt=media&token=925ad8ed-3940-42ac-bd24-102e65c1149d"
                        }
                    }
                }
            
                console.log("This is the MSG object : ", messageObject.tokens);
                
                admin.messaging().sendMulticast(messageObject)
                .then((response) => {
                    // Response is a message ID string.
                    console.log("Successfully sent message : ", response);
                    callback(null, true);
                })
                .catch((error) => {
                    console.log("Error sending message : ", error);
                    callback(error, null);
                });
            
            } else {
                console.log("|___ No tokens available to send push notifications. ___|");
            }
        
        })
        .catch((err) => {
            console.log("|___ Query Error : ___| ",err);
            callback(err, null);
        });
    
    }
    
};