<template>
    <div>
        <v-container v-if="!isAuthorised">
            <v-layout row wrap justify-space-around>
                <v-flex xs11 md6>
                    <v-card
                        flat
                        class="mx-auto"
                        max-width="400"
                        >
                            <v-img class="white--text align-end" :src="unAuthorisedState.image"></v-img>

                            <div class="d-flex flex-no-wrap justify-space-between">

                                <div>

                                    <v-card-title
                                    class="headline"
                                    v-text="'You need access'"
                                    ></v-card-title>

                                    <v-card-subtitle v-text="unAuthorisedState.message"></v-card-subtitle>

                                </div>

                            </div>
                        </v-card>
                </v-flex>

                <v-flex xs11 md4></v-flex>

                </v-layout>
                </v-container>


                <div id="fullscreen-container" v-if="isAuthorised">
        
  
        <iframe
            
            style="background: #F1F5F4;border: none;border-radius: 2px;box-shadow: 0 2px 10px 0 rgba(70, 76, 79, .2);width: 100vw;height: 100vh;"
            :src="anlyticsURL"></iframe>

            
    </div>


    </div>

    
</template>

<style scoped>
#fullscreen-container {
    position: fixed;
    margin-top: -3%;
}
</style>

<script>
import { mapGetters } from "vuex";
import utilities from "@/helpers/utilities";
import apis from "../helpers/apiConfig";
const axios = require("axios").default;

import firebase from "firebase/app";
import "firebase/firebase-analytics";
var firebaseAnlytics;

// Google Adsense    
import Vue from "vue";
import Ads from 'vue-google-adsense'
Vue.use(require('vue-script2'))
Vue.use(Ads.Adsense)
Vue.use(Ads.InFeedAdsense)

export default {

    name: "Analytics",

    computed: { ...mapGetters({ userData: "User/userData" }) },

    mounted() {

        if (window.screen.availWidth <= 650) { this.cardFlatFlag = true; }
        else { this.cardOutlineFlag = true; }

        // FIREBASE ANLYTICS
        firebaseAnlytics = firebase.analytics();
        firebaseAnlytics.logEvent("Analytics Page");

        this.$store.commit("User/toggleProgressLoader", false);
        this.$store.dispatch("User/updateUserToken", null, { root: true });
        this.$store.commit("User/isSignedIn");

        this.getAnlyticsURL()
    },

    data() {
        return {
            institutionId: this.$route.params.institutionId,
            anlyticsURL: "",
            // anlyticsURL: "https://charts.mongodb.com/charts-preskilet-mmlrn/embed/dashboards?id=ac73dc12-ce8a-4a9c-8a58-1b2c7acd14ba&theme=light&autoRefresh=true&maxDataAge=3600&showTitleAndDesc=true&scalingWidth=scale&scalingHeight=fixed",
            isAuthorised: true,
            unAuthorisedState: utilities.emptyState.unAuthorisedState,
        };

    },

    methods: {
        hideHeader() {
            var frame = document.getElementsByTagName("iframe");
            console.log("frame: ", frame);
            console.log("frame.contentDocument: ", frame.contentDocument);

            header = frame.contentDocument.getElementsByTagName("header");
            console.log("frame.contentDocument: ", frame.contentDocument);

            header.remove();
            footer = frame.contentDocument.getElementsByTagName("footer");
            footer.remove();
        },

        getAnlyticsURL() {
            let endPoint = apis.urls.getAnlyticsURL;
            this.$store.commit("User/toggleProgressLoader", true);

            axios(endPoint, {
                method: "get",
                params: { institutionId: this.institutionId },
                withCredentials: true,
                headers: { authorization: this.userData.token }
            })
                .then((res) => {
                    console.log("res.data.result.anlyticsURL : ", res.data.result.isAuthorised);
                    console.log("res.data.result.anlyticsURL : ", res.data.result.isAuthorised);
                    this.anlyticsURL = res.data.result.anlyticsURL;
                    this.isAuthorised = res.data.result.isAuthorised;

                    this.$store.commit("User/toggleProgressLoader", false);
                })
                .catch((err) => {
                    this.$store.commit("User/toggleProgressLoader", false);
                    console.log("Error in getting anlyticsURL url", err);
                    let errorMsg = utilities.errorHandler(err);
                    this.$store.commit("User/showErrorAlert", errorMsg);

                });
        }

    }

};
</script>