<template>
<div>
    
    <v-btn
      class="hidden-md-and-up"
      dark
      fab
      color="primary"
      style="position:fixed;bottom:3%;right:5%;z-index:1000;"
      v-on:click="addGroupForm = !addGroupForm"
    >
      <v-icon>mdi-plus</v-icon>
    </v-btn>

    <v-dialog v-model="dialogDelete" max-width="290">
        <v-card>
            <v-card-title class="headline">Are you sure ?</v-card-title>
            
            <v-card-actions>
                <v-spacer></v-spacer>
                
                <v-btn color="green darken-1" text @click="dialogDelete = false">No</v-btn>
                <v-btn color="green darken-1" text @click="deleteTask">Yes</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>


    <v-dialog v-model="latestSubscriptionExpiredFlag" max-width="290">
        <v-card>
            <v-card-title class="headline" style="color: red">Your recharge expired</v-card-title>
            <v-card-text>
                    <ul>
                        <li>Get access by subscribing to the platform, for that click on below recharge button</li>
                    </ul>
                </v-card-text>
            
            <v-card-actions>

                <v-btn color="green darken-1" text @click="latestSubscriptionExpiredFlag = !latestSubscriptionExpiredFlag">Ok, Cancel</v-btn>
                <v-spacer></v-spacer>

                <router-link style="text-decoration: none;color:black;" to="/payments">
                    <v-btn color="green darken-1" text>Recharge</v-btn>
                </router-link>
                
            </v-card-actions>
        </v-card>
    </v-dialog>

    <v-dialog v-model="groupCreationBlockedFlag" max-width="290">
        <v-card>
            <v-card-title class="headline" style="color: red">Task creation limit expired</v-card-title>
            <v-card-text>
                    <ul>
                        <li>You are signed in using email id : <b> {{ userData.email }} </b> </li>
                        <li style="color: #2196F3">Ensure you use either your college email ID or the one provided by your college management for login.</li>
                        <li style="color: red">If you are signed in using college email id or the one provided by your college management for access and still facing this issue, please contact college management.</li>
                        <li>Or you can get access by subscribing to the platform, for that click on below recharge button</li>
                    </ul>
                </v-card-text>
            
            <v-card-actions>

                <v-btn color="green darken-1" text @click="groupCreationBlockedFlag = !groupCreationBlockedFlag">Ok, Cancel</v-btn>
                <v-spacer></v-spacer>

                <router-link style="text-decoration: none;color:black;" to="/payments">
                    <v-btn color="green darken-1" text>Recharge</v-btn>
                </router-link>
                
            </v-card-actions>
        </v-card>
    </v-dialog>


    

    <v-container>
        
        <v-layout row wrap justify-space-around>
            
            <v-flex xs11 md6>

                <ul v-if="groupsList.length === 0">
                    <h3>Remember</h3>
                    <br>
                    <li>You need to <b>Create Group First</b></li>
                    <li>Then Students will follow that group (for which you will get a Follow Request),</li>
                    <li>Then you can create Tasks for that group,</li>
                    <li>For which students (or that Group members) will upload presentation videos</li>
                    <!-- <li>And you then you can download an excel sheet of Grades done by you</li> -->
                </ul>

                <br>
                <!-- Whole task add page -->
                <div v-if="groupsList.length != 0">
                    
                <div class="text-right">
                    <v-btn class="ma-2" color="primary" depressed v-on:click="openAddGroupForm">
                        <v-icon dark left>mdi-plus</v-icon>Create Task for Group
                    </v-btn><br />
                </div>
            
                
                <div v-if="addGroupForm">
                    <v-card color="white" outlined >
                        <br>

                        <p style="color: red;">Note: Tell your students to follow this group before you create task, As email notification will be sent to only those students (/Group members) whose 'follow request for Group' has been accepted by you.</p>
                        <p style="color: green;">Note: You can share your Preskilet Profile from where student can follow this group. Click on 'My Preskilet Profile' button below to visit your Preskilet Profile</p>
                         <v-btn
                class="ma-2"
                tilex
                outlined
                router
                color="primary"
                :to="'/' + userData.email"
            >
                My Preskilet Profile
            </v-btn>
                        
                        <v-form ref="form" v-model="valid" lazy-validation class="form-container">
                            
                            <v-select
                                item-text="name"
                                item-value="_id"

                                v-model="groupsListSelectedName"
                                :items="groupsList"
                                label="Select Group"
                                data-vv-name="select"
                                :rules="[(v) => !!v || 'Group is required']"
                                required
                            ></v-select>

                            <v-text-field
                                v-model="taskName"
                                clearable
                                counter="100"
                                :rules="taskNameRules"
                                label="Task Name"
                                required
                                :disabled="disabled"
                            ></v-text-field>
                            
                            <v-text-field
                                v-model="taskDescription"
                                clearable
                                counter="500"
                                :rules="taskDescriptionRules"
                                label="Task Description"
                                required
                                :disabled="disabled"
                            ></v-text-field>

                            <v-dialog
        ref="dialog"
        v-model="modal"
        :return-value.sync="deadlineDate"
        persistent
        width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="deadlineDate"
            :rules="deadlineDateRules"
            label="Select deadline date"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="deadlineDate"
          :min="new Date().toISOString().split('T')[0]"
          scrollable
        >
          <v-spacer></v-spacer>
          <v-btn
            text
            color="primary"
            @click="modal = false"
          >
            Cancel
          </v-btn>
          <v-btn
            text
            color="primary"
            @click="$refs.dialog.save(deadlineDate)"
          >
            OK
          </v-btn>
        </v-date-picker>
      </v-dialog>

                <v-select
                    item-text="name"
                    item-value="_id"

                    v-model="selectedCategory"
                    :items="categoriesList"
                    label="Select Category"
                  
                    :rules="[(v) => !!v || 'Category is required']"
                    required
                ></v-select>

                            
                            <v-select
                                item-text="name"
                                item-value="_id"

                                v-model="rubricSheetListSelectedId"
                                :items="rubricSheetList"
                                label="Select Rubrics"
                                data-vv-name="select"
                                :rules="[(v) => !!v || 'Rubrics is required']"
                                required
                            ></v-select>

                            <v-btn
                outlined
                router
                color="red"
                to="/customRubrics"
            >
                Click to Create your own rubric sheet
            </v-btn>

            <br />
            <br />


            <div style="border: 1px solid grey;margin: 2%;border-radius: 5px;">
                            <table>
                                        <tr>
                                            <th style="width: 70%;">Rubric Name</th>
                                            <th>Editable in final excel sheet (Currently out of 5)</th>
                                            <!-- <th></th> -->
                                        </tr>
                                            <tr v-for="param in rubricParametersList[rubricSheetListSelectedId]">
                                                <td><div style="color: #1976d2 !important;margin: 2.5%;">{{param}}</div></td>
                                                <td><v-rating color="primary" background-color="primary darken-1" readonly></v-rating></td>
                                                <!-- <td>
                                                    <v-btn icon> <v-icon color="primary">mdi-delete</v-icon>
                                                    </v-btn>
                                                </td> -->
                                            </tr>                                       

                                    </table>

</div>
                            <br />


    
                            
                            <div class="text-right">
                                <v-btn class="ma-2" color="primary" depressed v-on:click="validate" :disabled="disabled" required>
                                    <v-icon dark left>mdi-checkbox-marked-circle</v-icon>Add
                                </v-btn>
                                <v-btn
                                    class="ma-2"
                                    color="primary"
                                    depressed
                                    v-on:click="addGroupForm = !addGroupForm"
                                >
                                    <v-icon dark left>mdi-close-circle</v-icon>cancel
                                </v-btn>
                            </div>

                        </v-form>
                    </v-card> <br />
                </div>     <v-list v-if="groupsList.length != 0" subheader two-line>
                    <v-card color="white" outlined class="ma-2 pa-2">
                        
                        <v-row>
                            <v-col cols="12" md="10">
                                <v-select
                                    v-model="groupsListSelectedNameTask"
                                    :items="groupsList"
                                    label="Select Group to see list of Tasks"
                                    data-vv-name="select"
                                    :rules="[(v) => !!v || 'Subject type is required']"
                                    required
                                    item-text="name"
                                    item-value="_id"
                                    @change="getTasks"
                                ></v-select>
                            </v-col>
                            
                            <!-- <v-col cols="12" md="2">
                                <v-btn class="ma-2" color="primary" depressed v-on:click="getTasks">
                                    <v-icon dark left>mdi-send</v-icon>Show Tasks
                                </v-btn> <br />
                            </v-col> -->
                        </v-row>
                    
                    </v-card>
                    
                    <v-subheader>Tasks</v-subheader>
                </v-list>


                <v-card
                    flat
                    v-if="taskList.length === 0 && groupsListSelectedNameTask != ''"
                    class="mx-auto"
                    max-width="400"
                >
                            <v-card-subtitle> <b> No tasks for this Group has been created </b> (Click on create task button to create task/assigment for which students or group members can upload videos)</v-card-subtitle>


                </v-card>  


                <v-card
                    flat
                    v-if="groupsListSelectedNameTask == ''"
                    class="mx-auto"
                    max-width="400"
                >

                    <v-img class="white--text align-end" :src="emptyStateImage"></v-img>

                    <div class="d-flex flex-no-wrap justify-space-between">

                        <div>

                            <v-card-title
                            class="headline"
                            v-text="'Task'"
                            ></v-card-title>

                            <v-card-subtitle v-text="emptyStateMessage"></v-card-subtitle>

                        </div>

                    </div>

                </v-card>        
                <v-list>

                    <v-list-item v-for="item in taskList" :key="item._id" style="border: 1px solid grey;margin: 2%;border-radius: 5px;">
                        <v-list-item-content>
                            <v-list-item-title v-text="item.name"></v-list-item-title>
                            <v-list-item-subtitle class="text-wrap">
                                {{item.description}}

                                 <br><br> <br>
                                 
                                 <p>
                                    Rubric Sheet for this task : 
                                    <span v-if="item.rubricSheetId"  style="color: green;"> {{ item.rubricSheetId.name }} </span>
                                    <span v-if="!item.rubricSheetId" style="color: green;"> Default Presentation Rubric Sheet </span>
                                    <router-link router to="/customRubrics"><v-icon color="primary" size="14">mdi-launch</v-icon></router-link>
                                </p>

                                <p>
                                    Deadline for this task : 
                                    <span v-if="item.deadline"  style="color: red;">{{ new Date(item.deadline).toUTCString().split("GMT")[0] }}</span>
                                    <span v-if="!item.deadline" style="color: red;">No deadline</span>
                                </p>
                                 
                             <a style="text-decoration: none" :href="'/task/prets/'+ item._id" target="_blank">
                                    Watch Videos uploaded by Students for this Task
                                    <v-icon color="primary" size="14">mdi-launch</v-icon>
                                </a>

                                    <br><br>

                                    <a target="_blank"
                                        @click="prepareAndCopyToClipBoard(item)">
                                        
                                        Click here to copy below submission link for students

                                        <v-icon color="primary" size="14">mdi-content-copy</v-icon>

                                    </a>
                                    <br><br>
                                    <p style="word-wrap: break-word;">{{currentHost}}/submissionVideoForTask?groupId={{encodeURIComponent(item.groupId)}}&groupName={{encodeURIComponent(selectedGroupDetails.name)}}&autoJoin={{encodeURIComponent(selectedGroupDetails.isDirectJoiningAllowed ? true : false)}}&taskId={{encodeURIComponent(item._id)}}&categoryId={{encodeURIComponent(item.categoryId)}}&institutionId={{encodeURIComponent(userData.profile.institutionId)}}</p>
                            </v-list-item-subtitle>




                        </v-list-item-content>
                        
                        <v-list-item-action>
                            
                           
                            
                            <v-btn icon @click="deleteTaskById(item._id)">
                                <v-icon color="primary">mdi-delete</v-icon>
                            </v-btn>
                        </v-list-item-action>
                    </v-list-item>
                </v-list>
                <!-- End of add tasks whole page -->
                </div>

                
           
            
            </v-flex>
            
            <v-flex xs11 md4>

                <v-card 
                    :flat="cardFlatFlag"
                    :outlined="cardOutlineFlag"
                    class="mx-auto sticky"
                    v-if="userData.signedIn && userData.profile.account === 'Student'"
                >
            
                    <v-list-item two-line class="RightSideAd">
                        
                        <v-list-item-avatar><img src="https://banner2.cleanpng.com/20180324/iww/kisspng-google-logo-g-suite-google-5ab6f1cee66464.5739288415219388949437.jpg" /></v-list-item-avatar>
                        
                        <v-list-item-content>
                            
                            <v-list-item-title>Google Ads</v-list-item-title>
                            
                            <v-list-item-subtitle>Sponsored</v-list-item-subtitle>
                        
                        </v-list-item-content>
                                    
                        <v-btn color="primary" size="35" tile large icon router >
                            <v-icon left> mdi-compass-outline</v-icon>
                        </v-btn>


                    </v-list-item>
                    
                        <div class="ad-container">
                            <Adsense
                                style="display:block"
                                data-ad-client="ca-pub-2789565594210548"
                                data-ad-slot="7092127933"
                                data-ad-format="auto"
                                data-full-width-responsive="true"
                            ></Adsense>
                        </div>
        
        
                </v-card>


            </v-flex>

        </v-layout>
    </v-container>
</div>
</template>

<style scoped>

.sticky{
position: relative;
}



.RightSideAd{
    padding-left: 0px;
    padding-right: 0px;
}

/* for desktop */
@media screen and (min-width: 650px) {
.RightSideAd{
    padding-left: 4%;
    padding-right: 4%;
}

.sticky{
    position: -webkit-sticky;
    position: sticky;
    top: 4rem;
    z-index: 2;
    width: auto !important;
}

.sticky2{
    position: -webkit-sticky;
    position: sticky;
    top: 27rem;
    z-index: 2;
    width: auto !important;
    margin-top: 280%;
}
}

.form-container {
  padding   : 20px;
}


</style>

<script>

import { mapGetters } from "vuex";
import utilities from "@/helpers/utilities";
import apis from "../helpers/apiConfig";
const axios = require("axios").default;

import firebase from "firebase/app";
import "firebase/firebase-analytics";
var firebaseAnlytics;


// Google Adsense    
import Vue from "vue";
import Ads from 'vue-google-adsense'
Vue.use(require('vue-script2'))
Vue.use(Ads.Adsense)
Vue.use(Ads.InFeedAdsense)

export default {
    
    name: "Followers",
    
    computed: { ...mapGetters({ userData: "User/userData" }) },
    
    mounted() {

        console.log("Userdata : ",this.userData)
        if (window.screen.availWidth <= 650) { this.cardFlatFlag = true; } 
        else { this.cardOutlineFlag = true; }

        // FIREBASE ANLYTICS
        firebaseAnlytics = firebase.analytics();
        firebaseAnlytics.logEvent("Tasks Page");

        this.$store.commit("User/toggleProgressLoader", false);
        this.$store.dispatch("User/updateUserToken", null, { root: true });
        this.$store.commit("User/isSignedIn");

        this.setYears();
        this.getGroups();
        this.getRubricSheetList();
        this.getCategories()
    },
    
    data() {
        
        return {

            currentHost: apis.urls.currentHost,
            cardFlatFlag    : true,
            cardOutlineFlag : false,
            taskList: [],
            valid: true,
            disabled : false,
            groupsListSelectedName : "",
            selectedCategory : "",
            groupsListSelectedNameTask : "",
            selectedGroupDetails: {},
            rubricSheetListSelectedId: "",
            dialogDelete: false,
            deleteTaskId : "",

            addGroupForm: false,
            taskName : "",
            taskNameRules:  [ 
                (v) => !!v || "Task Name is required",
                (v) => (v && v.length <= 100) || "Task Name must be less than 100 characters"
            ],
            
            taskDescription : "",
            taskDescriptionRules:  [ 
                (v) => !!v || "Task Description is required",
                (v) => (v && v.length <= 500) || "Task Description must be less than 500 characters"
            ],

            modal: false,
            deadlineDate: null,
            deadlineDateRules:  [ 
                (v) => !!v || "Deadline is required",
            ],

            subjectName : "",
            subjectTerms: [],
            subjectNameRules : [ 
                (v) => !!v || "Subject Name is required",
                (v) => (v && v.length <= 50) || "Subject Name must be less than 50 characters"
            ],

            subjectType: "",
            subjectTypes: ["Theroy", "Lab"],

            academicYear: "",
            academicYears: ['First Year', 'Second Year', 'Third Year', 'Fourth Year'],

            departmentName: "",
            departmentTerms: [],
            departmentNameRules:[ 
                (v) => !!v || "Department Name is required",
                (v) => (v && v.length <= 50) || "Department Name must be less than 50 characters"
            ],

            divName: "",
            divTerms: [],
            divNameRules:[ 
                (v) => !!v || "Division Name is required",
                (v) => (v && v.length <= 50) || "Division Name must be less than 50 characters"
            ],
            
            batchName: "",
            batchTerms: [],
            batchNameRules:[ 
                (v) => !!v || "Batch Name is required",
                (v) => (v && v.length <= 50) || "Batch Name must be less than 50 characters"
            ],

            year: "",
            years: [],

            // Test data
            groupsList: [],
            rubricSheetList: [],
            rubricParametersList: {},
            
            // categoriesList
            categoriesList : [],
            
            emptyStateImage: utilities.emptyState.task.image,
            emptyStateMessage: utilities.emptyState.task.message,
            groupCreationBlocked: false,
            groupCreationBlockedFlag: false,
            latestSubscriptionExpired: false,
            latestSubscriptionExpiredFlag: false,

        };
        
    },

    methods: {
        openAddGroupForm () {
            if(this.groupCreationBlocked) {
                this.groupCreationBlockedFlag = true;
            } else if(this.latestSubscriptionExpired) {
                this.latestSubscriptionExpiredFlag = true;
            } else {
                this.addGroupForm = !this.addGroupForm
            }
        },

        copyToClipBoard(item, preparedLink) {
            try {
                navigator.clipboard.writeText(preparedLink);
                this.$store.commit("User/showSuccessAlert", `Submission Link copied for task: ${item.name}, please share it with students`);
            } catch (error) {
                this.$store.commit("User/showErrorAlert", `Please copy manually, Error while coping the link : ${error}`);
            }
        },
        
        prepareAndCopyToClipBoard(item) {
            const preparedLink = `${this.currentHost}/submissionVideoForTask?groupId=${encodeURIComponent(item.groupId)}&groupName=${encodeURIComponent(this.selectedGroupDetails.name)}&autoJoin=${encodeURIComponent(this.selectedGroupDetails.isDirectJoiningAllowed ? true : false)}&taskId=${encodeURIComponent(item._id)}&categoryId=${encodeURIComponent(item.categoryId)}&institutionId=${encodeURIComponent(this.userData.profile.institutionId)}`
            this.copyToClipBoard(item, preparedLink);
        },

        setYears(){ var i = -1; while(i < 20){ this.years.push(new Date().getFullYear() + i); i++;}},
        
        validate() {
            this.$refs.form.validate();
            if (this.$refs.form.validate()) {
                this.createTask();
            }
        },
        
        reset() { this.$refs.form.reset() },

        deleteTaskById(groupId){
            this.deleteTaskId = groupId;
            this.dialogDelete = true;
        },

        getGroups(){
            
            let endPoint = apis.urls.getGroups;
            this.$store.commit("User/toggleProgressLoader", true);
            
            axios(endPoint, {
                method          : "get",
                withCredentials : true,
                headers         : {
                    authorization: this.userData.token,
                    ...(this.userData.proxyUserEmail ? { 'x-proxy-user-email': this.userData.proxyUserEmail } : {})
                }, 
            })
            .then((res) => {
                console.log("Groups data : ",res);
                
                let data = res.data.result.data.groups;

                const subsciptionCount = res.data.result.data.count;
                const latestSubscription = res.data.result.data.latestSubscription;
                if(subsciptionCount <= 1) {
                    this.groupCreationBlocked = true;
                }

                let today = new Date();
				let expiryDate = new Date(latestSubscription?.expiryDate)

				if(expiryDate < today) {
                    this.latestSubscriptionExpired = true;
				}

                console.log("subsciptionCount : ", subsciptionCount);
                console.log("latestSubscription : ", latestSubscription);

                            
                this.groupsList = data;
                this.$store.commit("User/toggleProgressLoader", false);



                        
                // FIREBASE ANLYTICS
                firebaseAnlytics.logEvent("Groups Data Retrived on Tasks Page");
            
            })
            .catch((err) => {
                
                this.$store.commit("User/toggleProgressLoader", false);
                console.log("Error in Following",err);
                let errorMsg = utilities.errorHandler(err);
                this.$store.commit("User/showErrorAlert", errorMsg);
            
            });

        },

        deleteTask(){

            console.log("delete task id :",this.deleteTaskId );
            
            this.dialogDelete = false;
            
            this.$store.commit("User/toggleProgressLoader", true);
            
            let endPoint = apis.urls.deleteTask ;
            
            axios(endPoint, {
                method  : "delete",
                params: { taskId : this.deleteTaskId},
                withCredentials: true,
                headers         : {
                    authorization: this.userData.token,
                    ...(this.userData.proxyUserEmail ? { 'x-proxy-user-email': this.userData.proxyUserEmail } : {})
                }, 
            })
            .then((res) => {
                
                console.log("success : ", res.data.result.message);
                this.$store.commit("User/toggleProgressLoader", false);
                this.$store.commit("User/showSuccessAlert", res.data.result.message);
                this.getTasks();

                // FIREBASE ANLYTICS
                firebaseAnlytics.logEvent("Task Deleted on Tasks Page");
            })
            .catch((err) => {
                this.$store.commit("User/toggleProgressLoader", false);
                console.log(err);
                let errorMsg = utilities.errorHandler(err);
                this.$store.commit("User/showErrorAlert", errorMsg);
            });
        },

        getTasks(){
            console.log("this.userData.proxyUserEmail : ", this.userData.proxyUserEmail);
            console.log("Group Selectd Name : ",this.groupsListSelectedNameTask); 
            const selectedGroupDetailsLocal = this.groupsList.find((item => {
                return item._id === this.groupsListSelectedNameTask;
            }))
            this.selectedGroupDetails = selectedGroupDetailsLocal;
            console.log("selectedGroupDetails : ", this.selectedGroupDetails);
            
            let endPoint = apis.urls.getTasksList;
            this.$store.commit("User/toggleProgressLoader", true);
            
            axios(endPoint, {
                method          : "get",
                params          : { groupId : this.groupsListSelectedNameTask },
                withCredentials : true,
                headers         : {
                    authorization: this.userData.token,
                    ...(this.userData.proxyUserEmail ? { 'x-proxy-user-email': this.userData.proxyUserEmail } : {})
                }, 
            })
            .then((res) => {
                console.log("Tasks data : ",res);
                
                let data = res.data.result.data;
                this.taskList = data;
                this.$store.commit("User/toggleProgressLoader", false);

                
                // FIREBASE ANLYTICS
                firebaseAnlytics.logEvent("Task Retrived on Tasks Page");
            
            })
            .catch((err) => {
                
                this.$store.commit("User/toggleProgressLoader", false);
                console.log("Error in Following",err);
                let errorMsg = utilities.errorHandler(err);
                this.$store.commit("User/showErrorAlert", errorMsg);
            
            });

        },

        getRubricSheetList(){
                
                let endPoint = apis.urls.getRubricSheetList;
                this.$store.commit("User/toggleProgressLoader", true);
                
                axios(endPoint, {
                    method          : "get",
                    withCredentials : true,
                    headers         : {
                    authorization: this.userData.token,
                    ...(this.userData.proxyUserEmail ? { 'x-proxy-user-email': this.userData.proxyUserEmail } : {})
                }, 
                })
                .then((res) => {
                    console.log("Custom Rubrics data : ",res);
                    
                    let data = res.data.result.data;
                    console.log("rubricSheetList that we got from backend : ", data)
                    this.rubricSheetList = [
                        ...data, 
                        {
                            name: "Default Presentation Rubric Sheet",
                            rubricParameters: [
                                {
                                    sheet_id: "303030303030303030303030",
                                    name: "Language Proficiency",
                                },
                                {
                                    sheet_id: "303030303030303030303030",
                                    name: "Voice Modulation",
                                }, 
                                {
                                    sheet_id: "303030303030303030303030",
                                    name: "Eye Contact, Hand Gestures and Body Movement",
                                }, 
                                {
                                    sheet_id: "303030303030303030303030",
                                    name: "Confidence",
                                }, 
                                {
                                    sheet_id: "303030303030303030303030",
                                    name: "Content Delivery and Knowledge",
                                }, 
                            ],
                            _id: "303030303030303030303030"
                        }
                    ];

                    console.log("all rubrics data : ", this.rubricSheetList)

                    this.rubricParametersList = {};

                    this.rubricSheetList.forEach(rubric => {
                        const arr =  rubric.rubricParameters.map((element) => {
                            return element.name;
                        })
                        this.rubricParametersList[rubric._id] = arr;                        
                    });

                    console.log("rubricParametersList : ", this.rubricParametersList);
                    


                    this.$store.commit("User/toggleProgressLoader", false);
    
                    
                    // FIREBASE ANLYTICS
                    firebaseAnlytics.logEvent("Group Data Displayed");
                
                })
                .catch((err) => {
                    
                    this.$store.commit("User/toggleProgressLoader", false);
                    console.log("Error in Following",err);
                    let errorMsg = utilities.errorHandler(err);
                    this.$store.commit("User/showErrorAlert", errorMsg);
                
                });
    
        },


        getCategories(){
            
            let endPoint = apis.urls.getCategories;
            this.$store.commit("User/toggleProgressLoader", true);
            
            axios(endPoint, {
                method          : "get",
                withCredentials : true,
                headers         : {
                    authorization: this.userData.token,
                    ...(this.userData.proxyUserEmail ? { 'x-proxy-user-email': this.userData.proxyUserEmail } : {})
                }, 
            })
            .then((res) => {
                console.log("Categories data : ",res);
                let data = res.data.result.data;
                console.log("Categories that we got from backend : ", data)
                this.categoriesList = data;
                console.log("all rubrics data : ", this.categoriesList)
                this.$store.commit("User/toggleProgressLoader", false);
                // FIREBASE ANLYTICS
                firebaseAnlytics.logEvent("Categories Data Displayed");
            })
            .catch((err) => {
                
                this.$store.commit("User/toggleProgressLoader", false);
                console.log("Error in Getting Categories",err);
                let errorMsg = utilities.errorHandler(err);
                this.$store.commit("User/showErrorAlert", errorMsg);
            
            });

        },


        createTask(){
            
            this.$store.commit("User/toggleProgressLoader", true);
            console.log("this.deadlineDate ", this.deadlineDate);
            console.log("this.selectedCategory ", this.selectedCategory);

            
            let data = {
                name        :   this.taskName,
                groupId   :   this.groupsListSelectedName,
                rubricSheetId :  this.rubricSheetListSelectedId,
                description :   this.taskDescription,
                deadline: new Date(this.deadlineDate + "T23:59:59Z").toUTCString(),
                categoryId : this.selectedCategory
            };
            
            console.log("Tasks to be created  ", data);
            console.log("Tasks added date ", new Date(data.deadline));

            
            let endPoint = apis.urls.createTask;
            
            axios(endPoint, {
                method          : "post",
                data,
                withCredentials : true,
                headers         : {
                    authorization: this.userData.token,
                    ...(this.userData.proxyUserEmail ? { 'x-proxy-user-email': this.userData.proxyUserEmail } : {})
                }, 
            })
            .then((res) => {
                
                console.log("success : ", res.data.result.message);
                this.$store.commit("User/toggleProgressLoader", false);
                this.$store.commit("User/showSuccessAlert", res.data.result.message);
                
                // FIREBASE ANLYTICS
                firebaseAnlytics.logEvent("Task Created on Tasks Page");
                
            })
            .catch((err) => {
                
                this.$store.commit("User/toggleProgressLoader", false);
                (this.disabled = false), console.log(err);
                let errorMsg = utilities.errorHandler(err);
                console.log("Error in Pret",err);
                this.$store.commit("User/showErrorAlert", errorMsg);
            
            });

        }
    }

};
</script>
