<template>
<div class="home" v-if="userData.signedIn">
    
    <!-- Explore videos  -->
    <v-flex v-if="suggestData.length != 0" xs12 md12>
        
        <v-card-title
        class="headline"
        v-text="'Preskilet Suggestions'"
        ></v-card-title>

        <v-card
            v-for="item in suggestData"
            :key="item._id"
            outlined
            class="mx-auto my-4 pa-0"
        >
            
            <v-list-item>

                <v-list-item-avatar size="56" color="grey darken-3">

                    <v-img
                    class="elevation-6"
                    alt=""
                    :src="item.profile.profilePhoto"
                    ></v-img>

                </v-list-item-avatar>
                
                <router-link style="text-decoration: none;color:black;" :to="'/' + item.email">
                    {{ item.profile.name }}
                    
                    <v-list-item-subtitle>{{ item.followers.length }} Followers</v-list-item-subtitle>

                     </router-link>

                <v-spacer></v-spacer>

                <v-btn
                v-if="item.msg === 'follow'"
                style="cursor: pointer;"
                class="ma-3"
                    color="primary"
                    icon
                    @click="followPreskilet(item._id)">

                    {{item.msg}}
                
                </v-btn>
                
                <v-btn class="ma-3" v-if="item.msg === 'following'" icon style="color: grey;" >{{item.msg}}</v-btn>


            </v-list-item>

        </v-card>

    </v-flex>

</div>
</template>

<script>

import { mapGetters } from "vuex";
import utilities from "@/helpers/utilities";
import apis from "../helpers/apiConfig";
const axios = require("axios").default;

import firebase from "firebase/app";
import "firebase/firebase-analytics";
var firebaseAnlytics;

export default {
  
    name        : "Suggestions",

    data() {
        return {
            
            userId          : "",
            vectorImages    : utilities.vectorImages,
            suggestData     : [],
            shareableLink   : "https://preskilet.com/",
            dialog          : false
            
        };
    },

    computed    : { ...mapGetters({ userData: "User/userData" }) },

    created() {

        
        // FIREBASE ANLYTICS
        firebaseAnlytics = firebase.analytics();
        firebaseAnlytics.logEvent("Suggestions Component");
        
        this.$store.dispatch("User/updateUserToken", null, { root: true });
        this.$store.commit("User/isSignedIn");
    
    },

    mounted() {
    
        this.$store.dispatch("User/updateUserToken", null, { root: true });
        this.$store.commit("User/isSignedIn");
        this.$store.commit("User/toggleProgressLoader", false);
        this.shareableLink = this.shareableLink + this.userData.email;

        let endPoint = apis.urls.getSuggestions;
        this.$store.commit("User/toggleProgressLoader", true);

        axios(endPoint, {
            method          : "get",
            withCredentials : true,
            headers         : { authorization: this.userData.token }
        })
        .then((res) => {
            
            this.$store.commit("User/toggleProgressLoader", false);

            
            // FIREBASE ANLYTICS
            firebaseAnlytics.logEvent("Suggestions Displayed (Component)");

            if (res.data.result) {

                this.userId                 = res.data.result._id;
                var userAlreadyFollowing    = res.data.result.userFollowing;
                var tempSuggestData         = res.data.result.suggest;

                // To put all the data in one single array
                let finalCycle              = [];

                for (let i = 0; i < tempSuggestData.length; i++) {
                    
                    for (let j = 0; j < tempSuggestData[i].following.length; j++) {
                    
                        // To not show the user the himself as a suggestions
                        if (tempSuggestData[i].following[j]._id != this.userId) {

                            console.log("pushing the doc: ",tempSuggestData[i].following[j]); 
                            finalCycle.push(tempSuggestData[i].following[j]);
                        
                        }
                    
                    }

                }
                

                for (let jm = 0; jm < finalCycle.length; jm++) {
                    console.log("docs ", jm, " ", finalCycle[jm]);
                }

                // Removing all the duplicates from the array
                for (let i = 0; i < finalCycle.length; i++) {

                    for (let j = 0; j < finalCycle.length; j++) {
                        
                        if (i != j) {
                            
                            if (finalCycle[i]._id == finalCycle[j]._id) {
                                finalCycle.splice(j, 1);
                            }

                        }

                    }

                }

                for (let jm = 0; jm < finalCycle.length; jm++) {
                    // console.log("docs ", jm, " ", finalCycle[jm]);
                }

                var uniqueChars = [];

                function doesFollow(myid) {
                    
                    for (let i = 0; i < userAlreadyFollowing.length; i++) {
                        
                        if (userAlreadyFollowing[i]._id == myid) {
                            return true;
                        }

                    }
                    return false;

                }

                var uniqueChars = [];
                for (let j = 0; j < finalCycle.length; j++) {
                    
                    if (!doesFollow(finalCycle[j]._id)) {
                        uniqueChars.push(finalCycle[j]);
                    }

                }

                this.suggestData = uniqueChars;
                
                var suggestDataWithMsg = uniqueChars.map((x)=>{
                    return {
                        email : x.email,
                        followers : x.followers,
                        profile: x.profile,
                        _id : x._id,
                        msg: "follow"
                    }
                });

 console.log("suggestions : ", suggestDataWithMsg);
                
                suggestDataWithMsg = suggestDataWithMsg.sort(() => Math.random() - 0.5)
                
                this.suggestData = suggestDataWithMsg;

                console.log("shuffled : ", this.suggestData);
            
            }

        })
        .catch((err) => {
            
            console.log("Error in suggestions",err);
            let errorMsg = utilities.errorHandler(err);
            console.log(errorMsg);
            this.$store.commit("User/toggleProgressLoader", false);
        
        });

    },

    methods: {
          followPreskilet(uid) {
              console.log("my uid : ", uid);
                
                this.$store.commit("User/toggleProgressLoader", true);

                let data        = { userId: uid };
                let endPoint    = apis.urls.follow;
                
                axios(endPoint, {
                    method          : "post",
                    data,
                    withCredentials : true,
                    headers         : { authorization: this.userData.token }
                })
                .then((res) => {

                    this.$store.commit("User/toggleProgressLoader", false);
                    this.$store.commit("User/showSuccessAlert", res.data.result);


                    this.suggestData.forEach(x => {
                        if(JSON.stringify(x._id) === JSON.stringify(uid)){
                            x.msg = "following";
                        }
                        
                    });

                    // FIREBASE ANLYTICS
                    firebaseAnlytics.logEvent("New Follow Operation from Suggestions Component");                
                })
                .catch((err) => {
                    
                    this.$store.commit("User/toggleProgressLoader", false);
                    console.log("Error in watch",err);
                    let errorMsg = utilities.errorHandler(err);
                    this.$store.commit("User/showErrorAlert", errorMsg);
                
                });

        },
    }

};

</script>
