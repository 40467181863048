<template>
<div>
    
    <nav v-if="!userData.signedIn">
        
        <v-toolbar flat style="border-bottom: 1px solid grey;margin-bottom: 3%;">

            <v-toolbar-title>
                
                <a class="Brand-logo" href="/">
                <span class="blue--text">Preskilet</span>
                </a>

            </v-toolbar-title>

            <v-spacer></v-spacer>

            <v-btn
                v-if="this.$route.name != 'Signin'"
                class="ma-2 hidden-sm-and-down blue--text"
                tile
                outlined
                router
                to="/signin"
            >
                <v-icon left>mdi-face</v-icon>Sigin-in
            </v-btn>
            
            <v-btn
                v-if="this.$route.name != 'Signin'"
                class="ma-2 hidden-md-and-up blue--text"
                tile
                large
                icon
                router
                to="/signin"
            >
                <v-icon left>mdi-face</v-icon>
            </v-btn>

        </v-toolbar>

    </nav>

</div>
</template>

<style scoped>

.Brand-logo {
  text-decoration   : none !important;
  color             : black !important;
}

</style>

<script>

import { mapGetters } from "vuex";

export default {
    
    name        : "Navbar",
    computed    : { ...mapGetters({ userData: "User/userData" }) }

};

</script>
