module.exports = {
    
    validateGroup: (inputs) => {
        
        if (inputs.name === "") {
            
            return {
                error   : true,
                message : "Name field cannot be empty"
            };
        
        }

        if (inputs.name.length > 100) {

            return {
                error   : true,
                message : "Name cannot be greater than 100"
            };
            
        }
        
        if (inputs.subject.length > 100){ return{ error : true, message : "Subject cannot be greater than 100"} }
        
        let subjectTypes = ["Theroy", "Lab"];
        
        if (!subjectTypes.includes(inputs.type)) { return { error: true, message: "Invalid subject type" };}
        
        if (inputs.year < new Date().getFullYear() - 1)  { return { error: true, message: "Year can not be less than "+ new Date().getFullYear() - 1 };}
        if (inputs.year > new Date().getFullYear() + 20) { return { error: true, message: "Year can not be more than "+ new Date().getFullYear() + 20 };}

        let academicYears = ['First Year', 'Second Year', 'Third Year', 'Fourth Year'];
        
        if(inputs.academicYear)
        {
            if (!academicYears.includes(inputs.academicYear)) { return { error: true, message: "Invalid Academic Year" };}
        }
        

        if(inputs.department)
        {
            if (inputs.department.length > 100){ return{ error : true, message : "Department Name cannot be greater than 100"} }
        }

        if(inputs.div)
        {
            if (inputs.div.length > 100){ return{ error : true, message : "Division Name cannot be greater than 100"} }
        }

        if(inputs.batch)
        {
            if (inputs.batch.length > 100){ return{ error : true, message : "Batch Name cannot be greater than 100"} }
        }
        
        if (inputs.description.length > 100){ return{ error : true, message : "Description cannot be greater than 100"} }

        return {
            error   : false,
            message : null
        };
    
    }
    
};
