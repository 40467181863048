<template>
<div class="home" v-if="userData.signedIn">
    
    <v-flex xs12 md12>
        
        <v-card flat class="hidden-sm-and-down">
            
            <div class="d-flex flex-no-wrap justify-space-between">
            
                <div>
                    <v-card-title class="headline" v-text="'Feed'"></v-card-title>
                    <v-card-subtitle v-text="'Welcome to Preskilet Feed. Here you can see the Feed of Prets of the people that you follow'"></v-card-subtitle>
                </div>
                
                <v-avatar size="125" tile> <v-img :src="vectorImages.signout"></v-img></v-avatar>
            </div>
        
        </v-card>


        <div v-if="feedData.length != 0">
           
            <div v-for="(item, index) in feedData"
            :key="item._id">

            <v-card            
            :flat="cardFlatFlag"
            :outlined="cardOutlineFlag"
            class="mx-auto my-6"
        >
            
            <v-list-item two-line>
                
                <v-list-item-avatar><img :src="item.userId.profile.profilePhoto" /></v-list-item-avatar>
                
                <v-list-item-content>
                    
                    <router-link style="text-decoration: none;color:black;" :to="'/' + item.userId._id">
                        <v-list-item-title>{{ item.userId.profile.name }}</v-list-item-title>
                    </router-link>
                    
                    <v-list-item-subtitle>{{ item.title }}</v-list-item-subtitle>
                
                </v-list-item-content>
                
                <v-btn color="primary" size="35" tile large icon router :to="'/watch?v=' + item._id">
                    <v-icon left> mdi-compass-outline</v-icon>
                </v-btn>
            
            </v-list-item>
            
            <!-- <vue-plyr :options="options">
                <video :data-poster="item.thumbnailUrl" :src="item.videoUrl" controls playsinline style="width: 100%;height:auto;" ></video>
            </vue-plyr> -->

    <router-link style="text-decoration: none;color:black;" :to="'/watch?v=' + item._id">
                    
                        <v-img 
                            :src="item.thumbnailUrl"
                            class="white--text align-end videoRecommendationThubmnail"
                            gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                        >
                            <v-list-item two-line>
                            <v-list-item-content>
                                <v-list-item-title class="white--text">
                                {{ item.title }}
                                </v-list-item-title>
                                <v-list-item-subtitle class="white--text"
                                >{{ item.viewsLength }} Views</v-list-item-subtitle
                                >
                            </v-list-item-content>
                            </v-list-item>
                        </v-img>
                    
                    </router-link>
            <v-card-actions>
                <v-list-item class="grow">
                    <v-row align="center" class="pa-0 ma-0">
                        
                        <v-icon 
                            @click="unLikePret(index)" 
                            style="cursor:pointer;" 
                            v-if="item.likes.includes(userId)" 
                            color="red" 
                            size="30"
                        >
                            mdi-heart
                        </v-icon>
                        
                        <v-icon
                            @click="likePret(index)"
                            v-if="!item.likes.includes(userId)"
                            style="cursor:pointer;"
                            color="whitesmoke"
                            size="30"
                        >
                            mdi-heart-outline
                        </v-icon>
                        
                        <v-spacer></v-spacer>  
              
                        <v-icon 
                            @click="unsave(item._id)"
                            v-if="savedprets.includes(item._id)" 
                            style="cursor:pointer;" 
                            color="primary"
                            size="30"
                        >
                            mdi-bookmark
                        </v-icon>


                        <v-icon 
                            @click="save(item._id)"
                            v-if="!savedprets.includes(item._id)" 
                            style="cursor:pointer;" 
                            color="primary"
                            size="30"
                        >
                            mdi-bookmark-outline
                        </v-icon>
                    
                    </v-row>

                </v-list-item>

            </v-card-actions>
        
        </v-card>

<!-- ads -->
  <!-- <v-card   v-if="index % 4 == 0 && index != 0"
            :flat="cardFlatFlag"
            :outlined="cardOutlineFlag"
            class="mx-auto my-6"
        >
            
            <v-list-item two-line>
                
                <v-list-item-avatar><img src="https://banner2.cleanpng.com/20180324/iww/kisspng-google-logo-g-suite-google-5ab6f1cee66464.5739288415219388949437.jpg" /></v-list-item-avatar>
                
                <v-list-item-content>
                    
                        <v-list-item-title>Google Ads</v-list-item-title>
                    
                    <v-list-item-subtitle>Sponsored</v-list-item-subtitle>
                
                </v-list-item-content>
                
                <v-btn color="primary" size="35" tile large icon router >
                    <v-icon left> mdi-compass-outline</v-icon>
                </v-btn>
            
            </v-list-item>
            
<div  class="ad-container">
                <InFeedAdsense
                    style="display:block"
                    data-ad-format="fluid"
                    data-ad-layout-key="-6t+ed+2i-1n-4w"
                    data-ad-client="ca-pub-2789565594210548"
                    data-ad-slot="8144505096"
                ></InFeedAdsense>
            </div>
   

            <v-card-actions>
                <v-list-item class="grow">
                    <v-row align="center" class="pa-0 ma-0">
                        
                        <v-icon 
                        @click="likeAd(index)"
                            style="cursor:pointer;" 
                            v-if="likedAds.includes(userId)" 
                            color="red" 
                            size="30"
                        >
                            mdi-heart
                        </v-icon>
                        
                        <v-icon
                            @click="unLikeAd(index)"
                            v-if="!likedAds.includes(userId)"
                            style="cursor:pointer;"
                            color="whitesmoke"
                            size="30"
                        >
                            mdi-heart-outline
                        </v-icon>
                        
                        <v-spacer></v-spacer>  
              
                        <v-icon 
                            v-if="likedAds.includes(userId)" 
                            style="cursor:pointer;" 
                            color="primary"
                            size="30"
                        >
                            mdi-bookmark
                        </v-icon>


                        <v-icon 
                            v-if="!likedAds.includes(userId)" 
                            style="cursor:pointer;" 
                            color="primary"
                            size="30"
                        >
                            mdi-bookmark-outline
                        </v-icon>
                    
                    </v-row>

                </v-list-item>

            </v-card-actions>
        
        </v-card> -->


  
  



        </div>
        <div class="text-center">
            
            <v-progress-circular
                v-if="loadMoreVideosProgress"
                :size="50"
                color="blue"
                dark
                indeterminate>
            </v-progress-circular>
            
            <br><br>
            
            <v-btn
            rounded
            color="primary"
            dark
            v-if="noMorevideos"
            >
                No More Videos
            </v-btn>
        
        <br><br>
        </div>
            </div>

    </v-flex>


</div>
</template>

<style scoped>

.videoRecommendationThubmnail{
width: 100%;
height:350px;
}

/* for desktop */
@media screen and (min-width: 650px) {
.videoRecommendationThubmnail{
width: 100%;
height:450px;
}

}



#create .v-speed-dial {
  position  : absolute;
}

#create .v-btn--floating {
  position  : relative;
}
.tooltip {
  position  : relative;
  display   : inline-block;
}

.tooltip .tooltiptext {
  visibility        : hidden;
  width             : 140px;
  background-color  : #555;
  color             : #fff;
  text-align        : center;
  border-radius     : 6px;
  padding           : 5px;
  position          : absolute;
  z-index           : 1;
  bottom            : 150%;
  left              : 50%;
  margin-left       : -75px;
  opacity           : 0;
  transition        : opacity 0.3s;
}

.tooltip .tooltiptext::after {
  content       : "";
  position      : absolute;
  top           : 100%;
  left          : 50%;
  margin-left   : -5px;
  border-width  : 5px;
  border-style  : solid;
  border-color  : #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility    : visible;
  opacity       : 1;
}

</style>

<script>

import { mapGetters } from "vuex";

import utilities from "@/helpers/utilities";
import apis from "../helpers/apiConfig";
const axios = require("axios").default;

import firebase from "firebase/app";
import "firebase/firebase-analytics";
var firebaseAnlytics;

import VuePlyr from "vue-plyr";

// Google Adsense    
import Vue from "vue";
import Ads from 'vue-google-adsense'
Vue.use(require('vue-script2'))
Vue.use(Ads.Adsense)
Vue.use(Ads.InFeedAdsense)


export default {
    name        : "Feed",
    
    components  : { "vue-plyr": VuePlyr },
    
    data() {
        return {
            
            loadMoreVideosProgress  : false,
            skipCount               : 1,

            
            savedprets      : [],
            likedAds        : [],
            
            options         : { controls: [ "play-large", "mute", "current-time", "duration" ], hideControls: false },
            
            userId          : "",
            vectorImages    : utilities.vectorImages,
            feedData: [],
            shareableLink   : "https://preskilet.com/",
            dialog          : false,
            cardFlatFlag    : true,
            cardOutlineFlag : false,
            noMorevideos: false,
            apiAlreadyCalled : false

        }
    },
    
    computed    : { ...mapGetters({ userData: "User/userData" }) },
    
    created() {

        
        // FIREBASE ANLYTICS
        firebaseAnlytics = firebase.analytics();
        firebaseAnlytics.logEvent("FeedVideos (Component)");

        this.$store.commit("User/toggleProgressLoader", false);
        this.$store.dispatch("User/updateUserToken", null, { root: true });
        this.$store.commit("User/isSignedIn");


                   var that = this;
        
        $(window).scroll(function() {

            // Desktop
            if($(window).scrollTop() + $(window).height() == $(document).height()) {

                if(that.noMorevideos === false && that.apiAlreadyCalled === false){
                    that.apiAlreadyCalled = true;
                    that.loadFeedVideos();
                }

                if(that.noMorevideos === true)
                {
                    console.log("There are no more videos");
                    //   that.$store.commit("User/showErrorAlert", "There are no more videos");
                }
            }
            
            // For Mobile
             if ($(window).scrollTop() >= $(document).height() - $(window).height() - 100) {
                if(that.noMorevideos === false && that.apiAlreadyCalled === false){
                    that.apiAlreadyCalled = true;
                    that.exploreVideos();
                }

                if(that.noMorevideos === true)
                {
                    console.log("There are no more videos");
                }
            }

        });

    },
    
    mounted() {
        
        if (window.screen.availWidth <= 650) { this.cardFlatFlag = true; } 
        else { this.cardOutlineFlag = true; }
        
        this.shareableLink  = this.shareableLink + this.userData.email;
        this.loadFeedVideos();
    
    },
    
    methods: {

        loadFeedVideos(){
            this.loadMoreVideosProgress = true;

            let endPoint        = apis.urls.getFeed;
            
            axios(endPoint, {
                method          :   "get",
                params          : { "skip" : this.skipCount },
                withCredentials :   true,
                headers         : { authorization : this.userData.token }
            })
            .then((res) => {
                
                this.loadMoreVideosProgress = false;
                
                this.apiAlreadyCalled = false;
                
                if (res.data.result) {
                    
                    let data        = res.data.result.data;
                    this.userId     = data.userId;
                    this.savedprets = data.saved;

                    var videosData = data.feed;
                    
                    if(videosData.length === 0 || this.skipCount > 4999) {
                        this.noMorevideos = true;
                        
                        console.log("There are no more videos");

                        // this.$store.commit("User/showErrorAlert", "There are no more videos");
                    } else {
                        
                        for (let i = 0; i < videosData.length; i++) {
                            const element = videosData[i];
                            this.feedData.push(element)
                            }
                    
                        if(this.skipCount === 1){
                            // FIREBASE ANLYTICS
                            firebaseAnlytics.logEvent("Feed Vides Displayed (Component)");
                        } else {
                            // FIREBASE ANLYTICS
                            firebaseAnlytics.logEvent("Feed Videos (Load more Video) button clicked");
                        }
                        
                        this.skipCount = this.skipCount + 9;
                    }

                
                }
                
            })
            .catch((err) => {

                console.log("Error in FeedVideos.vue", err);
                let errorMsg = utilities.errorHandler(err);
                console.log(errorMsg);
                this.$store.commit("User/toggleProgressLoader", false);
            
            });
        },
        
        save(videoId){
            
            this.$store.commit("User/toggleProgressLoader", true);
            
            let endPoint = apis.urls.savePret;
            
            axios(endPoint, {
                method          :   "post",
                data            : { videoId },
                withCredentials :   true,
                headers         : { authorization: this.userData.token }
            })
            .then((res) => {
                
                this.savedprets.push(videoId)
                
                this.$store.commit("User/toggleProgressLoader", false);
                            
                // FIREBASE ANLYTICS
                firebaseAnlytics.logEvent("Pret Saved on Feed Component");

            })
            .catch((err) => {

                this.$store.commit("User/toggleProgressLoader", false);
                let errorMsg = utilities.errorHandler(err);
                this.$store.commit("User/showErrorAlert", errorMsg);

            });

        },
        
        unsave(videoId){
            
            this.$store.commit("User/toggleProgressLoader", true);
            
            let endPoint = apis.urls.unSavePret;
            
            axios(endPoint, {
                method          :   "post",
                data            : { videoId },
                withCredentials :   true,
                headers         : { authorization: this.userData.token }
            })
            .then((res) => {
                
                this.savedprets.pop(videoId)
                
                this.$store.commit("User/toggleProgressLoader", false);
                
                // FIREBASE ANLYTICS
                firebaseAnlytics.logEvent("Pret Unsaved on Feed Component");

            })
            .catch((err) => {

                this.$store.commit("User/toggleProgressLoader", false);
                let errorMsg = utilities.errorHandler(err);
                this.$store.commit("User/showErrorAlert", errorMsg);
                
            });
        
        },

        likeAd(index){
             this.$store.commit("User/toggleProgressLoader", true);
             this.likedAds.push(index);
             firebaseAnlytics.logEvent("Ad Liked on Feed Page");
             this.$store.commit("User/toggleProgressLoader", false);
        },
        
        unLikeAd(index){
             this.$store.commit("User/toggleProgressLoader", true);
             this.likedAds.pop(index);
             firebaseAnlytics.logEvent("Ad unLiked on Feed Page");
             this.$store.commit("User/toggleProgressLoader", false);
        },

        
        likePret(index) {
            
            this.$store.commit("User/toggleProgressLoader", true);
            
            let endPoint = apis.urls.likePret;
            
            axios(endPoint, {
                method          :   "post",
                data            : { videoId : this.feedData[index]._id },
                withCredentials :   true,
                headers         : { authorization : this.userData.token }
            })
            .then((res) => {
                
                this.feedData[index].likes.push(this.userId);
                this.$store.commit("User/toggleProgressLoader", false);
                // this.$store.commit("User/showSuccessAlert", res.data.result);

                // FIREBASE ANLYTICS
                firebaseAnlytics.logEvent("Pret Liked on Feed Component");

            })
            .catch((err) => {

                this.$store.commit("User/toggleProgressLoader", false);
                let errorMsg = utilities.errorHandler(err);
                this.$store.commit("User/showErrorAlert", errorMsg);

            });
        
        },
        
        unLikePret(index) {
            
            this.$store.commit("User/toggleProgressLoader", true);
            
            let endPoint = apis.urls.unLikePret;
            
            axios(endPoint, {
                method          :   "post",
                data            : { videoId : this.feedData[index]._id},
                withCredentials :   true,
                headers         : { authorization : this.userData.token }
            })
            .then((res) => {
                
                this.feedData[index].likes.pop(this.userId);
                this.$store.commit("User/toggleProgressLoader", false);
                // this.$store.commit("User/showSuccessAlert", res.data.result);

                // FIREBASE ANLYTICS
                firebaseAnlytics.logEvent("Pret Unliked on Feed Component");

            })
            .catch((err) => {
                
                this.$store.commit("User/toggleProgressLoader", false);
                let errorMsg = utilities.errorHandler(err);
                this.$store.commit("User/showErrorAlert", errorMsg);

            });

        },
        
        myLink() {

            var tooltip         = document.getElementById("myTooltip");
            tooltip.innerHTML   = "Link Copied !";
            var copiedText      = document.getElementById("myinput");
            
            copiedText.select();
            copiedText.setSelectionRange(0, 99999);
            document.execCommand("copy");

        },
        
        outFunc() {

            var tooltip         = document.getElementById("myTooltip");
            tooltip.innerHTML   = "Copy to clipboard";

        },
        
        share() {
            
            if (navigator.share) {
                
                navigator.share({
                    title   : "preskilet",
                    text    : "Check out my live Video Profile on Preskilet",
                    url     : this.shareableLink
                })
                .then()
                .catch((err) => {
                    let errorMsg = utilities.errorHandler(err);
                    this.$store.commit("User/showErrorAlert", errorMsg);
                });
            
            } else {
                console.log("outdated browser , sharing cant be done directly, so share link using model");
                this.dialog = true;
            }

        }
    
    }
    
};

</script>
