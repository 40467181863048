<template>
  <div>

    <nav v-if="userData.signedIn">
      <v-toolbar flat style="border-bottom: 1px solid grey; margin-bottom: 3%">
        <v-app-bar-nav-icon
          v-if="!drawer"
          @click="drawer = !drawer"
        ></v-app-bar-nav-icon>

        <v-toolbar-title>
          <a class="Brand-logo" href="/">
            <span class="blue--text">Preskilet</span>
          </a>
        </v-toolbar-title>

        <v-spacer></v-spacer>


        <v-btn v-if="userData.proxyUserEmail" class="ma-2" color="red" outlined style="color: white;" depressed v-on:click="stopProxy">
          <v-icon left size="22">mdi-account-alert</v-icon>
          Stop Proxy
          <v-icon class="blink_me" right>mdi-circle</v-icon>

        </v-btn>


        <v-btn
          v-if="
            this.$route.name != 'SearchByParams' && this.$route.name != 'Search'
          "
          class="ma-2"
          tile
          large
          icon
          router
          to="/search/"
        >
          <v-icon>mdi-magnify</v-icon>
        </v-btn>

        <v-btn
          class="ma-2 hidden-sm-and-down"
          tilex
          outlined
          router
          :to="'/' + userData.email"
        >
          <v-icon left>mdi-account</v-icon>
          My Profile
        </v-btn>

        <v-btn
          class="ma-2 hidden-md-and-up"
          tile
          large
          icon
          router
          :to="'/' + userData.email"
        >
          <v-icon left>mdi-account</v-icon>
        </v-btn>

        <!-- analytics button -->
        <v-btn
          v-if="authorisedEmails.includes(userData.email)"
          class="ma-2 hidden-sm-and-down"
          color='primary'
          style="margin-right: 5px;"
          router :to="'/analytics/' + userData.profile.institutionId"
        >
          <v-icon left>mdi-poll</v-icon>
          Analytics
        </v-btn>

        <v-btn
          v-if="authorisedEmails.includes(userData.email)"
          class="ma-2 hidden-md-and-up"
          tile
          large
          icon
          router :to="'/analytics/' + userData.profile.institutionId"
        >
          <v-icon left>mdi-poll</v-icon>
        </v-btn>
        <!-- analytics button end -->

        <InstallApp />
        <NavbarMenu />
      </v-toolbar>

      <v-navigation-drawer color="whitesmoke" dark v-model="drawer" app>
        <v-list-item class="px-2" style="padding-top: 8px">
          <v-list-item-avatar>
            <v-img :src="profile.profilePhoto"></v-img>
          </v-list-item-avatar>

          <v-list-item-title>{{ profile.name }}</v-list-item-title>

          <v-btn icon @click="drawer = !drawer">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
        </v-list-item>

        <v-list dense>
          <v-list-item
            v-for="item in menuOptions"
            :key="item.title"
            link
            router
            :to="item.route"
          >
            <v-list-item-icon>
              <i
                size="30"
                v-if="item.title == 'Settings'"
                class="material-icons"
                >settings</i
              >
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
    </nav>

    <nav v-if="!userData.signedIn">
      <v-toolbar flat style="border-bottom: 1px solid grey; margin-bottom: 3%">
        <v-toolbar-title>
          <a class="Brand-logo" href="/">
            <span class="blue--text">Preskilet</span>
          </a>
        </v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn
          v-if="this.$route.name != 'Signin'"
          class="ma-2 hidden-sm-and-down blue--text"
          tile
          outlined
          router
          to="/signin"
        >
          <v-icon left>mdi-account</v-icon>Sign-in</v-btn
        >

        <v-btn
          v-if="this.$route.name != 'Signin'"
          class="ma-2 hidden-md-and-up blue--text"
          tile
          large
          icon
          router
          to="/signin"
        >
          <v-icon left>mdi-account</v-icon>
        </v-btn>
      </v-toolbar>
    </nav>

      <v-dialog  v-model="tokenExpiredDialog" persistent max-width="290">
        <v-card>
          <v-card-title class="text-h5">
            Token Expired !
          </v-card-title>
          <v-card-text
            >Your token has been expired, Please force refresh the page.</v-card-text
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="refreshPageForToken()"
            >
              Refresh
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

  </div>
  
</template>

<style scoped>
.Brand-logo {
  text-decoration: none !important;
  color: black !important;
}

a {
  color: #ffffff;
  text-decoration: none;
}

.blink_me {
  animation: blinker 1s linear infinite;
  color: red;
  font-weight: 700;
  font-size: 25px;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}
</style>

<script>
import { mapGetters } from "vuex";

import utilities from "@/helpers/utilities";
import apis from "../helpers/apiConfig";
const axios = require("axios").default;

import InstallApp from "@/components/InstallApp";
import NavbarMenu from "@/components/NavbarMenu";

import firebase from "firebase/app";
import "firebase/firebase-analytics";
var firebaseAnlytics;

export default {
  name: "SignedInNavbar",

  components: { InstallApp, NavbarMenu },

  computed: { ...mapGetters({ userData: "User/userData" }) },

  created() {
    // FIREBASE ANLYTICS
    firebaseAnlytics = firebase.analytics();
    firebaseAnlytics.logEvent("SignedInNavbar Component");

    this.$store.dispatch("User/updateUserToken", null, { root: true });
    this.$store.commit("User/isSignedIn");

    if (this.userData.signedIn) {
      this.setProfile();
    }
  },

  data() {
    return {
      tokenExpiredDialog: false,
      profile: [],
      authorisedEmails: [],
      drawer: false,
      menuOptions: [],
    };
  },

  methods: {
    refreshPageForToken() {
      location.reload(true);
    },

    setProfile() {
      console.log("setprofile from navbar is called")
      let endPoint = apis.urls.getProfile;
      this.$store.commit("User/toggleProgressLoader", true);
      

      axios(endPoint, {
        method: "get",
        withCredentials: true,
        headers: { authorization: this.userData.token },
      })
        .then((res) => {
          if(this.$route.name !== 'SelectAccountType' && res.data.result.data.account === "none") {
            window.location = document.location.origin + "/selectaccounttype"; // Redirect user to select account
          } else {
            
            this.$store.commit("User/toggleProgressLoader", false);
            let data = res.data.result.data.profile;
            data.institutionId = res.data.result.data.institutionId
            this.authorisedEmails = res.data.result.authorisedEmails;
            this.$store.commit("User/updateUserProfile", data);
            console.log("profile data stored on store :", data);
            console.log("Userdata :", this.userData);


            this.profile = data;
            this.profile.account = res.data.result.data.account;
          
            console.log("data : ", data);
            if (String(this.profile.account).toLowerCase() == "teacher") {
              this.menuOptions = [
                { title: "Home", route: "/", icon: "mdi-home" },
                {
                  title: "Saved Prets",
                  route: "/savedprets",
                  icon: "mdi-bookmark",
                },
                { title: "Edit Profile", route: "/profile", icon: "mdi-account" },
                {
                  title: "Groups",
                  route: "/groups",
                  icon: "mdi-account-multiple",
                },
                { title: "Tasks", route: "/tasks", icon: "mdi-file-document" },
                {
                  title: "Group Requests",
                  route: "/grouprequests",
                  icon: "mdi-account-plus",
                },
                {
                  title: "Group Followers",
                  route: "/groupfollowers",
                  icon: "mdi-account-multiple-plus",
                },
                {
                  title: "Upload Video",
                  route: "/pret",
                  icon: "mdi-flag-triangle",
                },
                {
                  title: "Delete Videos",
                  route: "/editpret",
                  icon: "mdi-grease-pencil",
                },
                {
                  title: "Custom Rubrics",
                  route: "/customRubrics",
                  icon: "mdi-star",
                },
                {
                  title: "Recharge & Payment History",
                  route: "/payments",
                  icon: "mdi-credit-card",
                },
                {
                  title: "How to Use Preskilet ?",
                  route: "/how-to-use-preskilet",
                  icon: "mdi-file-document",
                }
              ];
            } else {
              this.menuOptions = [
                { title: "Home", route: "/", icon: "mdi-home" },
                {
                  title: "Upload Video",
                  route: "/pret",
                  icon: "mdi-flag-triangle",
                },
                {
                  title: "Delete Videos",
                  route: "/editpret",
                  icon: "mdi-grease-pencil",
                },
                {
                  title: "Saved Videos",
                  route: "/savedprets",
                  icon: "mdi-bookmark",
                },
                { title: "Edit Profile", route: "/profile", icon: "mdi-account" },
                { title: "Following", route: "/following", icon: "mdi-cctv" },
                {
                  title: "Recharge & Payment History",
                  route: "/payments",
                  icon: "mdi-credit-card",
                },
                {
                  title: "How to Use Preskilet ?",
                  route: "/how-to-use-preskilet",
                  icon: "mdi-file-document",
                }
              ];
            }

            // FIREBASE ANLYTICS
            firebaseAnlytics.logEvent("Navbar Profile Data Displayed");
          }

        })
        .catch((err) => {
          this.$store.commit("User/toggleProgressLoader", false);
          console.log(err);
          let errorMsg = utilities.errorHandler(err);
          console.log(errorMsg); 
          this.tokenExpiredDialog = true;
        });
    },
    stopProxy() {
      this.$store.commit("User/setProxyUser", "");
      if(this.$route.name !== 'ProxyUserMode') {
        window.location = document.location.origin + "/proxyUserMode"; // Redirect user to proxyUserMode
      }
    }
  },
};
</script>
