<template>
<div>
    
    <v-container>
        
        <!-- Sign in with us Dialogue -->
        <v-dialog v-model="signInWithUsDialog" persistent max-width="300">
            
            <v-card>
                
                <v-card-title class="headline"> Create Account with us </v-card-title>
                
                <v-card-text>Sign in with Google to uploade Prets, Comment, Flag or like Prets or To follow people.</v-card-text>
                
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click="signInWithUsDialog = false"> Not Now </v-btn>
                    
                    <v-btn color="green darken-1" text @click="signInWithUsRedirect" router> Create </v-btn>
                </v-card-actions>
            
            </v-card>
        
        </v-dialog>
        
        <!-- Sign in with us Dialogue ended-->
        <v-dialog v-model="dialog" persistent max-width="560">
            
            <v-card>
                <v-card-title class="headline">Copy link</v-card-title>
                
                <v-card-text> Please upadate your browser so you can directly share your preskilet profile, otherwise you can use the below link, click on copy button to copy the link</v-card-text>
                
                <v-text-field id="myLink" v-model="shareableLink" class="ma-3"></v-text-field>
                
                <v-card-actions>
                    <v-spacer></v-spacer>
                    
                    <div class="tooltip">
                        <button>
                            <span class="tooltiptext" id="myTooltip">Copy to clipboard</span>
                            <v-btn
                                color="green darken-1"
                                text
                                @click="copyToClipBoard"
                                @mouseout="outFunc"
                            > Copy </v-btn>
                        </button>
                    </div>
                    
                    <v-btn color="green darken-1" text @click="dialog = false">Close</v-btn>
                </v-card-actions>
            
            </v-card>
        
        </v-dialog>
        
        <v-fab-transition>
            <v-btn
                @click="goUp"
                color="primary"
                style="position: fixed; bottom: 3%; right: 5%; z-index: 1000"
                dark
                absolute
                bottom
                right
                fab
            >
                <v-icon>mdi-chevron-up</v-icon>
            </v-btn>
        
        </v-fab-transition>
        
        <v-layout row wrap justify-space-around>
            
            <v-flex xs12 md5 v-if="!validParameter">
                <v-img class="white--text align-end" src="https://cdn.dribbble.com/users/605899/screenshots/4144886/pikabu.gif"></v-img>
                
                <p style="padding: 5%"> If you are looking for a resume, Please check that the email in the url is valid.
                    <br /><br /> Your entered Url is : /{{ email }}
                </p>
            </v-flex>
            
            <v-flex xs12 md5 v-if="validParameter && !ResumeFound">
                <v-img class="white--text align-end" src="https://thumbs.gfycat.com/NiftyHeavyCat-size_restricted.gif"></v-img>
                <p style="padding: 5%">We couldn't find resume for {{ email }}</p>
            </v-flex>
            
            <v-flex xs12 md6>
                
                <v-card class="mx-auto my-12 ma-3" outlined v-if="validParameter && ResumeFound">
                    
                    <v-list-item two-line>

                        <v-list-item-avatar size="60">
                            <img :src="profile.profilePhoto" />
                        </v-list-item-avatar>
                        
                        <v-list-item-content>
                            <v-list-item-title>{{ profile.name }}</v-list-item-title>
                            <v-list-item-subtitle> {{ viewsLength }} Views</v-list-item-subtitle>
                            <v-list-item-subtitle>{{ blankViewsLength }} Blank Views</v-list-item-subtitle>
                        </v-list-item-content>
                    
                    </v-list-item>
                    
                    <v-list>
                        
                        <v-list-item v-if="curiculoProfile">
                            <v-list-item-icon>
                                <v-icon color="primary">mdi-tie</v-icon>
                            </v-list-item-icon>
                
                            <v-list-item-title>
                                <a style="text-decoration: none" :href="curiculoProfile" target="_blank">
                                    Visit Resume At Curiculo live
                                    <v-icon color="primary" size="14">mdi-launch</v-icon>
                                </a>
                            </v-list-item-title>
                        </v-list-item>

                        <v-list-item v-if='false'>
                            <v-list-item-icon>
                                <v-icon color="primary">mdi-theater</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>{{ followers }} followers</v-list-item-title>
                        </v-list-item>

                        <v-list-item v-if='false'>
                            <v-list-item-icon>
                                <v-icon color="primary">mdi-cctv</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>{{ following }} following</v-list-item-title>
                        </v-list-item>


                        <v-list-item>
                        
                            <v-list-item-icon>
                                <v-icon color="primary">mdi-flag-triangle</v-icon>
                            </v-list-item-icon>
                            
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-list-item-title v-bind="attrs" v-on="on"> Preskilet Score : {{ preskiletScore }} </v-list-item-title> 
                                </template>
                                
                                <span>Preskilet Score = Avarage Grade  * Total Prets Uploaded By You * Total Prets Graded By You</span>
                            </v-tooltip>
                            
                        </v-list-item>

                        <!-- Grades -->
                        <div v-if="preskiletScore != 0">

                                   
                            <v-list-item >

                                <v-tooltip right>

                                    <template v-slot:activator="{ on, attrs }">
                        
                                        <v-list-item-icon v-bind="attrs" v-on="on">
                                            <v-icon color="primary">mdi-alphabetical</v-icon>
                                        </v-list-item-icon>
                                    
                                    </template>

                                    <span>Language Proficiency</span>

                                </v-tooltip>
                                
                                <v-list-item-title>
                                    <v-rating 
                                        readonly
                                        v-model="avgGrades.language"
                                        color="primary"
                                        background-color="primary darken-1"
                                    ></v-rating>
                                </v-list-item-title>
                            </v-list-item>
                                



                        <v-list-item>

                            <v-tooltip right>
                                
                                <template v-slot:activator="{ on, attrs }">
                                
                                    <v-list-item-icon v-bind="attrs" v-on="on">
                                        <v-icon color="primary">mdi-surround-sound</v-icon>
                                    </v-list-item-icon>
                                
                                </template>

                                <span>Voice Modulation</span>
                                    
                            </v-tooltip>

                            <v-list-item-title>
                                <v-rating
                                    readonly
                                    v-model="avgGrades.voice"
                                    color="primary"
                                    background-color="primary darken-1"
                                ></v-rating>
                            </v-list-item-title>
                        </v-list-item>

                        <v-list-item>

                            <v-tooltip right>
                                
                                <template v-slot:activator="{ on, attrs }">
                                
                                    <v-list-item-icon v-bind="attrs" v-on="on">
                                        <v-icon color="primary">mdi-human-greeting</v-icon>
                                    </v-list-item-icon>

                                </template>

                                <span>Eye Contact, Hand Gestures and Body Movement</span>

                            </v-tooltip>

                            <v-list-item-title>
                                <v-rating
                                    readonly
                                    v-model="avgGrades.gestures"
                                    color="primary"
                                    background-color="primary darken-1"
                                ></v-rating>
                            </v-list-item-title>
                        </v-list-item>

                        <v-list-item>

                            <v-tooltip right>
                                
                                <template v-slot:activator="{ on, attrs }">
                                
                                    <v-list-item-icon v-bind="attrs" v-on="on">
                                        <v-icon color="primary">mdi-account-settings</v-icon>
                                    </v-list-item-icon>
                                
                                </template>

                                <span>Confidence</span>
                            
                            </v-tooltip>
                            
                            <v-list-item-title>
                                <v-rating
                                    readonly
                                    v-model="avgGrades.confidence"
                                    color="primary"
                                    background-color="primary darken-1"
                                ></v-rating>
                            </v-list-item-title>
                        </v-list-item>
                        
                        <v-list-item>

                            <v-tooltip right>
                                
                                <template v-slot:activator="{ on, attrs }">
                                
                                    <v-list-item-icon v-bind="attrs" v-on="on">
                                        <v-icon color="primary">mdi-presentation-play</v-icon>
                                    </v-list-item-icon>

                                </template>

                                <span>Content Delivery and Knowledge</span>  

                            </v-tooltip>

                            <v-list-item-title>
                                <v-rating
                                    readonly
                                    v-model="avgGrades.confidence"
                                    color="primary"
                                    background-color="primary darken-1"
                                ></v-rating>
                            </v-list-item-title>
                        </v-list-item>


                        <v-list-item>

                            <v-tooltip right>
                                
                                <template v-slot:activator="{ on, attrs }">
                                
                                    <v-list-item-icon v-bind="attrs" v-on="on">
                                        <v-icon color="primary">mdi-account-star</v-icon>
                                    </v-list-item-icon>

                                </template>

                                <span>Avarage Grading</span> 

                            </v-tooltip>
                               
                            <v-list-item-title>
                                <v-rating
                                    readonly
                                    v-model="avgPreskiletGrade"
                                    color="primary"
                                    background-color="primary darken-1"
                                ></v-rating>
                            </v-list-item-title>
                        </v-list-item>
                        </div>

                        <v-list-item v-if="profile.curiculo">
                            <v-list-item-icon>
                            <v-icon color="primary">mdi-tie</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>
                            <a
                                style="text-decoration: none"
                                :href="profile.curiculo"
                                target="_blank"
                            >
                                Visit Resume At Curiculo Live
                                <v-icon color="primary" size="14">mdi-launch</v-icon>
                            </a>
                            </v-list-item-title>
                        </v-list-item>
                    
                    </v-list>
                    
                    <v-card-actions>
                        
                        <v-row justify="space-around" v-if="validParameter && ResumeFound" class="pa-0 ma-0">
                            
                            <v-btn @click="follow" v-if="false" class="ma-2" outlined color="primary"> Follow </v-btn>
                            
                            <v-btn @click="unfollow" v-if="false" class="ma-2" outlined color="grey"> Unfollow </v-btn>
                            
                            <v-btn @click="share()" class="ma-2" outlined color="primary">
                                <v-icon left>mdi-share-variant</v-icon>
                                <span class="hidden-sm-and-up" style="font-size: 12px">Share</span>
                                <span class="hidden-md-and-down">Share</span>
                            </v-btn>
                        </v-row>
                    
                    </v-card-actions><br />
                
                </v-card>


                <v-list v-if="groupsList.length != 0" subheader two-line>
                    <v-subheader>Groups</v-subheader>
                    
                    <v-list-item v-for="item in groupsList" :key="item._id">
                        <v-list-item-content>
                            <v-list-item-title v-text="item.name"></v-list-item-title>
                            <v-list-item-subtitle class="text-wrap">

                                {{item.subject}}
                                {{item.type}}

                                {{item.academicYear}}
                                {{item.div}}
                                {{item.batch}}

                                {{item.department}}

                                {{item.year}} <br /><br />
                                {{item.description}}
                            </v-list-item-subtitle>
                        </v-list-item-content>
                        
                        <v-list-item-action>
                            <v-btn @click="followGroup(item._id)"  v-if="!followingGroups.includes(item._id) && !pendingGroups.includes(item._id)" class="ma-2" outlined color="primary"> Follow </v-btn>
                            
                            <v-btn diabled v-if="pendingGroups.includes(item._id)" text color="grey"> Pending </v-btn>
                            <v-btn @click="unFollowGroup(item._id)" v-if="followingGroups.includes(item._id) || pendingGroups.includes(item._id)" text color="grey"> Unfollow </v-btn>
                            
                        </v-list-item-action>
                    </v-list-item>
                </v-list>
            </v-flex>
            
            <v-flex xs12 md4 style="margin-top: -20px">
                <br /><br />
                
                <p class="videoblock" style="font-size: 24px" v-if="prets.length == 0">
                    <b> No Prets (Presentation Videos) Yet </b>
                </p>
                
                <div v-if="prets.length != 0">
                    <div v-for="item in prets" :key="item._id">
                        
                        <v-card flat class="mx-auto my-6">
                            
                            <router-link style="text-decoration: none; color: black" :to="'/watch?v=' + item._id">
                            
                                <v-img
                                    :src="item.thumbnailUrl"
                                    style="width: 100%; height: 250px"
                                    class="white--text align-end videoRecommendationThubmnail"
                                    gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                                    height="200px"
                                >
                                    <v-list-item two-line>
                                        <v-list-item-content>
                                            <v-list-item-title class="white--text"> {{ item.title }} </v-list-item-title>
                                            <v-list-item-subtitle class="white--text">{{ item.viewsLength }} Views</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-img>
                            
                            </router-link>
                        </v-card>
                    
                    </div>
                </div>
            
            </v-flex>
        </v-layout>
    </v-container> <br /> <br /> <br /> <br />

</div>
</template>

<style scoped>
/* for desktop */
@media screen and (min-width: 650px) {
  .videoRecommendationThubmnail {
    border-radius: 7px;
  }
}

@media screen and (min-width: 650px) {
  .videoblock {
    margin: 50px;
  }
}
@media screen and (max-width: 650px) {
  .videoblock {
    margin: 15px;
  }
}

#create .v-speed-dial {
  position: absolute;
}

#create .v-btn--floating {
  position: relative;
}
.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 140px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 150%;
  left: 50%;
  margin-left: -75px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
</style>

<script>
import { mapGetters } from "vuex";

import utilities from "@/helpers/utilities";
import apis from "../helpers/apiConfig";
const axios = require("axios").default;

import firebase from "firebase/app";
import "firebase/firebase-analytics";
var firebaseAnlytics;
// Google Adsense    
import Vue from "vue";
import Ads from 'vue-google-adsense'
Vue.use(require('vue-script2'))
Vue.use(Ads.Adsense)
Vue.use(Ads.InFeedAdsense)


export default {
    name: "Preskilet",
    
    computed: { ...mapGetters({ userData: "User/userData" }) },
    
    created() {

        // FIREBASE ANLYTICS
        firebaseAnlytics = firebase.analytics();
        firebaseAnlytics.logEvent("Preskilet Page");

        if(this.userData.signedIn){
            this.getFollowingGroupIds();
        }
        
        this.$store.commit("User/toggleProgressLoader", false);
        this.$store.dispatch("User/updateUserToken", null, { root: true });
        this.$store.commit("User/isSignedIn");
        
        let parameterEmail = this.email;
        
        this.validEmailParameter    = /.+@.+\..+/.test(parameterEmail)      ? true : false;
        
        this.validUserIdParameter   = /^[a-f\d]{24}$/i.test(parameterEmail) ? true : false;
        
        if (this.validEmailParameter || this.validUserIdParameter) {
            
            this.validParameter = true;
            
            var endPoint = apis.urls.preskilet + "?user=" + parameterEmail;
            
            this.$store.commit("User/toggleProgressLoader", true);
            
            axios(endPoint, {
                method          :   "get",
                withCredentials :   true,
                headers         : { authorization: this.userData.token }
            })
            .then((res) => {
                
                this.$store.commit("User/toggleProgressLoader", false);
                
                console.log(res.data.result.data);
                
                let data = res.data.result.data;
                
                this.userId = data.userData._id;
                
                this.profile = data.userData.profile;
                this.curiculoProfile = data.userData.curiculoProfile;
                this.accountType =data.userData.account;
                
                this.followers = data.userData.followersLength;
                this.following = data.userData.followingLength;
                
                this.prets = data.prets;
                this.groupsList = data.groups;
                
                this.viewsLength = data.userData.viewsLength;
                this.blankViewsLength = data.userData.blankViewsLength;

                this.ResumeFound = true;

                if (data.userData._id == data.userId) {
                    this.followFlag = false;
                    this.unfollowFlag = false;
                } else {
                    if (data.userData.followers.includes(data.userId)) {
                    this.unfollowFlag = true;
                    } else {
                    this.followFlag = true;
                    }
                }
                
                this.avgGrades = data.avgGrades;
                
                var avgGradesSum = this.avgGrades.language + this.avgGrades.voice +  this.avgGrades.gestures + this.avgGrades.confidence + this.avgGrades.content;
                
                this.avgPreskiletGrade =  avgGradesSum / 5;
                
                this.preskiletScore = data.userData.score;
            
            })
            .catch((err) => {
                
                this.ResumeFound = false;
                this.$store.commit("User/toggleProgressLoader", false);
                let errorMsg = utilities.errorHandler(err);
                this.$store.commit("User/showErrorAlert", errorMsg);
            
            });
        
        } else {
            this.ResumeFound = false;

            
             this.$store.commit("User/showErrorAlert", "404 : NOT FOUND") };
    },
    
    data() {
        return {
            
            avgGrades           : { language : 0, voice : 0, gestures : 0, confidence : 0, content    : 0},
            avgPreskiletGrade   : 0,
            
            preskiletScore      : 0,
            
            fab                 : true,
            dialog              : false,

            email               : this.$route.params.email,
            userId              : "",
            shareableLink       : "https://preskilet.com/" + this.$route.params.email,
            validEmailParameter : false,
            ResumeFound         : true,
            signInWithUsDialog  : false,
            followFlag          : false,
            unfollowFlag        : false,
            profile             : {},
            prets               : ["something"],
            videoCount          : 0,
            socialProfile       : [],
            viewsLength         : "",
            blankViewsLength    : "",
            followers           : "",
            following           : "",
            groupsList          : [],
            followingGroups     : [],
            pendingGroups       : [],
            curiculoProfile     : "",
            accountType         : ""
        }
    },
    
    methods: {
        
        goBack() { window.history.back() },
        
        goUp() { window.scrollTo(0, 0) },
        
        signInWithUsRedirect() { window.location.replace("/signin") },
        
        copyToClipBoard() {
            var tooltip = document.getElementById("myTooltip");
            tooltip.innerHTML = "Link Copied !";
            
            var copiedText = document.getElementById("myinput");
            copiedText.select();
            copiedText.setSelectionRange(0, 99999);
            document.execCommand("copy");
        },
        
        outFunc() {
            var tooltip = document.getElementById("myTooltip");
            tooltip.innerHTML = "Copy to clipboard";
        },
        
        share() {
            
            if (navigator.share) {
                
                navigator.share({
                    title   : "Preskilet",
                    text    : "Check out my live Video Profile on Preskilet",
                    url     : this.shareableLink
                })
                .then()
                .catch((err) => {
                    let errorMsg = utilities.errorHandler(err);
                    this.$store.commit("User/showErrorAlert", errorMsg)
                });
            
            } else {
                //outdated browser, opening model to copy link
                this.dialog = true;
            }
        },
        
        follow() {
            
            if (!this.userData.signedIn) { 
                this.signInWithUsDialog = true;
            } else {
                
                this.$store.commit("User/toggleProgressLoader", true);
                this.goUp();
                
                let endPoint = apis.urls.follow;
                
                axios(endPoint, {
                    method          :   "post",
                    data            : { userId: this.userId},
                    withCredentials :   true,
                    headers         : { authorization: this.userData.token}
                })
                .then((res) => {
                    
                    this.followFlag = false;
                    this.unfollowFlag = true;
                    this.followers += 1;
            
                    this.$store.commit("User/toggleProgressLoader", false);
                    this.$store.commit("User/showSuccessAlert", res.data.result);

                    // FIREBASE ANLYTICS
                    firebaseAnlytics.logEvent("New Follow Operation");
                
                })
                .catch((err) => {
                    
                    this.$store.commit("User/toggleProgressLoader", false);
                    let errorMsg = utilities.errorHandler(err);
                    this.$store.commit("User/showErrorAlert", errorMsg);
                });
            }
        },
        
        unfollow() {
            
            if (!this.userData.signedIn) {
                this.signInWithUsDialog = true;
            } else {
                
                this.$store.commit("User/toggleProgressLoader", true);
                this.goUp();
                
                let endPoint = apis.urls.unfollow;
                
                axios(endPoint, {
                    method          :   "post",
                    data            : { userId: this.userId},
                    withCredentials :   true,
                    headers         : { authorization: this.userData.token }
                })
                .then((res) => {
                    
                    this.followFlag = true;
                    this.unfollowFlag = false;
                    this.followers -= 1;

                    this.$store.commit("User/toggleProgressLoader", false);
                    this.$store.commit("User/showSuccessAlert", res.data.result);

                    // FIREBASE ANLYTICS
                    firebaseAnlytics.logEvent("New Unfollow Operation");

                })
                .catch((err) => {
                    this.$store.commit("User/toggleProgressLoader", false);
                    let errorMsg = utilities.errorHandler(err);
                    this.$store.commit("User/showErrorAlert", errorMsg);
                });
            }
        },
        
        openSocialProfile(link) {
            window.open(link, "_blank");
            win.focus();
        },

        getFollowingGroupIds(){
            
            let endPoint = apis.urls.getFollowingGroupIds;
            
            axios(endPoint, {
                method          : "get",
                withCredentials : true,
                headers         : { authorization: this.userData.token }
            })
            .then((res) => {
                console.log("getFollowingGroupIds : ",res);
                
                let data = res.data.result.data;
                this.pendingGroups = data.pending;            
                this.followingGroups = data.following;
                
                // FIREBASE ANLYTICS
                firebaseAnlytics.logEvent("Group Following ID Retrived");

            })
            .catch((err) => {
                console.log("Error in Following",err);
                let errorMsg = utilities.errorHandler(err);
                this.$store.commit("User/showErrorAlert", errorMsg);
            });
        },

        followGroup(groupId){
            
            if (!this.userData.signedIn) { 
                this.signInWithUsDialog = true;
            } else {

                this.$store.commit("User/toggleProgressLoader", true);
                
                let endPoint = apis.urls.followGroup;
                
                axios(endPoint, {
                    method          :   "post",
                    data            : { groupId: groupId},
                    withCredentials :   true,
                    headers         : { authorization: this.userData.token}
                })
                .then((res) => {
                    console.log("followed group : ",res);
                    console.log("res.data.result.isAccepted : ", res.data.result.isAccepted);
                    if(res.data.result.isAccepted) {
                        this.$store.commit("User/showSuccessAlert", "You are following the group!");
                        this.followingGroups.push(groupId)
                    } else 
                    {
                        this.$store.commit("User/showSuccessAlert", "Group join request sent, Please contact your teacher to accept the request!");
                        this.pendingGroups.push(groupId)
                    }
                    
                    console.log("after push : ", groupId);
                    console.log("followingGroups ", this.followingGroups);
                    console.log("pendingGroups ", this.pendingGroups);

                    this.$store.commit("User/toggleProgressLoader", false);
                    
                    // FIREBASE ANLYTICS
                    firebaseAnlytics.logEvent("New Group Follow Operation");
                
                })
                .catch((err) => {
                    
                    this.$store.commit("User/toggleProgressLoader", false);
                    let errorMsg = utilities.errorHandler(err);
                    this.$store.commit("User/showErrorAlert", errorMsg);
                });
            }

            
        },

        unFollowGroup(groupId){
            
            if (!this.userData.signedIn) { 
                this.signInWithUsDialog = true;
            } else {

                this.$store.commit("User/toggleProgressLoader", true);
                
                let endPoint = apis.urls.unFollowGroup;
                
                axios(endPoint, {
                    method          :   "post",
                    data            : { groupId: groupId},
                    withCredentials :   true,
                    headers         : { authorization: this.userData.token}
                })
                .then((res) => {
                    console.log("followed group : ",res);
                    
                    console.log("Before pop : ", groupId);
                    console.log("followingGroups ", this.followingGroups);
                    console.log("pendingGroups ", this.pendingGroups);

                    const indexInFollowing = this.followingGroups.indexOf(groupId);
                    if (indexInFollowing > -1) {
                        this.followingGroups.splice(indexInFollowing, 1);
                    }

                    const indexInPending = this.pendingGroups.indexOf(groupId);
                    if (indexInPending > -1) {
                        this.pendingGroups.splice(indexInPending, 1);
                    }

                    console.log("after pop : ", groupId);
                    console.log("followingGroups ", this.followingGroups);
                    console.log("pendingGroups ", this.pendingGroups);


                    this.$store.commit("User/toggleProgressLoader", false);
                    this.$store.commit("User/showSuccessAlert", res.data.result.data);

                    // FIREBASE ANLYTICS
                    firebaseAnlytics.logEvent("New Unfollow Operation");
                
                })
                .catch((err) => {
                    
                    this.$store.commit("User/toggleProgressLoader", false);
                    let errorMsg = utilities.errorHandler(err);
                    this.$store.commit("User/showErrorAlert", errorMsg);
                });
            }

        }
    
    }
};
</script>