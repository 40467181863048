<template>
<div>

    <v-fab-transition>
        <v-btn
            @click="goBack"
            color="primary"
            style="position: fixed; bottom: 6%; right: 5%; z-index: 1000"
            dark
            absolute
            bottom
            right
            fab
            v-if="iosBackButtonFlag"
        >
            <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
    </v-fab-transition>
    
    <v-container style="padding-top: 0px !important">
        
        <!-- Sign in with us Dialogue -->
        <v-dialog v-model="signInWithUsDialog" persistent max-width="300">
            <v-card>
                <v-card-title class="headline"> Create Account with us </v-card-title>
                <v-card-text>Sign in with Google to uploade Prets, Comment, Flag or like Prets or To follow people.</v-card-text>
                
                <v-card-actions>
                
                    <v-spacer></v-spacer>
                    
                    <v-btn color="green darken-1" text @click="signInWithUsDialog = false"> Not Now </v-btn>
                    <v-btn color="green darken-1" text router @click="signInWithUsRedirect"> Create </v-btn>
                
                </v-card-actions>
            </v-card>
        </v-dialog>
        
        <!-- Grade Dialogue -->
        <v-dialog v-model="gradeDialog" max-width="350">
            <v-card>
                <v-card-title class="headline">Select the Grades </v-card-title>
                <v-list two-line class="ma-0 pa-0">
                    
                    <v-list-item>
                        <span style="color: #1976d2 !important">Language Proficiency</span>
                        <v-spacer></v-spacer>
                        <v-rating v-model="grades.language" color="primary" background-color="primary darken-1"></v-rating>
                    </v-list-item>
                    
                    <v-list-item>
                        <span style="color: #1976d2 !important">Voice Modulation</span>
                        <v-spacer></v-spacer>
                        <v-rating v-model="grades.voice" color="primary" background-color="primary darken-1"></v-rating>
                    </v-list-item>

                    <v-list-item>
                        <span style="color: #1976d2 !important">Eye Contact, Hand Gestures and Body Movement </span>
                        <v-spacer></v-spacer>
                        <v-rating v-model="grades.gestures" color="primary" background-color="primary darken-1"></v-rating>
                    </v-list-item>

                    <v-list-item>
                        <span style="color: #1976d2 !important">Confidence</span>
                        <v-spacer></v-spacer>
                        <v-rating v-model="grades.confidence" color="primary" background-color="primary darken-1"></v-rating>
                    </v-list-item>

                    <v-list-item>
                        <span style="color: #1976d2 !important">Content Delivery and Knowledge</span>
                        <v-spacer></v-spacer>
                        <v-rating v-model="grades.content" color="primary" background-color="primary darken-1"></v-rating>
                    </v-list-item>
                
                </v-list>
                
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click="gradeDialog = false">Cancel</v-btn>
                    <v-btn color="green darken-1" text @click="gradePret">Grade</v-btn>
                </v-card-actions>
            
            </v-card>
        
        </v-dialog>
        
        <!-- Share dialogue -->
        <v-dialog v-model="shareDialog" persistent max-width="560">
            <v-card>
                <v-card-title class="headline">Copy link</v-card-title>
                <v-card-text> Please upadate your browser so you can directly share your resume, otherwise you can use the below link, click on copy button to copy the link</v-card-text>
                
                <v-text-field id="myLink" v-model="shareableLink" class="ma-3"></v-text-field>
                
                <v-card-actions>
                    <v-spacer></v-spacer>
                    
                    <div class="tooltip">
                        <button>
                            <span class="tooltiptext" id="myTooltip">Copy to clipboard</span>
                            <v-btn color="green darken-1" text @click="copyLink" @mouseout="outFunc" >Copy</v-btn>
                        </button>
                    </div>
                    
                    <v-btn color="green darken-1" text @click="shareDialog = false">Close</v-btn>
                </v-card-actions>
            
            </v-card>
        
        </v-dialog>
        
        <!-- Flag dialogue -->
        <v-dialog v-model="flagDialog" max-width="350">
            <v-card>
                <v-card-title class="headline">Mark as Inappropriate ?</v-card-title>
                
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click="flagDialog = false">No</v-btn>
                    <v-btn color="green darken-1" text @click="flagPret">Yes</v-btn>
                </v-card-actions>
            
            </v-card>
        </v-dialog>
        
        <!-- Comment Dialogue delete -->
        <v-dialog v-model="commentDeleteDialog" max-width="350">
            <v-card>
                <v-card-title class="headline">Are you sure ?</v-card-title>
                
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click="commentDeleteDialog = false">    No  </v-btn>
                    <v-btn color="green darken-1" text @click="deleteComment()">                Yes </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        
        <!-- ------------------------------------------------------------------------------------------------------------------------------------ -->
        <v-layout row wrap justify-space-around>
            <v-flex xs12 md12>
                
                <!-- Video card started -->
                <v-card flat class="mx-auto">
                    
                    <!-- Video player -->
                    <vue-plyr :options="options" class="playerHeight">
                        
                        <video
                            class="player"
                            style="--plyr-color-main: #2196f3;"
                            src="https://firebasestorage.googleapis.com/v0/b/preskilet.appspot.com/o/prets%2Fdhaneshpawar.me%40gmail.com%2F%20%7C%20Real%20Title%20%3A%20My%20Video%20Resume%20%7C%20Date%20%3A%20Tue%2C%2009%20Mar%202021%2007%3A11%3A05%20GMT%2F%20%7C%20Real%20Title%20%3A%20My%20Video%20Resume%20%7C%20Date%20%3A%20Tue%2C%2009%20Mar%202021%2007%3A11%3A29%20GMT?alt=media&token=cae844e8-0425-46db-a5bd-7967e81ae609"
                            controls
                            playsinline
                        ></video>
                    
                    </vue-plyr>

                            <!-- data-poster="https://firebasestorage.googleapis.com/v0/b/preskilet.appspot.com/o/prets%2Fdhaneshpawar.me%40gmail.com%2F%20%7C%20Real%20Title%20%3A%20My%20Video%20Resume%20%7C%20Date%20%3A%20Tue%2C%2009%20Mar%202021%2007%3A11%3A05%20GMT%2F%20%7C%20Real%20Title%20%3A%20My%20Video%20Resume%20%7C%20Date%20%3A%20Tue%2C%2009%20Mar%202021%2007%3A11%3A05%20GMT?alt=media&token=9a7c3f6f-b6e5-4872-960e-1b5de5ef3e29" -->

                  
                </v-card>
                
              
            </v-flex>

            <br><br><br><br><br><br>

            <p>It was my dad’s birthday, and I wanted to order a chocolate cake and a shirt for him. I headed over to Google to search for chocolate cakes and clicked on the first link in the search results. There was a blank screen for a few seconds; I didn’t understand what was happening. After a few seconds of staring patiently, my mobile screen filled with delicious-looking cakes. As soon as I clicked on one of them to check its details, I got an ugly fat popup, asking me to install an Android application so that I could get a silky smooth experience while ordering a cake.

That was disappointing. My conscience didn’t allow me to click on the “Install” button. All I wanted to do was order a small cake and be on my way.

I clicked on the cross icon at the very right of the popup to get out of it as soon as I could. But then the installation popup sat at the bottom of the screen, occupying one-fourth of the space. And with the flaky UI, scrolling down was a challenge. I somehow managed to order a Dutch cake.

After this terrible experience, my next challenge was to order a shirt for my dad. As before, I search Google for shirts. I clicked on the first link, and in a blink, the entire content was right in front of me. Scrolling was smooth. No installation banner. I felt as if I was browsing a native application. There was a moment when my terrible internet connection gave up, but I was still able to see the content instead of a dinosaur game. Even with my janky internet, I managed to order a shirt and jeans for my dad. Most surprising of all, I was getting notifications about my order.

I would call this a silky smooth experience. These people were doing something right. Every website should do it for their users. It’s called a progressive web app.

As Alex Russell states in one of his blog posts:

“It happens on the web from time to time that powerful technologies come to exist without the benefit of marketing departments or slick packaging. They linger and grow at the peripheries, becoming old-hat to a tiny group while remaining nearly invisible to everyone else. Until someone names them.”
A Silky Smooth Experience On The Web, Sometimes Known As A Progressive Web Application
Progressive web applications (PWAs) are more of a methodology that involves a combination of technologies to make powerful web applications. With an improved user experience, people will spend more time on websites and see more advertisements. They tend to buy more, and with notification updates, they are more likely to visit often. The Financial Times abandoned its native apps in 2011 and built a web app using the best technologies available at the time. Now, the product has grown into a full-fledged PWA.

But why, after all this time, would you build a web app when a native app does the job well enough?

Let’s look into some of the metrics shared in Google IO 17.</p>



        </v-layout>
    
    </v-container>

</div>
</template>

<style scoped>

.playerHeight{
    max-height: 350px !important;
}

/* for mobile */
 @media screen and (max-width: 600px) {
  .videoRecommendationThubmnail {
    border-top   : 17px solid whitesmoke;
    border-bottom   : 17px solid whitesmoke;
  }
}

/* for desktop */
@media screen and (min-width: 650px) {
  .videoRecommendationThubmnail {
    border-radius   : 7px;
  }
  .playerHeight{
    max-height: 650px !important;
}
}

.form-container {
  padding   : 20px;
}

.tooltip {
  position  : relative;
  display   : inline-block;
}

.tooltip .tooltiptext {
  visibility        : hidden;
  width             : 140px;
  background-color  : #555;
  color             : #fff;
  text-align        : center;
  border-radius     : 6px;
  padding           : 5px;
  position          : absolute;
  z-index           : 1;
  bottom            : 150%;
  left              : 50%;
  margin-left       : -75px;
  opacity           : 0;
  transition        : opacity 0.3s;
}

.tooltip .tooltiptext::after {
  content       : "";
  position      : absolute;
  top           : 100%;
  left          : 50%;
  margin-left   : -5px;
  border-width  : 5px;
  border-style  : solid;
  border-color  : #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility    : visible;
  opacity       : 1;
}

</style>

<script>

import { mapGetters } from "vuex";

import utilities from "@/helpers/utilities";
import apis from "../helpers/apiConfig";
const axios = require("axios").default;

import firebase from "firebase/app";
import "firebase/firebase-analytics";
var firebaseAnlytics;

// Google Adsense    
import Vue from "vue";
import Ads from 'vue-google-adsense'
Vue.use(require('vue-script2'))
Vue.use(Ads.Adsense)
Vue.use(Ads.InFeedAdsense)

import VuePlyr from "vue-plyr";

export default {
    name        :   "Watch",
    
    computed    : { ...mapGetters({ userData: "User/userData" }) },
    
    components  : { "vue-plyr": VuePlyr },
        
    mounted() {

        // FIREBASE ANLYTICS
        firebaseAnlytics = firebase.analytics();
        firebaseAnlytics.logEvent("Watch Page");
        
        this.$store.commit("User/toggleProgressLoader", false);
        this.$store.dispatch("User/updateUserToken", null, { root: true });
        this.$store.commit("User/isSignedIn");
    
        this.checkIOS();
        
        // if (this.watchQuery == undefined) {
        //     this.$store.commit("User/toggleProgressLoader", false);
        // } else {

        //     this.loadPretVideo();
        //     this.getAvgGrades();
        //     this.goUp();

        // }

    },
    
    data() {

        return {
            loadMoreVideosProgress  : false,
            excludeVideoIDs        : [],
            
            gradeDialog : false,
            gradedFlag  : false,

            // grades set by user while giving the grade
            grades          : {
                language         : 1,
                voice       : 1,
                gestures    : 1,
                confidence  : 1,
                content     : 1
            },
            
            //avgGrades of the video
            avgGrades       : {
                language         : 0,
                voice       : 0,
                gestures    : 0,
                confidence  : 0,
                content     : 0
            },
            
            avgVideoGrade   : 0,
            
            options         : { 

                adTagUrl: 'https://pubads.g.doubleclick.net/gampad/ads?iu=/22381172797/test_video_ad&description_url=https%3A%2F%2Fpreskilet.com%2Fgoogleadtest&tfcd=0&npa=0&sz=640x480&cmsid=[placeholder]&vid=[placeholder]&gdfp_req=1&output=vast&unviewed_position_start=1&env=vp&impl=s&correlator=',

                skipButton: {enabled: true, text: 'Skip ad', delay: 10},
                
                ads: {
                    enabled: true, 
                    tagUrl: 'https://pubads.g.doubleclick.net/gampad/ads?iu=/22381172797/test_video_ad&description_url=https%3A%2F%2Fpreskilet.com%2Fgoogleadtest&tfcd=0&npa=0&sz=640x480&cmsid=[placeholder]&vid=[placeholder]&gdfp_req=1&output=vast&unviewed_position_start=1&env=vp&impl=s&correlator='
                },

                controls: [ "play-large", "play", "progress", "current-time", "duration", "mute", "volume", "fullscreen" ], 
                
                hideControls: false 
            },
            
            showDescriptionFlag : false,
            showCommentsFlag    : true,
            watchQuery          : this.$route.query.v,
            commentDeleteDialog : false,
            watchResults        : [],
            pretUpladeDate      : "",
            watchResultsNotFound: false,
            
            //check ios devices to show the back button
            iosBackButtonFlag   : false,
            
            //Sharing and flag
            signInWithUsDialog  : false,
            shareDialog         : false,
            shareableLink       : "https://preskilet.com/watch?v=" + this.$route.query.v,
            flagDialog          : false,
            commentRules        : [ (v) => (v && v.length <= 2000) || "Comment must be less than 2000 characters",],

            // Variables to be sent from backend
            likeFlag            : false,
            unLikeFlag          : false,
            savedFlag           : false,
            savedvideos         : [],
            unsavedFlag         : false,
            
            saved               : false,
            followFlag          : false,
            unfollowFlag        : false,
            
            //addtional parameters
            userId              : "",
            videoUserId         : "",
            
            //dynamic additional parameters , not from backend
            comment             : "",
            commentsPret        : [],
            deleteCommentId     : "",
            deleteCommentIndex  : "",
            recommendationsVideosArray: [],
            exploreVideosArray  : [],

            gradeData           : []

        }
    
    },
    
    methods: {
        
        signInWithUsRedirect() { window.location.replace("/signin") },
        
        loadPretVideo() {
            
            this.$store.commit("User/toggleProgressLoader", true);
            
            var endPoint = apis.urls.viewPret;
            
            axios(endPoint, {
                method          :   "get",
                params          : { videoId : this.watchQuery},
                withCredentials :   true,
                headers         : { authorization : this.userData.token }
            })
            .then((res) => {

                console.log("pret data ", res);
                
                this.$store.commit("User/toggleProgressLoader", false);
                
                // FIREBASE ANLYTICS
                firebaseAnlytics.logEvent("Pret Loaded on Watch Page");
                
                this.watchResults   = res.data.result.data.watchResults;
                this.savedvideos    = res.data.result.data.saved;
                this.userId         = res.data.result.data.userId;
                this.gradeData      = res.data.result.data.gradeData;
                
                this.avgVideoGrade  = this.watchResults.grades;
                
                const UplaodDateTobeParsed = new Date(this.watchResults.date);
                
                this.pretUpladeDate = 
                    UplaodDateTobeParsed.getDate()  +   "/" +
                    UplaodDateTobeParsed.getMonth() +   "/" +
                    UplaodDateTobeParsed.getFullYear();
                    
                if (this.watchResults.length == 0) {
                    this.watchResultsNotFound = true;
                } else {
                    
                    // setting the additional parameters
                    this.videoUserId    = this.watchResults.userId._id;
                    
                    //setting comments
                    this.commentsPret   = this.watchResults.comments;

                    //follow unfollow buttons
                    if (this.videoUserId == this.userId) {

                        this.followFlag     = false;
                        this.unfollowFlag   = false;
                    
                    } else {
                        
                        if (this.watchResults.userId.followers.includes(this.userId)) {
                            this.unfollowFlag = true;
                        } else {
                            this.followFlag = true;
                        }
                    
                    }
                    
                    //Saved or unsaved pret
                    if (this.savedvideos.includes(this.watchResults._id)) {
                    
                        this.savedFlag      = true;
                        this.unsavedFlag    = false;
                    
                    } else {

                        this.savedFlag      = false;
                        this.unsavedFlag    = true;
                    
                    }

                    //like unlike heart
                    if ( this.watchResults.likes.includes(this.userId) ) {
                        
                        this.likeFlag   = true;
                        this.unLikeFlag = false;

                    } else {

                        this.likeFlag   = false;
                        this.unLikeFlag = true;

                    }

                    //Grade or graded button
                    if (this.gradeData.graded) {
                        this.gradedFlag = true
                    } else {
                        this.gradedFlag = false
                    }
                    
                    //setting the recommondations videos **Currently random videos
                    // this.exploreVideos();
                }
            
            })
            .catch((err) => {
                
                this.$store.commit("User/toggleProgressLoader", false);
                let errorMsg = utilities.errorHandler(err);
                this.$store.commit("User/showErrorAlert", errorMsg);
            
            });

        },
        
        unsave(){
            
            if (!this.userData.signedIn) {
                this.signInWithUsDialog = true;
            } else {
                
                this.$store.commit("User/toggleProgressLoader", true);
                this.goUp();

                let data        = { videoId : this.watchResults._id };
                let endPoint    = apis.urls.unSavePret;
                
                axios(endPoint, {
                    method          : "post",
                    data,
                    withCredentials : true,
                    headers         : { authorization: this.userData.token },
                })
                .then((res) => {

                    this.savedFlag      = false;
                    this.unsavedFlag    = true;

                    this.$store.commit("User/toggleProgressLoader", false);
                    
                    // FIREBASE ANLYTICS
                    firebaseAnlytics.logEvent("Pret Unsaved on Watch Page");
                
                })
                .catch((err) => {

                    this.$store.commit("User/toggleProgressLoader", false);
                    console.log("Error In watch",err);
                    let errorMsg = utilities.errorHandler(err);
                    this.$store.commit("User/showErrorAlert", errorMsg);
                
                });

            }

        },

        save(){
        
            if (!this.userData.signedIn) {
                this.signInWithUsDialog = true;
            } else {

                this.$store.commit("User/toggleProgressLoader", true);
                this.goUp();

                let data        = { videoId: this.watchResults._id };
                let endPoint    = apis.urls.savePret;
                
                axios(endPoint, {
                    method          : "post",
                    data,
                    withCredentials : true,
                    headers         : { authorization: this.userData.token },
                })
                .then((res) => {

                    this.savedFlag      = true;
                    this.unsavedFlag    = false;

                    this.$store.commit("User/toggleProgressLoader", false);                    
                    
                    // FIREBASE ANLYTICS
                    firebaseAnlytics.logEvent("Pret Saved on Watch Page");                
                })
                .catch((err) => {
                
                    this.$store.commit("User/toggleProgressLoader", false);
                    console.log("Error In Watch",err);
                    let errorMsg = utilities.errorHandler(err);
                    this.$store.commit("User/showErrorAlert", errorMsg);
                
                });

            }

        },

        recommendationsVideos() {

            let endPoint = apis.urls.recommendations;
            this.$store.commit("User/toggleProgressLoader", true);

            let params = {
                videoId     : this.watchResults._id,
                videoUserId : this.videoUserId,
                tags        : this.watchResults.tags,
            };

            var that = this;

            axios(endPoint, {
                method          : "get",
                params,
                withCredentials : true,
                headers         : { authorization: this.userData.token }
            })
            .then((res) => {
                this.$store.commit("User/toggleProgressLoader", false);

                console.log("Recommendations : ", res.data.result.data);
                let videoArray = res.data.result.data;
                
                // So same video will not be shown twice in the recommendations
                that.recommendationsVideosArray = videoArray.filter((video)=>{
                    return video._id != this.watchQuery;
                })
                
                // if videos are less than 4 then we need to add more videos
                if (that.recommendationsVideosArray.length <= 4) {
                    that.exploreVideos();
                }

                                    
                // FIREBASE ANLYTICS
                firebaseAnlytics.logEvent("Pret Recommendations Displayed on Watch Page");

            })
            .catch((err) => {
                
                this.$store.commit("User/toggleProgressLoader", false);
                console.log("Error In Watch",err);
                let errorMsg = utilities.errorHandler(err);
                this.$store.commit("User/showErrorAlert", errorMsg);
            
            });

        },

        exploreVideos() {
            this.loadMoreVideosProgress = true;
            
            let endPoint = apis.urls.exploreVideos;
            
            axios(endPoint, {
                method          :   "post",
                 data  : { excludeVideoIDs : this.excludeVideoIDs }
            })
            .then((res) => {
                            console.log("explore video data: ", res);

                this.loadMoreVideosProgress = false;
                
                var videosData = res.data.result.data.feed
                
                if(videosData.length === 0) {
                    this.$store.commit("User/showErrorAlert", "There are no more videos");
                } else {
                    
                    if(this.excludeVideoIDs.length === 0){
                        // FIREBASE ANLYTICS
                        firebaseAnlytics.logEvent("Explore Video on Watch Displayed");
                    } else {
                        // FIREBASE ANLYTICS
                        firebaseAnlytics.logEvent("Explore Video on Watch (Load more Video) button clicked");
                    }
                    
                    for (let i = 0; i < videosData.length; i++) {
                        const element = videosData[i];
                        this.exploreVideosArray.push(element)
                        this.excludeVideoIDs.push(element._id);
                    }

                    console.log("excludeVideoIDs 2 : ",this.excludeVideoIDs);


                }
                           
                // FIREBASE ANLYTICS
                firebaseAnlytics.logEvent("Pret Recommendations Displayed on Watch Page");

                /*

                // So same video will not be shown twice in the recommendations
                let recommendationsVideos = videoArray.filter((video)=>{
                    return video._id != this.watchQuery;
                })

                console.log("this is recommendationsVideos : ",recommendationsVideos);
                
                
                if(that.recommendationsVideosArray.length === 0){
                   that.recommendationsVideosArray = recommendationsVideos;
                }
                else{

                    for (let i = 0; i < recommendationsVideos.length; i++) {
                        
                        const element = recommendationsVideos[i];

                        let flag = 0;

                        for (let j = 0; j < that.recommendationsVideosArray.length; j++) {

                            if( JSON.stringify(element._id) === JSON.stringify(that.recommendationsVideosArray[j]._id) )
                            {
                                flag = 1;
                            }
                            
                        }

                        if(flag === 0)
                        {
                            that.recommendationsVideosArray.push(element)
                        
                        }
                        
                    }
                    

                    //that.recommendationsVideosArray.concat(recommendationsVideos)
                    console.log("this is recommendationsVideosArray : ",that.recommendationsVideosArray);
                }
                
                this.$store.commit("User/toggleProgressLoader", false);

                                
                // FIREBASE ANLYTICS
                firebaseAnlytics.logEvent("Pret Recommendations Displayed on Watch Page");
                */
            
            })
            .catch((err) => {
            
                this.$store.commit("User/toggleProgressLoader", false);
                console.log(err);
                let errorMsg = utilities.errorHandler(err);
                this.$store.commit("User/showErrorAlert", errorMsg);
            
            });

        },

        deleteComment() {

            this.commentDeleteDialog = false;
            console.log("comemnet id to be delete : ", this.deleteCommentId);
            console.log("watchquery : ", this.watchQuery);

            this.$store.commit("User/toggleProgressLoader", true);

            let data = {
                videoId     : this.watchResults._id,
                commentId   : this.deleteCommentId,
            };

            let endPoint = apis.urls.deleteComment;
            axios(endPoint, {
                method          : "delete",
                data,
                withCredentials : true,
                headers         : { authorization: this.userData.token }
            })
            .then((res) => {

                console.log("delete comment output from back : ", res);

                this.watchResults.commentsLength    -= 1;
                this.commentsPret                   = res.data.result;

                this.$store.commit("User/toggleProgressLoader", false);

                                    
                // FIREBASE ANLYTICS
                firebaseAnlytics.logEvent("Comment Deleted on Watch Page");
            
            })
            .catch((err) => {
                    
                this.$store.commit("User/toggleProgressLoader", false);
                console.log("Error In watch",err);
                let errorMsg = utilities.errorHandler(err);
                this.$store.commit("User/showErrorAlert", errorMsg);
            
            });

        },

        deleteCommentDialogue(commentId, Index) {

            this.commentDeleteDialog    = true;
            this.deleteCommentId        = commentId;
            this.deleteCommentIndex     = Index;
        
        },

        validate() {

            if (this.$refs.form.validate()) {
                this.commentPret();
            }

        },

        copyLink() {

            var tooltip         = document.getElementById("myTooltip");
            tooltip.innerHTML   = "Link Copied !";
            var copiedText      = document.getElementById("myLink");
            
            copiedText.select();
            copiedText.setSelectionRange(0, 99999);
            document.execCommand("copy");
        
        },

        outFunc() {

            var tooltip         = document.getElementById("myTooltip");
            tooltip.innerHTML   = "Copy to clipboard";
        
        },

        share() {

            if (navigator.share) {

                navigator
                .share({

                    title   : "Preskilet",
                    text    : "Check out my live Video Profile on Preskilet",
                    url     : this.shareableLink,
                
                })
                .then(() => console.log("Successful share is done"))
                .catch((err) => {
                    let errorMsg = utilities.errorHandler(err);
                    this.$store.commit("User/showErrorAlert", errorMsg);
                });

            } else {

                console.log("outdated browser , sharing cant be done directly, so share link using model");
                this.shareDialog = true;

            }

        },

        goUp() {
            window.scrollTo(0, 0);
        },

        goBack() {
            window.history.back();
        },

        checkIOS() {

            if (
                [
                "iPad Simulator",
                "iPhone Simulator",
                "iPod Simulator",
                "iPad",
                "iPhone",
                "iPod",
                ].includes(navigator.platform)
            ) {
                this.iosBackButtonFlag = true;
            }

        },

        flagPret() {

            this.flagDialog = false;
            
            if (!this.userData.signedIn) {
                this.signInWithUsDialog = true;
            } else {

                this.$store.commit("User/toggleProgressLoader", true);
                this.goUp();

                let data        = { videoId: this.watchResults._id };
                let endPoint    = apis.urls.flagPret;
                
                axios(endPoint, {
                    method          : "post",
                    data,
                    withCredentials : true,
                    headers         : { authorization: this.userData.token }
                })
                .then((res) => {

                    this.$store.commit("User/toggleProgressLoader", false);
                    this.$store.commit("User/showSuccessAlert", res.data.result);

                                        
                    // FIREBASE ANLYTICS
                    firebaseAnlytics.logEvent("Pret Flagged on Watch Page");
                
                })
                .catch((err) => {

                    this.$store.commit("User/toggleProgressLoader", false);
                    console.log("Error In Watch",err);
                    let errorMsg = utilities.errorHandler(err);
                    this.$store.commit("User/showErrorAlert", errorMsg);
                
                });

            }

        },

        commentPret() {

            if (!this.userData.signedIn) {
                this.signInWithUsDialog = true;
            } else {

                console.log("comment to be made : ", this.comment);

                this.$store.commit("User/toggleProgressLoader", true);
                this.goUp();

                let data = {
                videoId : this.watchResults._id,
                comment : this.comment,
                };

                let endPoint = apis.urls.commentPret;
                
                axios(endPoint, {
                    method          : "post",
                    data,
                    withCredentials : true,
                    headers         : { authorization: this.userData.token }
                })
                .then((res) => {

                    console.log("thisi is comment response : ", res.data.result);
                    
                    //pushing the comment in the array of comments
                    this.watchResults.commentsLength += 1;
                    this.commentsPret = res.data.result;
                    
                    // this.commentsPret.push(res.data.result);
                    this.$store.commit("User/toggleProgressLoader", false);

                                        
                    // FIREBASE ANLYTICS
                    firebaseAnlytics.logEvent("Comment Added on Watch Page");
                
                })
                .catch((err) => {
                    
                    this.$store.commit("User/toggleProgressLoader", false);
                    console.log("Error In watch",err);
                    let errorMsg = utilities.errorHandler(err);
                    this.$store.commit("User/showErrorAlert", errorMsg);
                
                });

            }

        },

        gradePret() {
                    
            if (!this.userData.signedIn) {
                this.signInWithUsDialog = true;
            } else {
                
                if( this.grades.language        > 0 && 
                    this.grades.voice      > 0 && 
                    this.grades.gestures   > 0 && 
                    this.grades.confidence > 0 && 
                    this.grades.content    > 0    )
                {
                    
                    this.$store.commit("User/toggleProgressLoader", true);
                    this.goUp();

                    let data = {
                    videoId : this.watchResults._id,
                    grades  : this.grades
                    };

                    console.log("grading the video now : ",data);
                    this.gradeDialog = false;
            
                    let endPoint = apis.urls.gradePret;
                    this.$store.commit("User/toggleProgressLoader", true);        
                    axios(endPoint, {
                        method          : "post",
                        data,
                        withCredentials : true,
                        headers         : {authorization : this.userData.token }
                    })
                    .then((res) => {

                        this.gradedFlag = false
                        this.$store.commit("User/toggleProgressLoader", false);
                        this.$store.commit("User/showSuccessAlert", res.data.result);
                        
                        this.gradeDialog    =  false
                        this.gradedFlag     = true

                        this.$store.commit("User/toggleProgressLoader", false);
                        // FIREBASE ANLYTICS
                        firebaseAnlytics.logEvent("Pret Graded on Watch Page");

                    })
                    .catch((err) => {

                        this.$store.commit("User/toggleProgressLoader", false);
                        console.log("Error In watch",err);
                        let errorMsg = utilities.errorHandler(err);
                        this.$store.commit("User/showErrorAlert", errorMsg);

                        this.gradeDialog    =  false
                        this.gradedFlag     = true

                    });

                }
                else{
                    this.$store.commit("User/showErrorAlert", "please grade all parameters");
                }

            }

        },

        likePret() {

            if (!this.userData.signedIn) {
                this.signInWithUsDialog = true;
            } else {

                this.$store.commit("User/toggleProgressLoader", true);
                this.goUp();

                let data        = { videoId: this.watchResults._id };
                let endPoint    = apis.urls.likePret;
                
                axios(endPoint, {
                    method          : "post",
                    data,
                    withCredentials : true,
                    headers         : { authorization   : this.userData.token } 
                })
                .then((res) => {

                    this.likeFlag   = true;
                    this.unLikeFlag = false;
                    this.watchResults.likesLength += 1;
                    
                    this.$store.commit("User/toggleProgressLoader", false);

                                        
                    // FIREBASE ANLYTICS
                    firebaseAnlytics.logEvent("Pret Liked on Watch Page");
                
                })
                .catch((err) => {
                    
                    this.$store.commit("User/toggleProgressLoader", false);
                    console.log("Error In Watch",err);
                    let errorMsg = utilities.errorHandler(err);
                    this.$store.commit("User/showErrorAlert", errorMsg);
                
                });

            }
        },

        unLikePret() {

            if (!this.userData.signedIn) {
                this.signInWithUsDialog = true;
            } else {

                this.$store.commit("User/toggleProgressLoader", true);
                this.goUp();

                let data        = { videoId: this.watchResults._id };
                let endPoint    = apis.urls.unLikePret;
                
                axios(endPoint, {
                    method          : "post",
                    data,
                    withCredentials : true,
                    headers         : { authorization: this.userData.token }
                })
                .then((res) => {

                    this.likeFlag   = false;
                    this.unLikeFlag = true;
                    this.watchResults.likesLength -= 1;
                    this.$store.commit("User/toggleProgressLoader", false);

                                        
                    // FIREBASE ANLYTICS
                    firebaseAnlytics.logEvent("Pret Unliked on Watch Page");

                })
                .catch((err) => {
                    
                    this.$store.commit("User/toggleProgressLoader", false);
                    console.log("Error In Watch",err);
                    let errorMsg = utilities.errorHandler(err);
                    this.$store.commit("User/showErrorAlert", errorMsg);
                
                });

            }

        },

        savePret() {

            if (!this.userData.signedIn) {
                this.signInWithUsDialog = true;
            } else {
                this.saved = false;
            }

        },

        unSavePret() {

            if (!this.userData.signedIn) {
                this.signInWithUsDialog = true;
            } else {
                this.saved = true;
            }

        },

        followPreskilet() {
            
            if (!this.userData.signedIn) {
                this.signInWithUsDialog = true;
            } else {
                
                this.$store.commit("User/toggleProgressLoader", true);
                this.goUp();

                let data        = { userId: this.videoUserId };
                let endPoint    = apis.urls.follow;
                
                axios(endPoint, {
                    method          : "post",
                    data,
                    withCredentials : true,
                    headers         : { authorization: this.userData.token }
                })
                .then((res) => {

                    // just added something in the followers array so that it will increases the length of followers and followers will be updated dynamically
                    this.watchResults.userId.followers.push({ id: "anonymes" });

                    this.followFlag     = false;
                    this.unfollowFlag   = true;
                    this.$store.commit("User/toggleProgressLoader", false);
                    this.$store.commit("User/showSuccessAlert", res.data.result);

                                        
                    // FIREBASE ANLYTICS
                    firebaseAnlytics.logEvent("New Follow Operation on Watch Page");
                
                })
                .catch((err) => {
                    
                    this.$store.commit("User/toggleProgressLoader", false);
                    console.log("Error in watch",err);
                    let errorMsg = utilities.errorHandler(err);
                    this.$store.commit("User/showErrorAlert", errorMsg);
                
                });

            }

        },

        unFollowPreskilet() {
            
            if (!this.userData.signedIn) {
                this.signInWithUsDialog = true;
            } else {

                // just deleted something in the followers array so that it will increases the length of followers and followers will be updated dynamically
                this.watchResults.userId.followers.pop();

                this.$store.commit("User/toggleProgressLoader", true);
                this.goUp();

                let data        = { userId: this.videoUserId };
                let endPoint    = apis.urls.unfollow;
                
                axios(endPoint, {
                    method          : "post",
                    data,
                    withCredentials : true,
                    headers         : { authorization: this.userData.token }
                })
                .then((res) => {

                    this.followFlag     = true;
                    this.unfollowFlag   = false;

                    this.$store.commit("User/toggleProgressLoader", false);
                    this.$store.commit("User/showSuccessAlert", res.data.result);

                                        
                    // FIREBASE ANLYTICS
                    firebaseAnlytics.logEvent("New Unfollow Operation on Watch Page");
                
                })
                .catch((err) => {
                    
                    this.$store.commit("User/toggleProgressLoader", false);
                    console.log("Error In watch",err);
                    let errorMsg = utilities.errorHandler(err);
                    this.$store.commit("User/showErrorAlert", errorMsg);
                
                });

            }

        },

        getAvgGrades(){
            
            let data        = { videoId: this.watchQuery };
            let endPoint    = apis.urls.getAvgGrades;
            
            axios(endPoint, {
                method          : "get",
                params: data,
                withCredentials : true,
                headers         : { authorization: this.userData.token },
            })
            .then((res) => {
                console.log("This are the avg grades : ", res.data.result.data);
                this.avgGrades = res.data.result.data;

                this.$store.commit("User/toggleProgressLoader", false);
                
                                    
                // FIREBASE ANLYTICS
                firebaseAnlytics.logEvent("Average Grades Retrived on Watch Page");

            })
            .catch((err) => {
            
                this.$store.commit("User/toggleProgressLoader", false);
                console.log("Error In Watch",err);
                let errorMsg = utilities.errorHandler(err);
            
            });

        }

    }

};

</script>
