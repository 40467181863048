<template>
<div class="home">
    
    
    <v-container>
        
        <v-layout row wrap justify-space-around>
            <v-card flat class="hidden-md-and-up">
                <div class="d-flex flex-no-wrap justify-space-between">
                    <div>
                        <v-card-title class="headline" v-text="'Feed'"></v-card-title>
                        <v-card-subtitle v-text="'Welcome to Preskilet Feed. Here you can see the Feed of Prets of the people that you follow'"></v-card-subtitle>
                    </div>
                    
                    <v-avatar class="ma-3 hidden-sm-and-down" size="125" tile>
                        <v-img :src="vectorImages.signout"></v-img>
                    </v-avatar>
                </div>
            </v-card>
            
            <v-flex v-if="suggestData.length != 0" xs12 md12 hidden-md-and-up>
                
                <v-sheet flat class="mx-auto">
                    <v-divider></v-divider>
                    
                    <v-card-title class="headline" v-text="'Preskilet Suggestions'"></v-card-title>
                    
                    <v-slide-group class="pa-0">
                        
                        <div v-for="item in suggestData" :key="item._id">
                            <v-slide-item v-slot="{ toggle }">
                                
                                <v-card outlined @click="toggle" class="pa-3 ma-3">
                                    

                                    <v-img 
                                    :src="item.profile.profilePhoto"
                                    gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                                    height="150px"
                                    width="150px"
                                    style="border-radius: 50%;"
                                    ></v-img>

                                    <router-link style="text-decoration: none;color:black;" :to="'/' + item._id">

                                    <v-list two-line class="pa-0">
                                        <v-list-item>                                                
                                            <v-list-item-content class="pa-0" v-bind:class="{ limitedLength: isLimitedLength }">
                                                <v-list-item-title>{{ item.profile.name }}</v-list-item-title>
                                                <v-list-item-subtitle>{{ item.followers.length }} Followers</v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list>
                                    
                                    </router-link>

                                    
                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn v-if="item.msg === 'follow'" color="primary"  style="width: 100%;" @click="followPreskilet(item._id)">{{item.msg}}</v-btn>
                                        <v-btn v-if="item.msg === 'following'" icon style="width: 100%;color: grey;" >{{item.msg}}</v-btn>
                                        <v-spacer></v-spacer>
                                    </v-card-actions>
                                </v-card>
                     
                            </v-slide-item>
                        </div>
                
                    </v-slide-group>
                
                </v-sheet>
                
                <v-divider></v-divider>
            
            </v-flex>
            
            <v-flex xs12 md6>
                <FeedVideos />
            </v-flex>

            <!-- Right Section -->
            <v-flex xs12 md5 hidden-sm-and-down>


                                <!-- !!! Google sticky ad on desktop  -->
                 <v-card  class="mx-auto sticky" v-if="userData.profile.account === 'Student'">
            
                    <v-list-item two-line class="RightSideAd">
                        
                        <v-list-item-avatar><img src="https://banner2.cleanpng.com/20180324/iww/kisspng-google-logo-g-suite-google-5ab6f1cee66464.5739288415219388949437.jpg" /></v-list-item-avatar>
                        
                        <v-list-item-content>
                            
                            <v-list-item-title>Google Ads</v-list-item-title>
                            
                            <v-list-item-subtitle>Sponsored</v-list-item-subtitle>
                        
                        </v-list-item-content>
                                    
                        <v-btn color="primary" size="35" tile large icon router >
                            <v-icon left> mdi-compass-outline</v-icon>
                        </v-btn>

                    </v-list-item>

                        <div class="ad-container">
                            <Adsense
                                class="adsbygoogle"
                                style="display:block"
                                data-ad-client="ca-pub-2789565594210548"
                                data-ad-slot="8893290615"
                                data-ad-format="auto"
                                data-full-width-responsive="true"
                            ></Adsense>
                        </div>
                       
                </v-card>

            </v-flex>
        
        </v-layout>
    
    </v-container>

</div>
</template>

<style scoped>
.limitedLength {
  width: 120px;
}
/* for desktop */
@media screen and (min-width: 650px) {

    .sticky{
    position: -webkit-sticky;
    position: sticky;
top: 35px;
    z-index: 2;
    width: auto !important;
}

  .sticky2{
    position: -webkit-sticky;
    position: sticky;
    top: 400px;
    z-index: 2;
    width: auto !important;
    margin-top: 280%;

}

}

</style>

<script>
import { mapGetters } from "vuex";

import utilities from "@/helpers/utilities";
import apis from "../helpers/apiConfig";
const axios = require("axios").default;

import FeedVideos from "@/components/FeedVideos";
import LandUpInfo from "@/components/LandUpInfo";
import Suggestions from "@/components/Suggestions";

import firebase from "firebase/app";
import "firebase/firebase-analytics";
var firebaseAnlytics;

// Google Adsense    
import Vue from "vue";
import Ads from 'vue-google-adsense'
Vue.use(require('vue-script2'))
Vue.use(Ads.Adsense)
Vue.use(Ads.InFeedAdsense)

export default {
    
    name        : "Feed",
    
    components  : { FeedVideos, LandUpInfo, Suggestions },
    
    computed: { ...mapGetters({ userData: "User/userData" }) },
    
    created() {

        // FIREBASE ANLYTICS
        firebaseAnlytics = firebase.analytics();
        firebaseAnlytics.logEvent("Feed Page");

        this.$store.commit("User/toggleProgressLoader", false);
        this.$store.dispatch("User/updateUserToken", null, { root: true });
        this.$store.commit("User/isSignedIn");
    },
    
    mounted() {
      // this.getSuggestions()
    },
    
    data() {
        return {
            vectorImages    : utilities.vectorImages,
            recommendations : [],
            suggestData     : [],
            isLimitedLength : true
        }
    },
    
    methods: {
        
              followPreskilet(uid) {
              console.log("my uid : ", uid);
                
                this.$store.commit("User/toggleProgressLoader", true);

                let data        = { userId: uid };
                let endPoint    = apis.urls.follow;
                
                axios(endPoint, {
                    method          : "post",
                    data,
                    withCredentials : true,
                    headers         : { authorization: this.userData.token }
                })
                .then((res) => {

                    this.$store.commit("User/toggleProgressLoader", false);
                    this.$store.commit("User/showSuccessAlert", res.data.result);


                    this.suggestData.forEach(x => {
                        if(JSON.stringify(x._id) === JSON.stringify(uid)){
                            x.msg = "following";
                        }
                        
                    });

                    // FIREBASE ANLYTICS
                    firebaseAnlytics.logEvent("New Follow Operation on Feed from suggestions");                
                })
                .catch((err) => {
                    
                    this.$store.commit("User/toggleProgressLoader", false);
                    console.log("Error in watch",err);
                    let errorMsg = utilities.errorHandler(err);
                    this.$store.commit("User/showErrorAlert", errorMsg);
                
                });

        },
        getSuggestions() {
            
            let endPoint = apis.urls.getSuggestions;
            
            axios(endPoint, {
                method          :   "get",
                withCredentials :   true,
                headers         : { authorization: this.userData.token }
            })
            .then((res) => {
                
                if (res.data.result) {
                    
                    this.userId = res.data.result._id;
                    var userAlreadyFollowing = res.data.result.userFollowing;
                    var tempSuggestData = res.data.result.suggest;
                    
                    // To put all the data in one single array
                    let finalCycle = [];
                    for (let i = 0; i < tempSuggestData.length; i++) {
                    for (let j = 0; j < tempSuggestData[i].following.length; j++) {
                        // To not show the user the himself as a suggestions
                        if (tempSuggestData[i].following[j]._id != this.userId) {
                            finalCycle.push(tempSuggestData[i].following[j]);
                        }
                    }
                    }
                    
                    for (let jm = 0; jm < finalCycle.length; jm++) {
                        // console.log("docs ", jm, " ", finalCycle[jm])
                    }
                    
                    // Removing all the duplicates from the array
                    for (let i = 0; i < finalCycle.length; i++) {
                    for (let j = 0; j < finalCycle.length; j++) {
                        if (i != j) {
                        if (finalCycle[i]._id == finalCycle[j]._id) {
                            finalCycle.splice(j, 1);
                        }
                        }
                    }
                    }
                    
                    for (let jm = 0; jm < finalCycle.length; jm++) {
                        // console.log("docs ", jm, " ", finalCycle[jm])
                    }
                    
                    var uniqueChars = [];
                    
                    function doesFollow(myid) {
                    for (let i = 0; i < userAlreadyFollowing.length; i++) {
                        if (userAlreadyFollowing[i]._id == myid) {
                        return true;
                        }
                    }
                    return false;
                    }

                    var uniqueChars = [];
                    for (let j = 0; j < finalCycle.length; j++) {
                    if (!doesFollow(finalCycle[j]._id)) {
                        uniqueChars.push(finalCycle[j]);
                    }
                    }
                    this.suggestData = uniqueChars;
                    
                    var suggestDataWithMsg = uniqueChars.map((x)=>{
                        
                        return {
                        email : x.email,
                        followers : x.followers,
                        profile: x.profile,
                        _id : x._id,
                        msg: "follow"
                    }
                    });
                
                this.suggestData = suggestDataWithMsg;

                console.log("suggestions : ", this.suggestData);


                    if (this.suggestData.length == 1) {
                    this.isLimitedLength = false;
                    }


                }

                // FIREBASE ANLYTICS
                firebaseAnlytics.logEvent("Suggestions Displayed on Feed Page");
            
            })
            .catch((err) => { console.log("getSuggestions Error : ", err) });
        
        }
    }

};
</script>
