<template>
<div>
    
    <v-container style="min-height: 80vh;">
        
        <v-layout row justify-space-around>
  
            <!-- Treding videos  -->
          <v-flex xs12 md6>
         <v-flex xs12 md12>
              <v-card flat>
                <div class="d-flex flex-no-wrap justify-space-between">
                    <div>
                        <v-card-title class="headline" v-text="'Trending'"></v-card-title>
                        <v-card-subtitle v-text="'Welcome to Preskilet Trending. Here you can see top best Prets (Presentation Videos) on Preskilet.'"></v-card-subtitle>
                    </div>
                    
                    <v-avatar class="ma-3" size="150" tile>
                        <v-img :src="vectorImages.trending"></v-img>
                    </v-avatar> 
                </div>
            </v-card>
            </v-flex>

        <div v-if="trendingVideosArray.length != 0">
           
            <div v-for="(item, index) in trendingVideosArray"
            :key="item._id">

            <!-- videos -->
            <v-card            
            :flat="cardFlatFlag"
            :outlined="cardOutlineFlag"
            class="mx-auto my-6"
        >
            
            <v-list-item two-line>
                
                <v-list-item-avatar><img :src="item.userId.profile.profilePhoto" /></v-list-item-avatar>
                
                <v-list-item-content>
                    
                    <router-link style="text-decoration: none;color:black;" :to="'/' + item.userId._id">
                        <v-list-item-title>{{ item.userId.profile.name }}</v-list-item-title>
                    </router-link>
                    
                    <v-list-item-subtitle>{{ item.title }}</v-list-item-subtitle>
                
                </v-list-item-content>
                
                <v-btn color="primary" size="35" tile large icon router :to="'/watch?v=' + item._id">
                    <v-icon left> mdi-compass-outline</v-icon>
                </v-btn>
            
            </v-list-item>
            
            <!-- <vue-plyr :options="options">
                <video :data-poster="item.thumbnailUrl" :src="item.videoUrl" controls playsinline style="width: 100%;height:auto;" ></video>
            </vue-plyr> -->

    <router-link style="text-decoration: none;color:black;" :to="'/watch?v=' + item._id">
                    
                        <v-img 
                            :src="item.thumbnailUrl"
                            class="white--text align-end videoRecommendationThubmnail"
                            gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                        >
                            <v-list-item two-line>
                            <v-list-item-content>
                                <v-list-item-title class="white--text">
                                {{ item.title }}
                                </v-list-item-title>
                                <v-list-item-subtitle class="white--text"
                                >{{ item.viewsLength }} Views</v-list-item-subtitle
                                >
                            </v-list-item-content>
                            </v-list-item>
                        </v-img>
                    
                    </router-link>
            <v-card-actions>
                <v-list-item class="grow">
                    <v-row align="center" class="pa-0 ma-0">
                        {{ item.finalDate }} | {{ item.finalTime }}
                        
                        <v-spacer></v-spacer>  
              
                        <v-icon 
                            @click="unsave(item._id)"
                            v-if="savedprets.includes(item._id)" 
                            style="cursor:pointer;" 
                            color="primary"
                            size="30"
                        >
                            mdi-bookmark
                        </v-icon>


                        <v-icon 
                            @click="save(item._id)"
                            v-if="!savedprets.includes(item._id)" 
                            style="cursor:pointer;" 
                            color="primary"
                            size="30"
                        >
                            mdi-bookmark-outline
                        </v-icon>
                    
                    </v-row>

                </v-list-item>

            </v-card-actions>
        
        </v-card>

<!-- ads -->
  <!-- <v-card   v-if="index % 4 == 0 && index != 0"
            :flat="cardFlatFlag"
            :outlined="cardOutlineFlag"
            class="mx-auto my-6"
        >
            
            <v-list-item two-line>
                
                <v-list-item-avatar><img src="https://banner2.cleanpng.com/20180324/iww/kisspng-google-logo-g-suite-google-5ab6f1cee66464.5739288415219388949437.jpg" /></v-list-item-avatar>
                
                <v-list-item-content>
                    
                        <v-list-item-title>Google Ads</v-list-item-title>
                    
                    <v-list-item-subtitle>Sponsored</v-list-item-subtitle>
                
                </v-list-item-content>
                
                <v-btn color="primary" size="35" tile large icon router >
                    <v-icon left> mdi-compass-outline</v-icon>
                </v-btn>
            
            </v-list-item>
            
<div  class="ad-container">
                <InFeedAdsense
                    style="display:block"
                    data-ad-format="fluid"
                    data-ad-layout-key="-6t+ed+2i-1n-4w"
                    data-ad-client="ca-pub-2789565594210548"
                    data-ad-slot="6914026357"
                ></InFeedAdsense>
            </div>
   
            <v-card-actions>
                <v-list-item class="grow">
                    <v-row align="center" class="pa-0 ma-0">
                        
                        <v-icon 
                            style="cursor:pointer;" 
                            color="whitesmoke" 
                            size="30"
                        >
                            mdi-heart-outline
                        </v-icon>
                        
                       
                        
                        <v-spacer></v-spacer>  
              
                                            <v-icon 
                            style="cursor:pointer;" 
                            color="primary"
                            size="30"
                        >
                            mdi-bookmark-outline
                        </v-icon>
                    
                    </v-row>

                </v-list-item>

            </v-card-actions>
        
        </v-card> -->

        </div>
        <div class="text-center">
            
            <v-progress-circular
                v-if="loadMoreVideosProgress"
                :size="50"
                color="blue"
                dark
                indeterminate>
            </v-progress-circular>

            <br><br>

                            <v-btn
            rounded
            color="primary"
            dark
            @click="trendingVideos()"
            >
                Load more videos
            </v-btn>


                <v-btn
            rounded
            color="primary"
            dark
            v-if="noMorevideos"
            >
                No More Videos
            </v-btn>

            <br><br>
        
            
        
        </div>
            </div>

               

            </v-flex>
            
            <!-- Right section -->
            <v-flex xs12 md5 hidden-sm-and-down>

                <!-- !!! Google sticky ad on desktop  -->
                  <v-card  class="mx-auto sticky" v-if="userData.signedIn && userData.profile.account === 'Student'">
            
                    <v-list-item two-line class="RightSideAd">
                        
                        <v-list-item-avatar><img src="https://banner2.cleanpng.com/20180324/iww/kisspng-google-logo-g-suite-google-5ab6f1cee66464.5739288415219388949437.jpg" /></v-list-item-avatar>
                        
                        <v-list-item-content>
                            
                            <v-list-item-title>Google Ads</v-list-item-title>
                            
                            <v-list-item-subtitle>Sponsored</v-list-item-subtitle>
                        
                        </v-list-item-content>
                                    
                        <v-btn color="primary" size="35" tile large icon router >
                            <v-icon left> mdi-compass-outline</v-icon>
                        </v-btn>

                    </v-list-item>

                        <div class="ad-container">
                            <Adsense
                                class="adsbygoogle"
                                style="display:block"
                                data-ad-client="ca-pub-2789565594210548"
                                data-ad-slot="8261599755"
                                data-ad-format="auto"
                                data-full-width-responsive="true"
                            ></Adsense>
                        </div>
                       
                </v-card> 

            </v-flex>

        </v-layout>
    </v-container>

</div>
</template>

<style scoped>
.videoRecommendationThubmnail{
width: 100%;
height:350px;
}


.footerad{ 
    width: 100%;
    height: 100%;
    margin: 25px;
    margin-top: 0px;
    margin-bottom:30px;
}


/* for desktop */
@media screen and (min-width: 650px) {
.videoRecommendationThubmnail{
width: 100%;
height:450px;
}
    .footerad{
      width: 100%;
      height: 100%;
      margin:25px;
      margin-top: -3px;
      margin-bottom:30px;
  }

    .sticky{
    position: -webkit-sticky;
    position: sticky;
    top: 35px;
    z-index: 2;
    width: auto !important;
}

  .sticky2{
    position: -webkit-sticky;
    position: sticky;
    top: 400px;
    z-index: 2;
    width: auto !important;
    margin-top: 280%;
}

}

.Brand-logo {
  text-decoration: none !important;
  color: black !important;
}

.headtitle {
  font-size: 45px;
  color: grey;
  margin-bottom: 8%;
  margin-top: 10vh;
}

@media screen and (min-width: 650px) {
  .headtitle {
    font-size: 70px;
  }
}

.blink_me {
  animation: blinker 1s linear infinite;
  color: red;
  margin: 1%;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}


</style>

<script>

import { mapGetters } from "vuex";

import utilities from "@/helpers/utilities";
import apis from "../helpers/apiConfig";
const axios = require("axios").default;

import firebase from "firebase/app";
import "firebase/auth";
import "firebase/messaging";
import "firebase/firebase-analytics";

import VuePlyr from "vue-plyr";

// Google Adsense    
import Vue from "vue";
import Ads from 'vue-google-adsense'
Vue.use(require('vue-script2'))
Vue.use(Ads.Adsense)
Vue.use(Ads.InFeedAdsense)

var messaging;
var deferredPrompt;
var firebaseAnlytics;


export default {
    name: "Trending",
    
    components: { "vue-plyr": VuePlyr },
    
    computed: { ...mapGetters({ userData: "User/userData" }) },
    
    async created() {

        // FIREBASE ANLYTICS
        firebaseAnlytics = firebase.analytics();
        firebaseAnlytics.logEvent("Trending Page");
        
        this.$store.commit("User/toggleProgressLoader", false);
        this.$store.dispatch("User/updateUserToken", null, { root: true });
        this.$store.commit("User/isSignedIn");
        
        var that = this;
        
        $(window).scroll(function() {

            // Desktop
            if($(window).scrollTop() + $(window).height() == $(document).height()) {

                if(that.noMorevideos === false && that.apiAlreadyCalled === false){
                    that.apiAlreadyCalled = true;
                    that.trendingVideos();
                }

                if(that.noMorevideos === true)
                {
                    console.log("There are no more videos");
                    //   that.$store.commit("User/showErrorAlert", "There are no more videos");
                }
            }
            
            // For Mobile
             if ($(window).scrollTop() >= $(document).height() - $(window).height() - 100) {
                if(that.noMorevideos === false && that.apiAlreadyCalled === false){
                    that.apiAlreadyCalled = true;
                    that.exploreVideos();
                }

                if(that.noMorevideos === true)
                {
                    console.log("There are no more videos");
                }
            }
        });
    
    },
    
    mounted() {
        
        if (window.screen.availWidth <= 650) { this.cardFlatFlag = true; } 
        else { this.cardOutlineFlag = true; }
        
         this.trendingVideos();
    },


    data() {
        return {
            
            savedprets      : [],
            userId          : "",

            loadMoreVideosProgress  : false,
            skipCount               : 1,
            
            options                 : { controls: ["play-large", "mute"], hideControls: true},
            trendingVideosArray      : [],
            
            notificationPermission  : true,
            notificationprogress    : false,
            installPwaBanner        : false,
            vectorImages            : utilities.vectorImages,
            searchQuery             : "",
            
            SocialLinksOfPreskilet  : [
                {   icon : "mdi-linkedin",  link : "https://www.linkedin.com/in/preskilet-incorporation-1bb17a1b9/" },
                {   icon : "mdi-instagram", link : "https://www.instagram.com/preskilet/"                           }
            ],
            
            commonMenuitems: [
                {   title : "Terms & conditions",   route : "/termsandconditions",  icon : "mdi-file-document"  },
                {   title : "Privacy Policy",       route : "/privacypolicy",       icon : "mdi-lock"           },
                {   title : "About us",             route: "/aboutus",              icon : "mdi-information"    },
                {   title : "Contact us",           route: "/contactus",            icon : "mdi-phone"          }
            ],
            
            noMorevideos: false,
            apiAlreadyCalled : false,
            cardFlatFlag    : true,
            cardOutlineFlag : false,


        }
    },
    

    methods: {
        
        trendingVideos() {

            this.loadMoreVideosProgress = true;

            let endPoint = apis.urls.trendingVideos;
            
            axios(endPoint, {
                method  :   "get",
                params  : { "skip" : this.skipCount },
                withCredentials :   true,
                headers         : { authorization : this.userData.token }

            })
            .then((res)     => {
                
                this.apiAlreadyCalled = false;
                
                this.loadMoreVideosProgress = false;
                
                var videosData = res.data.result.data.feed;
                this.userId     = res.data.result.data.userId;
                this.savedprets = res.data.result.data.saved;

                
                if(videosData.length === 0 || this.skipCount > 4999) {
                    this.noMorevideos = true;
                    console.log("There are no more videos");
                    // this.$store.commit("User/showErrorAlert", "There are no more videos");
                } else {
                    
                    for (let i = 0; i < videosData.length; i++) {
                        const element = videosData[i];
                        const UplaodDateTobeParsed = new Date(element.date);
                        const monthFromDate = UplaodDateTobeParsed.getMonth() + 1;
                        const finalDate = UplaodDateTobeParsed.getDate()  +   "/" +
                        monthFromDate +   "/" + UplaodDateTobeParsed.getFullYear();

                        // const finalTime = UplaodDateTobeParsed.getHours() + " : " + UplaodDateTobeParsed.getMinutes() + " : " + UplaodDateTobeParsed.getSeconds();

                        function formatAMPM(date) {
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0'+minutes : minutes;
  var strTime = hours + ':' + minutes + ' ' + ampm;
  return strTime;
}
const finalTime = formatAMPM(UplaodDateTobeParsed);


                        this.trendingVideosArray.push({...element, finalTime, finalDate})
                    }
                    console.log("this.trendingVideosArray : ", this.trendingVideosArray);
                    
                    if(this.skipCount === 1){
                        // FIREBASE ANLYTICS
                        firebaseAnlytics.logEvent("Trending Videos Displayed");
                    } else {
                        // FIREBASE ANLYTICS
                        firebaseAnlytics.logEvent("Trending Video (Load more Video) button clicked");
                    }

                    this.skipCount = this.skipCount + 12;
                }
            
            })
            .catch((err)    => { console.log("trendingVideos() Error : ", err)   });

        },
        
    
     
        
    }
};

</script>
