<template>
    <div>
   
        <MultimediaBlock :config="curiculoConfig" />
        <MultimediaBlock :config="curiculoExplainationConfig" />
        <MultimediaBlock :config="curiculoStep1Config" />
        <MultimediaBlock :config="curiculoStep2Config" />
        <MultimediaBlock :config="curiculoStep3Config" />
        <GalleryBlock :config="placementsConfig" />
        <GalleryBlock :config="recruitersConfig" />
        

    </div>
</template>
    
<script>

import MultimediaBlock from "@/staticPagesModule/blocks/MultimediaBlock";
import GalleryBlock from "@/staticPagesModule/blocks/GalleryBlock";

export default {
    name: "StaticPagesTemplate",
    components: { MultimediaBlock, GalleryBlock },
    data() { 
        return {
            curiculoConfig: {
                core: {
                    info: {
                        orderMd: "2",
                        order: "1",
                    },
                    media: {
                        orderMd: "1",
                        order: "2",
                    },  
                    colors: {
                        primary: "white",
                        secondary: "#2196f3",
                        third: "#2196f3",
                        
                    },                          
                },
                info: {
                    title1: "Create Live Textual Resume With ",
                    title2: "Curiculo Live.",
                    subTitle: "Industry-Ready Resume. Share with Link. Update in Real-Time.",
                    ctaButton: {
                        isShow: true,
                        link: "https://curiculo.live/signin"
                    },
                },
                videos: [
                    {
                        title: "",
                        URL: "https://www.youtube.com/embed/mevUwzyy3ro?si=va5BnRHRk7-AbFTO"
                    },
                ],
                images: [
                    {
                        URL:"https://firebasestorage.googleapis.com/v0/b/preskilet.appspot.com/o/assets%2FinfopageAssets%2Fcuriculoliveresume.png?alt=media&token=01bd28bd-3622-417b-8a27-9462d12dc361"
                    }                 
                ],
            },

            curiculoExplainationConfig: {

                core: {
                    info: {
                        orderMd: "1",
                        order: "2",
                    },
                    media: {
                        orderMd: "1",
                        order: "2",
                    },           
                },
                info: {
                    title1: "",
                    title2: "What is Curiculo Live?",
                    subTitle: "Curiculo live is a platform where you can create resume in industry expected format. after creating a resume you get a live resume link that can be shared with recruiter. the live nature of resume link allows you to edit it anytime you want, it will automatically reflects on recruiters side !",
                    ctaButton: {
                        isShow: false,
                        link: "https://curiculo.live/signin"
                    }
                },
                images: [
                    
                    {
                        URL:"https://firebasestorage.googleapis.com/v0/b/preskilet.appspot.com/o/assets%2FinfopageAssets%2Fcuriculologoimage.png?alt=media&token=4b556f70-c4a9-4161-881d-3c9ebd352a7a"
                    },
                    {
                        URL: "https://firebasestorage.googleapis.com/v0/b/preskilet.appspot.com/o/assets%2FinfopageAssets%2FCuriculo%20Info%20Page%20(2).png?alt=media&token=b0b1986b-d8d5-48f4-90ba-a218063e4ad7"   
                    },
               
                ],
            },

            curiculoStep1Config: {

                core: {
                    info: {
                        orderMd: "2",
                        order: "1",
                    },
                    media: {
                        orderMd: "1",
                        order: "2",
                    },   
                    colors: {
                        primary: "white",
                        secondary: "#2196f3",
                        third: "#2196f3",
                        
                    },           
                },
                info: {
                    title1: "Step 1 - ",
                    title2: "Enter Details",
                    subTitle: "",
                    ctaButton: {
                        isShow: false,
                        link: "/signin"
                    }
                },
                images: [
                    {
                        URL: "https://firebasestorage.googleapis.com/v0/b/preskilet.appspot.com/o/assets%2FinfopageAssets%2Fcuriculostep1.png?alt=media&token=462d634c-b4e9-4d59-af97-f39a35053060"   
                    }               
                ],
            },

            curiculoStep2Config: {

                core: {
                    info: {
                        orderMd: "1",
                        order: "2",
                    },
                    media: {
                        orderMd: "1",
                        order: "2",
                    },     
         
                },
                info: {
                    title1: "Step 2 - ",
                    title2: "Get Real time Resume",
                    subTitle: "",
                    ctaButton: {
                        isShow: false,
                        link: "/signin"
                    }
                },
                images: [
                    {
                        URL: "https://firebasestorage.googleapis.com/v0/b/preskilet.appspot.com/o/assets%2FinfopageAssets%2Fcuriculostep2.png?alt=media&token=8552ce4a-61c8-4a54-9bac-6feb7c68acdc"   
                    }               
                ],
            },

            curiculoStep3Config: {

                core: {
                    info: {
                        orderMd: "2",
                        order: "1",
                    },
                    media: {
                        orderMd: "1",
                        order: "2",
                    },    
                    colors: {
                        primary: "white",
                        secondary: "#2196f3",
                        third: "#2196f3",
                        
                    },          
                },
                info: {
                    title1: "Step 3 - ",
                    title2: "Share With Link",
                    subTitle: "",
                    ctaButton: {
                        isShow: false,
                        link: "/signin"
                    }
                },
                images: [
                    {
                        URL: "https://firebasestorage.googleapis.com/v0/b/preskilet.appspot.com/o/assets%2FinfopageAssets%2Fcuriculostep3.png?alt=media&token=de21410b-1481-4e79-97a1-d9f79c400c52"   
                    }               
                ],
            },


            placementsConfig: {
                info: {
                    title1: "",
                    title2: "Placements",
                    subTitle: "",
                },
                core: {
                    galleryMaxHeight: "100vh",
                    onlyImages: false,
                    tabletImages: 3,
                    laptopImages: 4,
                    
                    media: {
                        blocksPerLine: "12",
                    },
                },
                imagesList: [
                    {
                        url: "https://firebasestorage.googleapis.com/v0/b/preskilet.appspot.com/o/assets%2Fplacements%2Fpooja.png?alt=media&token=60d7fc42-e262-4ff4-a362-65471a8961ee",
                        title: "44 LPA",
                        subTitle: "Pooja Kumari",
                        // isTileShape: true,
                    },
                    {
                        url: "https://firebasestorage.googleapis.com/v0/b/preskilet.appspot.com/o/assets%2Fplacements%2Fprasad.jpg?alt=media&token=18f3c5fc-a5ed-4846-8d33-2909813df4cc",
                        title: "44 LPA",
                        subTitle: "Prasad Borse",
                    },
                    {
                        url: "https://firebasestorage.googleapis.com/v0/b/preskilet.appspot.com/o/assets%2Fplacements%2Fneahaal.jpg?alt=media&token=5750bdb1-4aea-469e-a788-caa0632948ab",
                        title: "44 LPA",
                        subTitle: "Nehaal Pandey",
                    },
                    {
                        url: "https://firebasestorage.googleapis.com/v0/b/preskilet.appspot.com/o/assets%2Fplacements%2Faditya.jpg?alt=media&token=1df809ea-fa5f-4f3e-be54-9ee48b8c4c92",
                        title: "22 LPA",
                        subTitle: "aditya barve",
                    },
                    {
                        url: "https://firebasestorage.googleapis.com/v0/b/preskilet.appspot.com/o/assets%2Fplacements%2Frahul.jpg?alt=media&token=a225a34d-eaa6-44e8-9650-9b15140e2f5a",
                        title: "10 LPA",
                        subTitle: "Rahul tah",
                    },
                    {
                        url: "https://firebasestorage.googleapis.com/v0/b/preskilet.appspot.com/o/assets%2Fplacements%2Frushikesh.jpg?alt=media&token=284a076d-be6d-4bf0-8f9d-a39fa3753122",
                        title: "10 LPA",
                        subTitle: "Rushikesh Bagal",
                    },
                    {
                        url: "https://firebasestorage.googleapis.com/v0/b/preskilet.appspot.com/o/assets%2Fplacements%2Fsoham.jpg?alt=media&token=c1ec71e2-1031-4c40-ad50-600b35df42e8",
                        title: "10 LPA",
                        subTitle: "Soham Kumbhar",
                    },
                    {
                        url: "https://firebasestorage.googleapis.com/v0/b/preskilet.appspot.com/o/assets%2Fplacements%2Fdivya.png?alt=media&token=e0dbf4ab-3034-4094-9152-ea6a8c537c36",
                        title: "10 LPA",
                        subTitle: "Divya Chaudhari",
                    },
                    {
                        url: "https://firebasestorage.googleapis.com/v0/b/preskilet.appspot.com/o/assets%2Fplacements%2FLippi.png?alt=media&token=0e46ac42-250f-4557-8368-a5ddf99ae054",
                        title: "4 LPA",
                        subTitle: "Lipi Choudhary",
                    },
                    {
                        url: "https://firebasestorage.googleapis.com/v0/b/preskilet.appspot.com/o/assets%2Fplacements%2Fkhushi.jpg?alt=media&token=440fd657-2ec2-4b6b-825e-a281e24940fb",
                        title: "4 LPA",
                        subTitle: "khushi Khan",
                    },
                    {
                        url: "https://firebasestorage.googleapis.com/v0/b/preskilet.appspot.com/o/assets%2Fplacements%2Fvaishnav.png?alt=media&token=b82bee6f-713d-4e6d-a057-257bd81cf192",
                        title: "4 LPA",
                        subTitle: "Vaishnav Katare",
                    },
                    {
                        url: "https://firebasestorage.googleapis.com/v0/b/preskilet.appspot.com/o/assets%2Fplacements%2Fmadhvi.jpg?alt=media&token=a26cb933-8eb2-4270-a56e-11c0e543e586",
                        title: "4 LPA",
                        subTitle: "Madhavi Mandave",
                    },
                    
                ],
            },

            recruitersConfig: {
                info: {
                    title1: "",
                    title2: "Our Recruiters",
                    subTitle: "",
                },
                core: {
                    galleryMaxHeight: "100vh",
                    onlyImages: true,
                    tabletImages: 3,
                    laptopImages: 4,
                    colors: {
                      primary: "white",
                      secondary: "black",
                    },
                    media: {
                        blocksPerLine: "12",
                    },
                },
                imagesList: [
                    {
                        url: "https://firebasestorage.googleapis.com/v0/b/preskilet.appspot.com/o/assets%2Frecruiters%2Fdentsu.jpg?alt=media&token=654c5215-59d1-4833-8b57-bbd9cedba118",
                    },
                    {
                        url: "https://firebasestorage.googleapis.com/v0/b/preskilet.appspot.com/o/assets%2Frecruiters%2Fonline%20sales.jpg?alt=media&token=8a938af0-2153-47a8-af61-14a4c5e0cbb9",
                    },
                    {
                        url: "https://firebasestorage.googleapis.com/v0/b/preskilet.appspot.com/o/assets%2Frecruiters%2Fimages.jpg?alt=media&token=8bcfc210-ee07-4eb6-80a4-0879eb2770d3",
                    },
                    {
                        url: "https://firebasestorage.googleapis.com/v0/b/preskilet.appspot.com/o/assets%2Frecruiters%2Fradiant.jpg?alt=media&token=aff0adbd-1236-461c-8c08-cba7857dc410",
                    },
                    {
                        url: "https://firebasestorage.googleapis.com/v0/b/preskilet.appspot.com/o/assets%2Frecruiters%2Fabsolutereports.jpg?alt=media&token=f39353e5-a5c8-41d3-a7be-539091f9efbd",
                    },
                    {
                        url: "https://firebasestorage.googleapis.com/v0/b/preskilet.appspot.com/o/assets%2Frecruiters%2Fartiset.jpg?alt=media&token=746e5989-351f-46d3-b9b8-7fb62e79f60a",
                    },
                    {
                        url: "https://firebasestorage.googleapis.com/v0/b/preskilet.appspot.com/o/assets%2Frecruiters%2Fbpointer.jpg?alt=media&token=9da00465-a58b-42a0-ad70-75b3858709c6",
                    },
                    {
                        url: "https://firebasestorage.googleapis.com/v0/b/preskilet.appspot.com/o/assets%2Frecruiters%2Fcampuscomponent.jpg?alt=media&token=f217b7d8-588e-49fd-b9d2-217a37991360",
                    },
                    {
                        url: "https://firebasestorage.googleapis.com/v0/b/preskilet.appspot.com/o/assets%2Frecruiters%2Fdigtinctive.jpg?alt=media&token=e0d0ef25-97c2-43c0-ae1e-8021685a44b0",
                    },
                    {
                        url: "https://firebasestorage.googleapis.com/v0/b/preskilet.appspot.com/o/assets%2Frecruiters%2Fexecutive%2081.jpg?alt=media&token=9aee3386-a328-4fc5-a654-0f836f8526a4",
                    },
                    {
                        url: "https://firebasestorage.googleapis.com/v0/b/preskilet.appspot.com/o/assets%2Frecruiters%2Ffortunebi.png?alt=media&token=0e35c746-9535-4cbf-b370-0fc3fde44f4d",
                    },
                    {
                        url: "https://firebasestorage.googleapis.com/v0/b/preskilet.appspot.com/o/assets%2Frecruiters%2Fgullakaari_logo.jpg?alt=media&token=dc127550-c096-4e6e-90af-7ee403366ccb",
                    },
                    {
                        url: "https://firebasestorage.googleapis.com/v0/b/preskilet.appspot.com/o/assets%2Frecruiters%2Fhan%20digital.webp?alt=media&token=44164df2-a4f6-4b15-8677-cb51afdfd5a7 ",
                    },
                    {
                        url: "https://firebasestorage.googleapis.com/v0/b/preskilet.appspot.com/o/assets%2Frecruiters%2Fturtlemint.png?alt=media&token=6d491424-7491-4012-a1e2-13cbeb7c3ada",
                    }, 
                    {
                        url:"https://firebasestorage.googleapis.com/v0/b/preskilet.appspot.com/o/assets%2Frecruiters%2F1717146717595.jpg?alt=media&token=edcf4484-d725-4ce8-ad65-2c0736196aac"
                    },
                    {
                        url:"https://firebasestorage.googleapis.com/v0/b/preskilet.appspot.com/o/assets%2Frecruiters%2Fsahal.jpg?alt=media&token=dcbeb2ac-eef9-42e3-8ac4-ae19cbb1d07e"
                    },
                    {
                        url:"https://firebasestorage.googleapis.com/v0/b/preskilet.appspot.com/o/assets%2Frecruiters%2Fstorybot.jpg?alt=media&token=94d75c8c-d36b-4e85-9851-e95b25f57e73"
                    },
                    {
                        url:"https://firebasestorage.googleapis.com/v0/b/preskilet.appspot.com/o/assets%2Frecruiters%2Fxcelliuan.jpg?alt=media&token=cf10fd3e-f174-48ed-9355-ebc30945f8e1"
                    },
                    {
                        url:"https://firebasestorage.googleapis.com/v0/b/preskilet.appspot.com/o/assets%2Frecruiters%2Finternlabs.png?alt=media&token=6a183017-03e2-4fe5-bffd-5221902ae50a"
                    }
                ],
            }
        }
    },
};
</script>
    