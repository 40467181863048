const groupValidations      =   require("../apiValidations/groupValidations");
const notificationModule = require('../modules/notificationModule');
// const emailTemplateModule = require('../modules/emailTemplateModule');
// const emailModule = require("../modules/emailModule");

// request follow
// isDelete : false, isAccepted : false,

// request accept 
// isDelete  false, isAccepted : true

// request decline 
// isDelete : true, isAccepted: false

// un follow group 
// isDelete : true. isAccepted : false,



module.exports = function (app,   urlPrsr,    errorHandler,   responder,  authCheck,  dbMdl, admin) {
    
    app.post("/api/group", urlPrsr, authCheck, (req, res) => {
        try {

            let groupVarified = groupValidations.validateGroup(req.body);

            {// Console Logs
                console.log("Name", req.body.name);
                console.log("Description", req.body.description);
            }
            
            if (groupVarified.error) {
                errorHandler.errorHandler(400, groupVarified.message, res, "ER400");
                return;
            }
            
            dbMdl.groupMdl({

                userId          : req.userdata._id,
                name            : req.body.name,
                
                subject         : req.body.subject,
                type            : req.body.type,
                
                year            : req.body.year,
                academicYear    : req.body.academicYear,
                
                department      : req.body.department,
                div             : req.body.div,
                batch           : req.body.batch,
                
                description     : req.body.description,
                date: new Date(Date.now()).toUTCString()
            
            })
            .save()
            .then((data) => {
                responder.respond({"message" : "Group Created Successfully."}, res);
            })
            .catch((err) => {
                console.log("\n|_____ Query Error _____|\n", err, "\n");
                errorHandler.errorHandler(500, err, res);
            });
        
        }
        catch (err) {
            console.log("\n|_____ Error catched by Try Catch _____|\n", err, "\n");
            errorHandler.errorHandler(500, err, res);
        }
    
    });

    app.get("/api/groups", urlPrsr, authCheck, (req, res) => {
        try {

            dbMdl.groupMdl.find({ userId  : req.userdata._id, isDeleted   : false })
            .then((data) => {

                responder.respond({ "data"  : data }, res);

            })
            .catch((err) => {
                console.log("\n|_____ Query Error _____|\n", err, "\n");
                errorHandler.errorHandler(500, err, res);
            });
        
        }
        catch (err) {
            console.log("\n|_____ Error catched by Try Catch _____|\n", err, "\n");
            errorHandler.errorHandler(500, err, res);
        }
    
    });

    app.delete("/api/group", urlPrsr, authCheck, (req, res) => {
        try {

            if (!/^[a-f\d]{24}$/i.test(req.query.groupId)) {

                errorHandler.errorHandler(400, "Invalid groupId", res, "ER400");
                return;

            }

            dbMdl.groupMdl.find({ _id: req.query.groupId })
            .then((groupData) => {
                
                if (groupData.length === 0) {
                    errorHandler.errorHandler(400, "Group does not exist", res, "ER400");
                } else{

                    dbMdl.groupMdl.findOneAndUpdate({ 

                        _id         :   req.query.groupId 
                    
                    }, {

                        isDeleted   :   true
                    
                    })
                    .then((data) => {

                        dbMdl.groupFollowersMdl.updateMany({ 

                            groupId         :   req.query.groupId 
                        
                        }, {
    
                            isAccepted   :   false
                        
                        })
                        .then((data) => {

                            responder.respond({ message: "Group Deleted successfully" },res );
                        
                        })
                        .catch((err) => {
                            console.log("\n|_____ Query Error _____|\n", err, "\n");
                            errorHandler.errorHandler(500, err, res);
                        });
        
                        
        
                    })
                    .catch((err) => {
                        console.log("\n|_____ Query Error _____|\n", err, "\n");
                        errorHandler.errorHandler(500, err, res);
                    });

                }

            })
            .catch((err) => {
                console.log("\n|_____ Query Error _____|\n", err, "\n");
                errorHandler.errorHandler(500, err, res);
            });

        
        }
        catch (err) {
            console.log("\n|_____ Error catched by Try Catch _____|\n", err, "\n");
            errorHandler.errorHandler(500, err, res);
        }
    
    });

    app.post("/api/group/follow", urlPrsr, authCheck, (req, res) => {
        try {
            
            {// Console Logs
                console.log("GroupID : ", req.body.groupId);
            }

            if (!/^[a-f\d]{24}$/i.test(req.body.groupId)) {

                errorHandler.errorHandler(400, "Invalid groupID", res, "ER400");
                return;

            }

            dbMdl.groupMdl.find({ _id: req.body.groupId, isDeleted   : false })
            .then((groupData) => {

                if (groupData.length === 0) {
                    errorHandler.errorHandler(400, "Group does not exist", res, "ER400");
                } else {

                    dbMdl.groupFollowersMdl.findOneAndUpdate({
                        groupId   : req.body.groupId,
                        userId      : req.userdata._id,
                    },{
                        isAccepted : false,
                        isDeleted : false,
                        date: new Date(Date.now()).toUTCString()
                    },{
                        upsert : true
                    })
                    .then((data) => {
                        responder.respond({"data" : "You Started Following Group."}, res);

                        let message = {
                            title : "New Follow Request",
                            body: req.userdata.profile.name+" Requested To Follow Your Group :  "+ groupData[0].name,
                            url: "/grouprequests",
                            icon : req.userdata.profile.profilePhoto
                        }
        
                        notificationModule.send(groupData[0].userId, message, dbMdl.notificationTokensMdl, admin);

                    })
                    .catch((err) => {
                        console.log("\n|_____ Query Error _____|\n", err, "\n");
                        errorHandler.errorHandler(500, err, res);
                    });
                
                }

            });
            
        }
        catch (err) {
            console.log("\n|_____ Error catched by Try Catch _____|\n", err, "\n");
            errorHandler.errorHandler(500, err, res);
        }
    
    });

    app.post("/api/group/unfollow", urlPrsr, authCheck, (req, res) => {
        try {
            
            {// Console Logs
                console.log("GroupID : ", req.body.groupId);
            }

            if (!/^[a-f\d]{24}$/i.test(req.body.groupId)) {

                errorHandler.errorHandler(400, "Invalid groupID", res, "ER400");
                return;

            }

            dbMdl.groupMdl.find({ _id: req.body.groupId, isDeleted   : false })
            .then((data) => {

                if (data.length === 0) {
                    errorHandler.errorHandler(400, "Group does not exist", res, "ER400");
                } else {

                    dbMdl.groupFollowersMdl.findOneAndUpdate({
                        groupId   : req.body.groupId,
                        userId      : req.userdata._id,
                     },{

                        isDeleted   : true,
                        isAccepted  : false

                    })
                    .then((data) => {
                        responder.respond({ data : "You Unfollowed Group."}, res);
                    })
                    .catch((err) => {
                        console.log("\n|_____ Query Error _____|\n", err, "\n");
                        errorHandler.errorHandler(500, err, res);
                    });
                
                }

            });
            
        }
        catch (err) {
            console.log("\n|_____ Error catched by Try Catch _____|\n", err, "\n");
            errorHandler.errorHandler(500, err, res);
        }
    
    });

    app.get("/api/group/requests", urlPrsr, authCheck, (req, res) => {
        try {
            
            {// Console Logs
                console.log("GroupID : ", req.query.groupId);
            }

            if (!/^[a-f\d]{24}$/i.test(req.query.groupId)) {

                errorHandler.errorHandler(400, "Invalid groupID", res, "ER400");
                return;

            }

            dbMdl.groupMdl.find({ _id: req.query.groupId, isDeleted   : false })
            .then((data) => {

                if (data.length === 0) {
                    errorHandler.errorHandler(400, "Group does not exist", res, "ER400");
                } else {

                    dbMdl.groupFollowersMdl
                    .find({
        
                        groupId     :   req.query.groupId, 
                        isAccepted  :   false,
                        isDeleted   :   false 
        
                    })
                    .populate("userId")
                    .then((data) => {
                        responder.respond({"data" : data}, res);
                    })
                    .catch((err) => {
                        console.log("\n|_____ Query Error _____|\n", err, "\n");
                        errorHandler.errorHandler(500, err, res);
                    });
                
                }
            
            })
            .catch((err) => {
                console.log("\n|_____ Query Error _____|\n", err, "\n");
                errorHandler.errorHandler(500, err, res);
            });
        
        }
        catch (err) {
            console.log("\n|_____ Error catched by Try Catch _____|\n", err, "\n");
            errorHandler.errorHandler(500, err, res);
        }
    
    });

    app.post("/api/group/request/accept", urlPrsr, authCheck, (req, res) => {
        try {
            
            {// Console Logs
                console.log("GroupID : ", req.body.groupId);
                console.log("userIDArray : ", req.body.followersIdArray);
            }

            if (!/^[a-f\d]{24}$/i.test(req.body.groupId)) {

                errorHandler.errorHandler(400, "Invalid groupID", res, "ER400");
                return;

            }

            dbMdl.groupMdl.find({ _id: req.body.groupId, isDeleted   : false })
            .then((groupData) => {

                if (groupData.length === 0) {
                    errorHandler.errorHandler(400, "Group does not exist", res, "ER400");
                } else {

                    dbMdl.groupFollowersMdl.updateMany({
                
                        userId          :   req.body.followersIdArray,
                        groupId         :   req.body.groupId,
                        isDeleted       :   false
        
                    }, {
        
                        isAccepted      :   true
        
                    }).then((data) => {

                        responder.respond({ message : "You Accepted all Requests."}, res);

                        let message = {
                            title   : "Follow Request Accepted",
                            body    : "Your follow request for "+groupData[0].name+" is accepted by "+req.userdata.profile.name,
                            url     : "/grouprequests",
                            icon    : req.userdata.profile.profilePhoto
                        }
        
                        notificationModule.send(req.body.followersIdArray, message, dbMdl.notificationTokensMdl, admin);


                    })
                    .catch((err) => {
                        console.log("\n|_____ Query Error _____|\n", err, "\n");
                        errorHandler.errorHandler(500, err, res);
                    });

                }
            
            })
            .catch((err) => {
                console.log("\n|_____ Query Error _____|\n", err, "\n");
                errorHandler.errorHandler(500, err, res);
            });
        
        }
        catch (err) {
            console.log("\n|_____ Error catched by Try Catch _____|\n", err, "\n");
            errorHandler.errorHandler(500, err, res);
        }
    
    });

    app.delete("/api/group/request/decline", urlPrsr, authCheck, (req, res) => {
        try {
            
            {// Console Logs
                console.log("GroupID : ", req.query.groupId);
                console.log("UserID : ", req.query.followersIdArray);
            }

            if (!/^[a-f\d]{24}$/i.test(req.query.groupId)) {

                errorHandler.errorHandler(400, "Invalid groupID", res, "ER400");
                return;

            }

            dbMdl.groupMdl.find({ _id: req.query.groupId, isDeleted   : false })
            .then((data) => {

                if (data.length === 0) {
                    errorHandler.errorHandler(400, "Group does not exist", res, "ER400");
                } else {

                    dbMdl.groupFollowersMdl.findOneAndUpdate({

                        userId      :   req.query.followersIdArray,
                        groupId     :   req.query.groupId,
                        isDeleted   :   false 
        
                    }, {
        
                        isAccepted  :   false,
                        isDeleted   :   true
                        
                    })
                    .then((resultData) => {
                        responder.respond({"message" : "Request deleted successfully"}, res);

                        
                        let message = {
                            title : "Request Declined",
                            body: "Your follow request for "+data[0].name+" is declined",
                            url: "/"+data[0].userId,
                            icon : req.userdata.profile.profilePhoto
                        }

                        notificationModule.send(req.query.followersIdArray, message, dbMdl.notificationTokensMdl, admin);
                        
                        // emailTemplateData = {
                        //     type : "Follow Request Rejection",
                        //     from : {
                        //       profilePhoto: req.userdata.profile.profilePhoto,
                        //       name : req.userdata.profile.name,
                        //       account : req.userdata.account ? req.userdata.account:"",
                        //       userId : req.userdata._id
                        //     },
                            
                        //     msg : "Your follow  request for Group ' "+ data[0].name +" ' has been rejected and you are removed from the group on Preskilet.",
                        //     btn: "Visit Preskilet",
                        //     url : "/"
                        // }
                  
                  
                        // var emailTemplateObj =  emailTemplateModule.emailTemplate(emailTemplateData)
                        
                        // dbMdl.userMdl
                        // .find({ _id: req.query.followersIdArray})
                        // .then((userData) => {

                        //     emailModule.send( userData[0].email, "New Task on Preskilet", emailTemplateObj);
                        
                        // });
                        
                    })
                    .catch((err) => {
                        console.log("\n|_____ Query Error _____|\n", err, "\n");
                        errorHandler.errorHandler(500, err, res);
                    });

                }
            
            })
            .catch((err) => {
                console.log("\n|_____ Query Error _____|\n", err, "\n");
                errorHandler.errorHandler(500, err, res);
            });
        
        }
        catch (err) {
            console.log("\n|_____ Error catched by Try Catch _____|\n", err, "\n");
            errorHandler.errorHandler(500, err, res);
        }
    
    });

    app.delete("/api/group/remove/follower", urlPrsr, authCheck, (req, res) => {
        try {
            
            {// Console Logs
                console.log("GroupID : ", req.query.groupId);
                console.log("UserID : ", req.query.followersIdArray);
            }

            if (!/^[a-f\d]{24}$/i.test(req.query.groupId)) {

                errorHandler.errorHandler(400, "Invalid groupID", res, "ER400");
                return;

            }

            dbMdl.groupMdl.find({ _id: req.query.groupId, isDeleted   : false })
            .then((data) => {

                if (data.length === 0) {
                    errorHandler.errorHandler(400, "Group does not exist", res, "ER400");
                } else {

                    dbMdl.groupFollowersMdl.findOneAndUpdate({

                        userId      :   req.query.followersIdArray,
                        groupId     :   req.query.groupId,
                        isDeleted   :   false 
        
                    }, {
        
                        isAccepted  :   false,
                        isDeleted   :   true
                        
                    })
                    .then((resultData) => {
                        responder.respond({"message" : "Request deleted successfully"}, res);

                        
                        let message = {
                            title : "Removed From Group",
                            body: "You have been removed from "+data[0].name+". ",
                            url: "/"+data[0].userId,
                            icon : req.userdata.profile.profilePhoto
                        }

                        notificationModule.send(req.query.followersIdArray, message, dbMdl.notificationTokensMdl, admin);
                        
                        // emailTemplateData = {
                        //     type : "Follow Request Rejection",
                        //     from : {
                        //       profilePhoto: req.userdata.profile.profilePhoto,
                        //       name : req.userdata.profile.name,
                        //       account : req.userdata.account ? req.userdata.account:"",
                        //       userId : req.userdata._id
                        //     },
                            
                        //     msg : "Your follow  request for Group ' "+ data[0].name +" ' has been rejected and you are removed from the group on Preskilet.",
                        //     btn: "Visit Preskilet",
                        //     url : "/"
                        // }
                  
                  
                        // var emailTemplateObj =  emailTemplateModule.emailTemplate(emailTemplateData)
                        
                        // dbMdl.userMdl
                        // .find({ _id: req.query.followersIdArray})
                        // .then((userData) => {

                        //     emailModule.send( userData[0].email, "New Task on Preskilet", emailTemplateObj);
                        
                        // });
                        
                    })
                    .catch((err) => {
                        console.log("\n|_____ Query Error _____|\n", err, "\n");
                        errorHandler.errorHandler(500, err, res);
                    });

                }
            
            })
            .catch((err) => {
                console.log("\n|_____ Query Error _____|\n", err, "\n");
                errorHandler.errorHandler(500, err, res);
            });
        
        }
        catch (err) {
            console.log("\n|_____ Error catched by Try Catch _____|\n", err, "\n");
            errorHandler.errorHandler(500, err, res);
        }
    
    });

    app.get("/api/group/followers", urlPrsr, authCheck, (req, res) => {
        try {
            
            {// Console Logs
                console.log("GroupID : ", req.query.groupId);
            }

            if (!/^[a-f\d]{24}$/i.test(req.query.groupId)) {

                errorHandler.errorHandler(400, "Invalid groupID", res, "ER400");
                return;

            }

            dbMdl.groupMdl.find({ _id: req.query.groupId, isDeleted   : false })
            .then((data) => {

                if (data.length === 0) {
                    errorHandler.errorHandler(400, "Group does not exist", res, "ER400");
                } else {

                    dbMdl.groupFollowersMdl
                    .find({
        
                        groupId     :   req.query.groupId, 
                        isAccepted  :   true,
                        isDeleted   :   false 
        
                    })
                    .populate("userId")
                    .then((data) => {
                        responder.respond({"data" : data}, res);
                    })
                    .catch((err) => {
                        console.log("\n|_____ Query Error _____|\n", err, "\n");
                        errorHandler.errorHandler(500, err, res);
                    });
                
                }
            
            })
            .catch((err) => {
                console.log("\n|_____ Query Error _____|\n", err, "\n");
                errorHandler.errorHandler(500, err, res);
            });
        
        }
        catch (err) {
            console.log("\n|_____ Error catched by Try Catch _____|\n", err, "\n");
            errorHandler.errorHandler(500, err, res);
        }
    
    });

    app.get("/api/group/excel", urlPrsr, authCheck, (req, res) => {
        try {
            
            {// Console Logs
                console.log("GroupID : ", req.query.groupId);
            }

            if (!/^[a-f\d]{24}$/i.test(req.query.groupId)) {

                errorHandler.errorHandler(400, "Invalid groupID", res, "ER400");
                return;

            }

            dbMdl.groupMdl.find({ _id: req.query.groupId, isDeleted   : false })
            .then((groupData) => {

                if (groupData.length === 0) {
                    errorHandler.errorHandler(400, "Group does not exist", res, "ER400");
                } else {

                    
                    dbMdl.taskMdl.find({ groupId: req.query.groupId, isDeleted   : false })
                    .then((taskArray) => {
                    
                       dbMdl.groupFollowersMdl.find({ groupId : req.query.groupId, isDeleted    : false})
                       .populate("userId")
                       .then((userData)=>{
                           
                        var taskIds = taskArray.map((x)=>{ return x._id })
                        
                        var userIds = userData.map((x)=>{ return x.userId._id })
                        
                        var excelDataPromises = []
                        
                        for (let i = 0; i < userIds.length; i++) {
                    
                                let userInfo =  dbMdl.gradesMdl.find({ userId : userIds[i], taskId : taskIds, graderId: req.userdata._id})
                                .then((excelGradeData)=>{

                                    var avgGrade = excelGradeData.map((x)=>{

                                        var gradeSum = x.grades.language + x.grades.voice + x.grades.gestures + x.grades.confidence + x.grades.content
                                        return {taskId: x.taskId, grades: x.grades , avgGrades: gradeSum / 5 };
                                    
                                    })
                    
                                    let userInfo = {
                                        userData : userData[i].userId.profile,
                                        taskGrades : avgGrade
                                    }
                                    console.log("userInfo :",userInfo);
                    
                                    console.log("\n\n===============\n\n");
                    
                                    return userInfo;
                    
                                })

                                excelDataPromises.push(userInfo)
                    
                            }
                    
                            Promise.all(excelDataPromises).then((data)=>{

                                console.log("\n\n============Promise data Below================");
                                console.log("Data : ",data);

                                let ExcelData = {
                                    excelData : data,
                                    groupName : groupData[0].name,
                                    tasks: taskArray
                                }

                                responder.respond( {data : ExcelData}, res);

                            })
                            .catch((err) => {
                                console.log("\n|_____ Query Error _____|\n", err, "\n");
                                errorHandler.errorHandler(500, err, res);
                            });
                        
                        }) 
                    
                    });
                
                }
            
            })
            .catch((err) => {
                console.log("\n|_____ Query Error _____|\n", err, "\n");
                errorHandler.errorHandler(500, err, res);
            });
        
        }
        catch (err) {
            console.log("\n|_____ Error catched by Try Catch _____|\n", err, "\n");
            errorHandler.errorHandler(500, err, res);
        }
    
    });



};
